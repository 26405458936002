import { deleteById, get, patch, post, postProfileFile, put } from './api'

export const fetchAllDeviceDetails = async (data) => {
  return await get('devices', data)
}
export const fetchDeviceCount = async (data) => {
  return await get('devices/count', data)
}
export const fetchDeviceDetailsById = async (data) => {
  return await get('devices/' + data)
}
export const fetchModelUnderProject = async (data) => {
  return await get('project/models/' + data?.id, data?.data)
}
export const fetchCreateDevice = async (data) => {
  return await post('devices', data)
}
export const fetchUpdateDeviceDetails = async (data) => {
  return await put('devices/' + data.id, data?.payload)
}
export const fetchDeleteDevice = async (data) => {
  return await deleteById('devices/' + data)
}
export const postFiledata = async (data) => {
  return await postProfileFile('devices/upload', data.file, data.type)
}

export const deviceUnregister = async (data) => {
  return await patch('devices/' + data)
}
