import React from 'react'
import {
  cilCaretBottom,
  cilCaretTop,
  cilColorBorder,
  cilFilter,
  cilOptions,
  cilSwapVertical,
  cilTrash,
  cilXCircle,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CButton,
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CFormCheck,
  CFormLabel,
  CLoadingButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CSmartPagination,
  CSpinner,
  CTabContent,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CTabPane,
} from '@coreui/react-pro'
import { Number } from 'core-js'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import AsyncReactSelect from 'src/views/components/asyncRactSelec/asyncReactSelect'
import Search from 'src/views/components/serach/search'
import { handleUtcToMMDDYY } from 'src/views/helpers/utilityFunctions'
import {
  createDevice,
  deleteDevice,
  getAllDeviceDetails,
  getDeviceDetailsById,
  getModelUnderProject,
  updateDevice,
  uploadFile,
} from 'src/views/slices/device'
import { unregisterDevice } from 'src/views/slices/deviceRegisterSlice'
import { getProjects } from 'src/views/slices/projectSlice'
import { showAlert } from 'src/views/slices/toastSlice'
import * as Yup from 'yup'
import './index.css'
import { useTranslation } from 'react-i18next'

const DeviceListing = () => {
  const dispatch = useDispatch()
  const [id, setId] = useState('')
  const [data, setData] = useState([])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectDeleteDevice, setSelectDeleteDevice] = useState(null)
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [showEditModal, setShowEditModal] = useState(false)
  const [selectedProject, setSelectedProject] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [activeKey, setActiveKey] = useState(1)
  const [projectId, setProjectId] = useState()
  const [projectData, setProjectData] = useState()
  const [modelData, setModelData] = useState()

  const [deleteToggle, setDeleteToggle] = useState(false)
  const { t } = useTranslation('common')
  const {
    deviceDetails,
    isDeviceListing,
    isCreateDevice,
    isDeviceDetailById,
    isdeleteDevice,
    isUpdateDevice,
    isUpload,
  } = useSelector((state) => state.device)
  const { userDetails } = useSelector((state) => state.authentication)
  useEffect(() => {
    if (!searchParams.get('page')) {
      setSearchParams({ page: 1, size: 10 })
    } else {
      const params = {}
      searchParams.forEach((value, key) => {
        params[key] = value
      })
      params['page'] = currentPage
      setSearchParams(params)
    }
  }, [currentPage])

  useEffect(() => {
    if (searchParams.get('page')) {
      const params = {}
      searchParams.forEach((value, key) => {
        params[key] = value
      })
      if (searchParams.get('keyword')) {
        params['friendlyNameOrserialNumber'] = searchParams.get('keyword')
        delete params['keyword']
      }

      dispatch(getAllDeviceDetails(params))
    }
  }, [searchParams])

  const openEditModal = async (id) => {
    try {
      setId(id)
      const response = await dispatch(getDeviceDetailsById(id))
      response.payload?.data[0] && setData(response.payload?.data[0])
      setProjectId(response.payload?.data[0]?.project?.id)
      setSelectedProject(true)
      setShowEditModal(true)
    } catch (err) {}
  }

  const handleCreate = async (event) => {
    if (!selectedProject) {
      try {
        await dispatch(createDevice(event))
        setShowCreateModal(false)
        dispatch(getAllDeviceDetails({ page: searchParams.get('page') }))
      } catch (err) {
        setShowCreateModal(false)
        return null
      }
    } else {
      dispatch(updateDevice(event))
    }
  }
  const handleCsv = async (event) => {
    let file = ''
    if (event.csvFile) {
      file = event.csvFile
    }
    if (file) {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('fileType', 'file')
      try {
        await dispatch(uploadFile({ file, type: 'file' }))
        setShowCreateModal(false)
      } catch (err) {
        return err
      }
    }
  }
  const closeModal = () => {
    setShowCreateModal(false)
    setShowEditModal(false)
    setSelectedProject('')
    setShowDeleteModal(false)
    setSelectDeleteDevice(null)
  }
  const handleUpdate = async (event) => {
    var payload = { ...event }
    modelData?.forEach((data) => {
      if (payload['deviceModel'] === data.value) {
        payload['deviceModel'] = data?.id
      }
    })
    payload['project'] = projectData?.id
    try {
      await dispatch(updateDevice({ id, payload })).unwrap()
      setShowEditModal(false)
      setData('')
      dispatch(getAllDeviceDetails({ page: currentPage, size: '10' }))
      setId('')
    } catch (err) {
      setData('')
      setShowEditModal(false)
      dispatch(showAlert({ type: 'ERROR', message: 'err.message' }))

      return null
    }
    setProjectData('')
    setModelData('')
  }
  const handleUnregister = async () => {
    try {
      await dispatch(unregisterDevice(data?.id))
      setShowEditModal(false)
      setData('')
      dispatch(getAllDeviceDetails({ page: currentPage, size: '10' }))
      setId('')
    } catch (err) {
      setData('')
      setShowEditModal(false)
    }
  }
  const loadOptionsFunctionProject = (response, loadedOptions) => {
    response?.payload?.data[0]?.data?.forEach((value) => {
      Object.keys(value).forEach((item) => {
        if (item === 'projectName')
          loadedOptions.push({
            value: value?.projectName,
            label: value?.projectName,
            id: value?.id,
          })
      })
    })
  }
  const loadMoreOptionsProject = (response, loadedOptions) => {
    response?.payload?.data[0]?.data?.forEach((value) => {
      Object.keys(value).forEach((item) => {
        if (item === 'projectName')
          loadedOptions.push({
            value: value?.projectName,
            label: value?.projectName,
            id: value?.id,
          })
      })
    })
  }
  const loadOptionsFunctionModel = (response, loadedOptions) => {
    response?.payload?.data[0]?.deviceModels?.forEach((value, index) => {
      Object.keys(value).forEach((item) => {
        if (item === 'modelName')
          loadedOptions.push({
            value: value?.modelName,
            label: value?.modelName,
            id: value?.id,
          })
      })
    })
    setModelData(loadedOptions)
    setProjectData(response?.payload?.data[0])
  }
  const loadMoreOptionsModel = (response, loadedOptions) => {
    response?.payload?.data[0]?.data?.forEach((value, index) => {
      Object.keys(value).forEach((item) => {
        if (item === 'projectName')
          loadedOptions.push({
            value: value?.projectName,
            label: value?.projectName,
            id: value?.id,
          })
      })
    })
    setModelData(...modelData, ...loadedOptions)
  }
  const onDeleteClick = async () => {
    try {
      await dispatch(deleteDevice(selectDeleteDevice)).unwrap()
      setDeleteToggle(false)
    } catch (err) {
      setDeleteToggle(false)
      return err
    }

    closeModal()
    setSelectDeleteDevice(null)
    dispatch(getAllDeviceDetails({ page: currentPage, size: '10' }))
  }
  const onDeleteModalOpen = async (device) => {
    setShowDeleteModal(true)
    setSelectDeleteDevice(device?.id)
  }
  const list = [
    { title: t('REGISTERED_DEVICE'), value: 'REGISTERED_DEVICES' },
    { title: t('UN-REGISTERED_DEVICES'), value: 'UNREGISTERED_DEVICES' },
    { title: t('AUTHORIZED_DEVICES'), value: 'AUTHORIZED_DEVICES' },
    { title: t('SUSPENDED_DEVICES'), value: 'SUSPENDED_DEVICES' },
    { title: t('CLEAR_FILTER'), value: 'CLEAR_FILTER' },
  ]
  const handleFilter = (data) => {
    const params = {}
    searchParams.forEach((value, key) => {
      params[key] = value
    })
    const filterValues = [
      'REGISTERED_DEVICES',
      'UNREGISTERED_DEVICES',
      'AUTHORIZED_DEVICES',
      'SUSPENDED_DEVICES',
    ]
    if (filterValues.includes(data)) {
      params['filter'] = data
    } else {
      delete params['filter']
    }
    setSearchParams(params)
  }
  const handleSort = (asc, desc) => {
    const params = {}
    searchParams.forEach((value, key) => {
      params[key] = value
    })
    if (!searchParams.get('sort') || ![asc, desc].includes(searchParams.get('sort'))) {
      params['sort'] = asc
    }
    if (searchParams.get('sort') === asc) {
      params['sort'] = desc
    } else params['sort'] = asc
    setSearchParams(params)
  }

  const tableHeader = [
    {
      title: t('DEVICE.FRIENDLY_NAME'),
      value: 'FRIENDLY_NAME',
      asc: 'friendlyName:asc',
      desc: 'friendlyName:desc',
      required: true,
    },
    {
      title: t('DEVICE.PROJECT_NAME'),
      value: 'PROJECT_NAME',
      asc: 'project:asc',
      desc: 'project:desc',
      required: true,
    },
    {
      title: t('DEVICE.DEVICE_MODEL'),
      value: 'DEVICE_MODEL',
      asc: 'deviceModel:asc',
      desc: 'deviceModel:desc',
      required: true,
    },
    {
      title: t('DEVICE.SERIAL_NUMBER'),
      value: 'SERIAL_NUMBER',
      asc: 'serialNumber:asc',
      desc: 'serialNumber:desc',
      required: false,
    },
    {
      title: t('DEVICE.REGISTRATION_STATUS'),
      value: 'REGISTRATION_STATUS',
      asc: 'registrationStatus:asc',
      desc: 'registrationStatus:desc',
      required: false,
    },
    {
      title: t('DEVICE.ONLINE_STATUS'),
      value: 'ONLINE_STATUS',
      asc: 'onlineStatus:asc',
      desc: 'onlineStatus:desc',
      required: false,
    },
    {
      title: t('DEVICE.ACTUAL_OWNER'),
      value: 'ACTUAL_OWNER',
      asc: 'actualOwner:asc',
      desc: 'actualOwner:desc',
      required: false,
    },
    {
      title: t('DEVICE.AUTHORIZED_BY'),
      value: 'AUTHORIZED_BY',
      asc: 'authorizedBy:asc',
      desc: 'authorizedBy:desc',
      required: false,
    },
    {
      title: t('DEVICE.AUTHORIZED_DATE'),
      value: 'AUTHORIZED_DATE',
      asc: 'authorizeDate:asc',
      desc: 'authorizeDate:desc',
      required: true,
    },
    {
      title: t('DEVICE.REGISTERED_DATE'),
      value: 'REGISTERED_DATE',
      asc: 'registrationDate:asc',
      desc: 'registrationDate:desc',
      required: true,
    },
    {
      title: t('DEVICE.ACTIONS'),
      value: 'ACTIONS',

      required: false,
    },
  ]

  const renderTable = () => {
    const selectStyle = (value) => {
      const color = {
        ONLINE: 'lightgreen',
        OFFLINE: 'gray',
        REGISTERED: 'lightgreen',
        'NOT-REGISTERED': 'lightskyblue',
        UNREGISTERED: 'rgb(255, 99, 71)',
      }
      const textColor = {
        ONLINE: 'green',
        OFFLINE: 'white',
        REGISTERED: 'darkgreen',
        'NOT-REGISTERED': 'blue',
        UNREGISTERED: 'darkred',
      }
      const boxStyle = {
        padding: '2px 16px 2px 16px',
        backgroundColor: color[value],
        color: textColor[value],
        borderRadius: '4px',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        width: 'fit',
      }
      return boxStyle
    }

    return (
      <div className="">
        {!isDeviceListing ? (
          deviceDetails?.data?.length ? (
            <div>
              <CTable align="top" striped responsive>
                <CTableHead>
                  <CTableRow align="top">
                    {tableHeader.map((item, index) => (
                      <CTableHeaderCell key={item.value + index}>
                        <div className="d-flex justify-content-center  inline ">
                          {' '}
                          {item.title}{' '}
                          {item.required ? (
                            <CIcon
                              size="lg"
                              icon={
                                [item.asc, item.desc].includes(searchParams.get('sort'))
                                  ? searchParams.get('sort') === item.asc
                                    ? cilCaretTop
                                    : cilCaretBottom
                                  : cilSwapVertical
                              }
                              // className="me-2 "
                              onClick={() => {
                                if (item.required) handleSort(item.asc, item.desc)
                              }}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      </CTableHeaderCell>
                    ))}
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {deviceDetails?.data?.map((item, index) => (
                    <CTableRow key={item.id}>
                      <CTableDataCell>{item?.friendlyName}</CTableDataCell>
                      <CTableDataCell>{item?.project?.projectName}</CTableDataCell>
                      <CTableDataCell>{item?.deviceModel?.modelName}</CTableDataCell>
                      <CTableDataCell>{item.serialNumber}</CTableDataCell>
                      <CTableDataCell className="w-auto">
                        <div
                          className=" justify-content-center "
                          style={selectStyle(item.registrationStatus)}
                        >
                          {item.registrationStatus}
                        </div>
                      </CTableDataCell>
                      <CTableDataCell>
                        {' '}
                        <div className=" " style={selectStyle(item.onlineStatus)}>
                          {item.onlineStatus}{' '}
                        </div>
                      </CTableDataCell>
                      <CTableDataCell>{item.actualOwner}</CTableDataCell>
                      <CTableDataCell>{item.authorizedBy}</CTableDataCell>
                      <CTableDataCell>
                        {item?.authorizeDate ? handleUtcToMMDDYY(item?.authorizeDate) : null}
                      </CTableDataCell>
                      <CTableDataCell>
                        {item?.registrationDate ? handleUtcToMMDDYY(item?.registrationDate) : null}
                      </CTableDataCell>
                      <CTableDataCell>
                        <CDropdown variant="dropdown" alignment="end">
                          <CDropdownToggle
                            placement="bottom-end"
                            className="py-0 btn-info"
                            caret={false}
                            style={{ backgroundColor: 'lightGray' }}
                          >
                            <CIcon
                              icon={cilOptions}
                              title="Download file"
                              className="btn-info text-black "
                            />
                          </CDropdownToggle>
                          <CDropdownMenu className="pt-0" style={{ zIndex: 1000 }}>
                            <CDropdownItem
                              onClick={() => openEditModal(item.id)}
                              className="mt-2 pb-2"
                              style={{ zIndex: 1000 }}
                            >
                              <CIcon icon={cilColorBorder} className="me-2 " />
                              {t('EDIT')}
                            </CDropdownItem>
                            <CDropdownItem
                              className="border-top"
                              onClick={() => onDeleteModalOpen(item)}
                              style={{ zIndex: 1000 }}
                            >
                              <CIcon icon={cilTrash} className="me-2" />
                              {t('DESTROY')}
                            </CDropdownItem>
                          </CDropdownMenu>
                        </CDropdown>
                      </CTableDataCell>
                    </CTableRow>
                  ))}
                </CTableBody>
              </CTable>
              <div className="w-100 d-flex justify-content-center pt-5">
                <CSmartPagination
                  align="center"
                  activePage={currentPage}
                  pages={Number(deviceDetails?.totalPages)}
                  onActivePageChange={setCurrentPage}
                />
              </div>
            </div>
          ) : (
            <div className="w-100 d-flex justify-content-center pt-5">{t('NODATA')}</div>
          )
        ) : (
          <div className="w-100 d-flex justify-content-center pt-5">
            <CSpinner />
          </div>
        )}
      </div>
    )
  }
  return (
    <div>
      <div className="d-flex justify-content-between">
        <h1 className="w-50 d-inline">{t('DEVICE.DEVICE_LIST')}</h1>

        {userDetails?.level !== 'USER' ? (
          <div className="w-100 d-flex justify-content-end">
            <CButton
              style={{ backgroundColor: 'green', height: 38, width: 98 }}
              onClick={() => {
                setShowCreateModal(true)
                setSelectedProject(false)
              }}
            >
              {t('DEVICE.AUTHORIZE')}
            </CButton>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="w-100 d-flex justify-content-between">
        {' '}
        <div className="w-50">
          <Search />
        </div>
        <div>
          {' '}
          <CDropdown variant="dropdown" alignment="end">
            <CDropdownToggle
              placement="bottom-end"
              className="px-3  backgroundColor-info"
              caret={false}
              style={{ backgroundColour: 'red', height: 38, width: 98 }}
            >
              {t('DEVICE.FILTER')}
              <CIcon icon={cilFilter} title="Download file" />
            </CDropdownToggle>
            <CDropdownMenu className="pt-0">
              {list.map((value, index) => (
                <CDropdownItem
                  key={index + value}
                  onClick={() => handleFilter(value.value)}
                  className={`${
                    searchParams.get('filter') === value.value && 'bg-info text-white'
                  } mt-1 pb-1`}
                >
                  {value?.value === 'CLEAR_FILTER' ? (
                    <CIcon icon={cilXCircle} className="me-2 " />
                  ) : (
                    ''
                  )}
                  {value.title}
                </CDropdownItem>
              ))}
            </CDropdownMenu>
          </CDropdown>
        </div>
      </div>

      {renderTable()}
      <CModal visible={showCreateModal} onClose={() => setShowCreateModal(false)}>
        <CModalBody>
          <>
            <CModalHeader className="mb-2" closeButton>
              <CModalTitle>
                {selectedProject ? t('DEVICE.EDIT_DEVICE') : t('DEVICE.AUTHORIZE_DEVICE')}
              </CModalTitle>
            </CModalHeader>
            <CNav variant="tabs" role="tablist">
              <CNavItem role="presentation">
                <CNavLink
                  active={activeKey === 1}
                  component="button"
                  role="tab"
                  aria-controls="home-tab-pane"
                  aria-selected={activeKey === 1}
                  onClick={() => setActiveKey(1)}
                >
                  {t('DEVICE.SINGLE_DEVICE')}
                </CNavLink>
              </CNavItem>
              <CNavItem role="presentation">
                <CNavLink
                  active={activeKey === 2}
                  component="button"
                  role="tab"
                  aria-controls="profile-tab-pane"
                  aria-selected={activeKey === 2}
                  onClick={() => setActiveKey(2)}
                >
                  {t('DEVICE.MULTIPLE_DEVICE')}
                </CNavLink>
              </CNavItem>
            </CNav>
            <CTabContent>
              <CTabPane role="tabpanel" aria-labelledby="home-tab-pane" visible={activeKey === 1}>
                <Formik
                  enableReinitialize
                  initialValues={{
                    project: '',
                    model: '',
                    uuId: '',
                  }}
                  validationSchema={Yup.object().shape({
                    project: Yup.string().required('Project Name is required.'),
                    model: Yup.string().required('Model Name  is required.'),
                    uuId: Yup.string().required('UUID is required.'),
                  })}
                  onSubmit={(data) => (selectedProject ? handleUpdate(data) : handleCreate(data))}
                >
                  {({ errors, touched, values, setFieldValue, handleChange }) => {
                    return (
                      <Form>
                        <CModalBody>
                          <CRow className="mb-3">
                            <CFormLabel htmlFor="inputEmail3" className="col-sm-2 col-form-label">
                              {t('DEVICE.PROJECT')}
                            </CFormLabel>
                            <CCol sm={10}>
                              <AsyncReactSelect
                                styles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    borderColor:
                                      errors.project && touched.project
                                        ? 'red'
                                        : provided.borderColor,
                                    '&:hover': {
                                      borderColor:
                                        errors.project && touched.project
                                          ? 'red'
                                          : provided.borderColor,
                                    },
                                  }),
                                }}
                                className={`form-control ${
                                  errors.project && touched.project ? 'border-danger' : ''
                                }`}
                                dispatchFunction={getProjects}
                                keyword="keyword"
                                name="project"
                                setFieldValue={setFieldValue}
                                setId={setProjectId}
                                loadOptionsFunction={loadOptionsFunctionProject}
                                handleChange={handleChange}
                                field={'id'}
                                placeholderValue={t('TYPE_TO_SEARCH')}
                              />
                              <div className="text-danger">
                                <ErrorMessage name="project" className="invalid-feedback " />
                              </div>
                            </CCol>
                          </CRow>
                          {
                            <CRow className="mb-3">
                              <CFormLabel htmlFor="inputEmail3" className="col-sm-2 col-form-label">
                                {t('DEVICE.MODEL')}
                              </CFormLabel>
                              <CCol sm={10}>
                                <AsyncReactSelect
                                  key={values?.project}
                                  id={projectId}
                                  isDisabled={!values?.project}
                                  styles={{
                                    control: (provided, state) => ({
                                      ...provided,
                                      borderColor:
                                        errors.model && touched.model
                                          ? 'red'
                                          : provided.borderColor,
                                      '&:hover': {
                                        borderColor:
                                          errors.model && touched.model
                                            ? 'red'
                                            : provided.borderColor,
                                      },
                                    }),
                                  }}
                                  className={`form-control ${
                                    errors.model && touched.model ? 'border-danger' : ''
                                  }`}
                                  dispatchFunction={values?.project && getModelUnderProject}
                                  keyword="keyword"
                                  name="model"
                                  setFieldValue={setFieldValue}
                                  loadOptionsFunction={loadOptionsFunctionModel}
                                  handleChange={handleChange}
                                  field={'id'}
                                  placeholderValue={t('TYPE_TO_SEARCH')}
                                />
                                <div className="text-danger">
                                  <ErrorMessage name="model" className="invalid-feedback " />
                                </div>
                              </CCol>
                            </CRow>
                          }
                          <CRow className="mb-3">
                            <CFormLabel htmlFor="inputEmail3" className="col-sm-2 col-form-label">
                              UUID
                            </CFormLabel>
                            <CCol sm={10}>
                              <Field
                                type="text"
                                name="uuId"
                                className={`form-control ${
                                  errors.uuId && touched.uuId ? 'is-invalid' : ''
                                }`}
                              />
                              <ErrorMessage
                                name="uuId"
                                component="div"
                                className="invalid-feedback"
                              />{' '}
                            </CCol>
                          </CRow>
                        </CModalBody>
                        <CModalFooter>
                          <CButton color="secondary" onClick={() => closeModal()}>
                            {t('CLOSE')}
                          </CButton>
                          <CButton
                            disabled={isCreateDevice}
                            style={{ backgroundColor: 'green' }}
                            type="submit"
                            color="primary"
                          >
                            {isCreateDevice ? (
                              <CSpinner component="span" size="sm" aria-hidden="true" />
                            ) : (
                              ''
                            )}
                            &nbsp; {selectedProject ? 'Save' : t('CREATE')}
                          </CButton>
                        </CModalFooter>
                      </Form>
                    )
                  }}
                </Formik>
              </CTabPane>
              <CTabPane
                role="tabpanel"
                aria-labelledby="profile-tab-pane"
                visible={activeKey === 2}
              >
                <Formik
                  initialValues={{
                    csvFile: '',
                  }}
                  validationSchema={Yup.object().shape({
                    csvFile: Yup.mixed()
                      .required('CSV file required')
                      .test('fileType', 'Only CSV files are allowed', (value) => {
                        if (!value) return true
                        return value.type === 'text/csv'
                      }),
                  })}
                  onSubmit={(data) => handleCsv(data)}
                >
                  {({ errors, touched, values, setFieldValue }) => {
                    return (
                      <Form className="border-top-0">
                        <CModalBody className="">
                          <div>
                            {' '}
                            <CRow className="mb-3 ">
                              <CFormLabel htmlFor="inputEmail3" className="col-sm-4 col-form-label">
                                {t('DEVICE.UPLOAD_CSV')}
                              </CFormLabel>
                              <CCol sm={8}>
                                <input
                                  type="file"
                                  name="csvFile"
                                  accept=".csv"
                                  onChange={(e) => {
                                    setFieldValue('csvFile', e.target.files[0])
                                  }}
                                  className={`form-control ${
                                    errors.csvFile && touched.csvFile ? 'is-invalid' : ''
                                  } `}
                                  placeholder={t('NO_FILE_CHOSEN')}
                                />

                                <ErrorMessage
                                  name="csvFile"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </CCol>
                            </CRow>
                          </div>
                        </CModalBody>
                        <CModalFooter>
                          <CButton color="secondary" onClick={() => closeModal()}>
                            {t('CLOSE')}
                          </CButton>
                          <CButton
                            style={{ backgroundColor: 'green' }}
                            type="submit"
                            color="primary"
                          >
                            {isUpload ? (
                              <CSpinner component="span" size="sm" aria-hidden="true" />
                            ) : (
                              ''
                            )}
                            &nbsp; {selectedProject ? 'Save' : t('CREATE')}
                          </CButton>
                        </CModalFooter>
                      </Form>
                    )
                  }}
                </Formik>
              </CTabPane>
            </CTabContent>
          </>
        </CModalBody>
      </CModal>

      <CModal
        // style={{ width: 700 }}
        visible={showEditModal}
        onClose={() => setShowEditModal(false)}
      >
        {!isDeviceDetailById ? (
          <CModalBody>
            <Formik
              enableReinitialize
              initialValues={{
                project: data?.project?.projectName,
                deviceModel: data?.deviceModel?.modelName,
                serialNumber: data?.serialNumber,
                friendlyName: data?.friendlyName,
              }}
              validationSchema={Yup.object().shape({
                project: Yup.string(),
                deviceModel: Yup.string(),
                serialNumber: Yup.string(),
                friendlyName: Yup.string(),
              })}
              onSubmit={(data) => handleUpdate(data)}
            >
              {({ errors, touched, values, setFieldValue, handleChange }) => (
                <Form>
                  <CModalHeader closeButton>
                    <CModalTitle>
                      {selectedProject ? t('DEVICE.EDIT_DEVICE') : 'Create New Project'}
                    </CModalTitle>
                  </CModalHeader>
                  <CModalBody>
                    <CRow className="mb-3">
                      <CFormLabel htmlFor="colFormLabel" className="col-sm-2 col-form-label">
                        {t('DEVICE.PROJECT')}
                      </CFormLabel>
                      <CCol sm={9}>
                        <AsyncReactSelect
                          value={{ value: values?.project, label: values?.project }}
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              borderColor:
                                errors.project && touched.project ? 'red' : provided.borderColor,
                              '&:hover': {
                                borderColor:
                                  errors.project && touched.project ? 'red' : provided.borderColor,
                              },
                            }),
                          }}
                          className={`form-control ${
                            errors.project && touched.project ? 'border-danger' : ''
                          }`}
                          dependentFielCLear="deviceModel"
                          dispatchFunction={getProjects}
                          keyword="keyword"
                          name="project"
                          setFieldValue={setFieldValue}
                          setId={setProjectId}
                          loadOptionsFunction={loadOptionsFunctionProject}
                          handleChange={handleChange}
                          loadMoreOptionsFunction={loadMoreOptionsProject}
                        />
                        <div className="text-danger">
                          <ErrorMessage name="project" className="invalid-feedback " />
                        </div>{' '}
                      </CCol>
                    </CRow>
                    <CRow className="mb-3">
                      <CFormLabel htmlFor="colFormLabel" className="col-sm-2 col-form-label">
                        {t('DEVICE.MODEL')}
                      </CFormLabel>
                      <CCol sm={9}>
                        <AsyncReactSelect
                          value={{ value: values?.deviceModel, label: values?.deviceModel }}
                          key={values?.project}
                          id={projectId}
                          isDisabled={!values?.project}
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              borderColor:
                                errors.deviceModel && touched.deviceModel
                                  ? 'red'
                                  : provided.borderColor,
                              '&:hover': {
                                borderColor:
                                  errors.deviceModel && touched.deviceModel
                                    ? 'red'
                                    : provided.borderColor,
                              },
                            }),
                          }}
                          className={`form-control ${
                            errors.deviceModel && touched.deviceModel ? 'border-danger' : ''
                          }`}
                          dispatchFunction={projectId && getModelUnderProject}
                          keyword="keyword"
                          name="deviceModel"
                          setFieldValue={setFieldValue}
                          loadOptionsFunction={loadOptionsFunctionModel}
                          handleChange={handleChange}
                          loadMoreOptionsFunction={loadMoreOptionsModel}
                          // field={'id'}
                        />
                        <div className="text-danger">
                          <ErrorMessage name="deviceModel" className="invalid-feedback " />
                        </div>
                      </CCol>
                    </CRow>
                    <CRow className="mb-3">
                      <CFormLabel htmlFor="colFormLabel" className="col-sm-2 col-form-label">
                        {t('DEVICE.SERIAL_NUMBER')}
                      </CFormLabel>
                      <CCol sm={9}>
                        <Field
                          type="text"
                          name="serialNumber"
                          className={`form-control ${
                            errors.serialNumber && touched.serialNumber ? 'is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage
                          name="serialNumber"
                          component="div"
                          className="invalid-feedback"
                        />{' '}
                      </CCol>
                    </CRow>
                    <CRow className="mb-3">
                      <CFormLabel htmlFor="colFormLabel" className="col-sm-2 col-form-label">
                        {t('DEVICE.FRIENDLY_NAME')}
                      </CFormLabel>
                      <CCol sm={9}>
                        <Field
                          type="text"
                          value={values.friendlyName}
                          name="friendlyName"
                          className={`form-control ${
                            errors.friendlyName && touched.friendlyName ? 'is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage
                          name="friendlyName"
                          component="div"
                          className="invalid-feedback"
                        />{' '}
                      </CCol>
                    </CRow>
                    <CFormLabel className="mt-2"></CFormLabel>
                  </CModalBody>
                  {/* <CModalFooter> */}
                  <div className="w-full d-flex  justify-content-between border-top pt-3">
                    <div>
                      <CLoadingButton
                        onClick={handleUnregister}
                        color="danger"
                        variant="outline"
                        timeout={2000}
                      >
                        Unregister
                      </CLoadingButton>
                    </div>
                    <div className="d-flex gap-2">
                      <CButton color="secondary" onClick={() => closeModal()}>
                        Close
                      </CButton>
                      <CButton type="submit" color="primary">
                        {isUpdateDevice ? (
                          <CSpinner component="span" size="sm" aria-hidden="true" />
                        ) : (
                          ''
                        )}
                        &nbsp; Save
                      </CButton>
                    </div>
                  </div>
                  {/* </CModalFooter> */}
                </Form>
              )}
            </Formik>
          </CModalBody>
        ) : (
          <div className="text-center">
            <CSpinner />
          </div>
        )}
      </CModal>
      <CModal visible={showDeleteModal} onClose={() => closeModal()}>
        <CModalHeader closeButton>
          <CModalTitle>{t('DEVICE.DESTROY_DEVICE')}</CModalTitle>
        </CModalHeader>
        <CModalBody>{t('DEVICE.ARE_YOU_SURE')}</CModalBody>
        <CModalBody>
          {' '}
          <CFormCheck
            checked={deleteToggle}
            onClick={() => setDeleteToggle(!deleteToggle)}
            id="checkboxNoLabel"
            value=""
            aria-label="..."
          />
          &nbsp; {t('DEVICE.I_CONFIRM')}
          <div className="px-3 text-secondary pt-3">{t('DEVICE.NOTE')} </div>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => closeModal()}>
            {t('CLOSE')}
          </CButton>
          <CButton
            disabled={!deleteToggle}
            style={{
              border: '1px solid #ff0000',
              marginLeft: '10px',
              backgroundColor: 'white',
              color: 'red',
            }}
            onClick={() => onDeleteClick()}
            type="submit"
          >
            {isdeleteDevice ? <CSpinner component="span" size="sm" aria-hidden="true" /> : ''}
            &nbsp; {t('DESTROY')}
          </CButton>
        </CModalFooter>
      </CModal>
    </div>
  )
}

export default DeviceListing
