import React from 'react'
import {
  cilCaretBottom,
  cilCaretTop,
  cilColorBorder,
  cilSwapVertical,
  cilTrash,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CButton,
  CCol,
  CFormCheck,
  CFormLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSmartPagination,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react-pro'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import Search from 'src/views/components/serach/search'
import { handleUtcToMMDDYY } from 'src/views/helpers/utilityFunctions'
import {
  createProjects,
  deleteProjectsById,
  getProjectById,
  getProjects,
  updataProjectsById,
} from 'src/views/slices/projectSlice'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

const Projects = () => {
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [selectedProject, setSelectedProject] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectDeleteProject, setSelectDeleteProject] = useState(null)
  const [projectName, setProjectName] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [deleteToggle, setDeleteToggle] = useState(false)
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const {
    isgettingProjects,
    projects,
    isCreatingProject,
    isUpdatingProject,
    isDeleteingProjectsById,
  } = useSelector((store) => store.project)
  const [searchParams, setSearchParams] = useSearchParams()
  useEffect(() => {
    if (!searchParams.get('page')) setSearchParams({ page: 1, size: 10 })
    else {
      const params = {}
      searchParams.forEach((value, key) => {
        params[key] = value
      })
      params['page'] = currentPage
      setSearchParams(params)
    }
  }, [currentPage])

  useEffect(() => {
    if (searchParams.get('page')) {
      const params = {}
      searchParams.forEach((value, key) => {
        params[key] = value
      })
      if (searchParams.get('keyword')) {
        params['projectName'] = searchParams.get('keyword')
        delete params['keyword']
      }

      dispatch(getProjects(params))
    }
  }, [searchParams])

  const openEditModal = async (project) => {
    try {
      const response = await dispatch(getProjectById(project?.id))
      const projectData = response.payload?.data[0]
      setSelectedProject(project)
      setProjectName(projectData.projectName)
      setShowCreateModal(true)
    } catch (err) {
      return null
    }
  }

  const handleCreate = async (data) => {
    try {
      await dispatch(createProjects(data))
      dispatch(getProjects())
      setProjectName('')
      setShowCreateModal(false)
    } catch (err) {
      return null
    }
  }

  const handleUpdate = async (data) => {
    if (!selectedProject) return

    try {
      await dispatch(
        updataProjectsById({
          data,
          id: selectedProject.id,
        }),
      )
      setProjectName('')
      setShowCreateModal(false)
    } catch (err) {
      return null
    }
  }
  const onDeleteModalOpen = async (project) => {
    setShowDeleteModal(true)
    setSelectDeleteProject(project?.id)
  }
  const onDeleteClick = async (project) => {
    try {
      await dispatch(deleteProjectsById(selectDeleteProject)).unwrap()
      setDeleteToggle(false)
    } catch (err) {
      setDeleteToggle(false)
      return err
    }
    closeModal()
    setSelectDeleteProject(null)
  }
  const closeModal = () => {
    setShowCreateModal(false)
    setProjectName('')
    setShowDeleteModal(false)
    setSelectDeleteProject(null)
  }

  const handleSort = (asc, desc) => {
    const params = {}
    searchParams.forEach((value, key) => {
      params[key] = value
    })
    if (!searchParams.get('sort') || ![asc, desc].includes(searchParams.get('sort'))) {
      params['sort'] = asc
    }
    if (searchParams.get('sort') === asc) {
      params['sort'] = desc
    } else params['sort'] = asc
    setSearchParams(params)
  }

  const tableHeader = [
    {
      title: t('PROJECT.PROJECT_NAME'),
      value: 'PROJECT_NAME',
      asc: 'projectName:asc',
      desc: 'projectName:desc',
      required: true,
    },
    {
      title: t('REGISTERED_DEVICE'),
      value: 'REGISTERED_DEVICES',
      asc: 'registeredDevices:asc',
      desc: 'registeredDevices:desc',
      required: true,
    },
    {
      title: t('UN-REGISTERED_DEVICES'),
      value: 'UN-REGISTERED_DEVICES',
      asc: 'unRegisteredDevices:asc',
      desc: 'unRegisteredDevices:desc',
      required: true,
    },
    {
      title: t('AUTHORIZED_DEVICES'),
      value: 'AUTHORIZED_DEVICES',
      asc: 'authorizedDevices:asc',
      desc: 'authorizedDevices:desc',
      required: true,
    },
    {
      title: t('CREATED_DATE'),
      value: 'CREATED_DATE',
      asc: 'createdDate:asc',
      desc: 'createdDate:desc',
      required: true,
    },

    {
      title: t('ACTIONS'),
      value: 'ACTIONS',

      required: false,
    },
  ]
  const renderTable = () => {
    return isgettingProjects ? (
      <div
        style={{ height: '10rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <CSpinner />
      </div>
    ) : projects?.data?.length > 0 ? (
      <div>
        <CTable striped responsive>
          <CTableHead>
            <CTableRow>
              {tableHeader.map((item, index) => (
                <CTableHeaderCell key={item.value + index}>
                  <div className="d-flex gap-3  inline ">
                    {' '}
                    {item.title}{' '}
                    {item.required ? (
                      <CIcon
                        size="lg"
                        icon={
                          [item.asc, item.desc].includes(searchParams.get('sort'))
                            ? searchParams.get('sort') === item.asc
                              ? cilCaretTop
                              : cilCaretBottom
                            : cilSwapVertical
                        }
                        // className="me-2 "
                        onClick={() => {
                          if (item.required) handleSort(item.asc, item.desc)
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </CTableHeaderCell>
              ))}
              {/* <CTableHeaderCell>PROJECT NAME</CTableHeaderCell>
              <CTableHeaderCell>REGISTERED DEVICES</CTableHeaderCell>
              <CTableHeaderCell>UN-REGISTERED DEVICES</CTableHeaderCell>
              <CTableHeaderCell>AUTHORIZED DEVICES</CTableHeaderCell>
              <CTableHeaderCell>CREATED DATE</CTableHeaderCell>
              <CTableHeaderCell>ACTIONS</CTableHeaderCell> */}
            </CTableRow>
          </CTableHead>

          <CTableBody>
            {projects?.data?.map((project, index) => (
              <CTableRow key={project?.id}>
                <CTableDataCell>{project?.projectName}</CTableDataCell>
                <CTableDataCell>{project?.registeredDevices}</CTableDataCell>
                <CTableDataCell>{project?.unRegisteredDevices}</CTableDataCell>
                <CTableDataCell>{project?.authorizedDevices}</CTableDataCell>
                <CTableDataCell>{handleUtcToMMDDYY(project?.createdDate)}</CTableDataCell>
                <CTableDataCell>
                  <CButton
                    style={{
                      backgroundColor: '#3a9bf0',
                    }}
                    onClick={() => openEditModal(project)}
                  >
                    <CIcon icon={cilColorBorder} className="me-2" />
                    {t('EDIT')}
                  </CButton>
                  <CButton
                    style={{
                      border: '1px solid #ff0000',
                      marginLeft: '10px',
                      backgroundColor: 'white',
                      color: 'red',
                    }}
                    onClick={() => onDeleteModalOpen(project)}
                  >
                    <CIcon icon={cilTrash} className="me-2" />
                    {t('DESTROY')}
                  </CButton>
                </CTableDataCell>
              </CTableRow>
            ))}
          </CTableBody>
        </CTable>
        <div className="w-100 d-flex justify-content-center pt-5">
          <CSmartPagination
            align="center"
            activePage={currentPage}
            pages={Number(projects?.totalPages)}
            onActivePageChange={setCurrentPage}
          />
        </div>
      </div>
    ) : (
      <div
        style={{ height: '10rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        No Data
      </div>
    )
  }
  return (
    <div>
      <div className=" d-flex">
        <h1>{t('PROJECT.PROJECTS')}</h1>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '20px',
          }}
        >
          <CButton
            style={{ backgroundColor: 'green' }}
            onClick={() => (setSelectedProject(false), setShowCreateModal(true))}
          >
            {t('CREATE_NEW')}
          </CButton>
        </div>
      </div>
      <div className="w-50">
        <Search />
      </div>

      {renderTable()}
      <CModal visible={showCreateModal} onClose={() => closeModal()}>
        <Formik
          initialValues={{ projectName: projectName }}
          validationSchema={Yup.object().shape({
            projectName: Yup.string().required('Project Name is required.'),
          })}
          onSubmit={(data) => (selectedProject ? handleUpdate(data) : handleCreate(data))}
        >
          {({ errors, touched }) => (
            <Form>
              <CModalHeader closeButton>
                <CModalTitle>
                  {selectedProject ? t('PROJECT.EDIT_PROJECT') : t('PROJECT.CREATE_NEW')}
                </CModalTitle>
              </CModalHeader>
              <CModalBody className="mt-3">
                <CRow className="mb-3">
                  <CFormLabel htmlFor="colFormLabel" className="col-sm-3 col-form-label">
                    {t('PROJECT.PROJECT_NAME')}
                  </CFormLabel>
                  <CCol sm={9}>
                    <Field
                      type="text"
                      name="projectName"
                      className={`form-control ${
                        errors.projectName && touched.projectName ? 'is-invalid' : ''
                      }`}
                    />
                    <ErrorMessage
                      name="projectName"
                      component="div"
                      className="invalid-feedback "
                    />{' '}
                  </CCol>
                </CRow>
              </CModalBody>
              <CModalFooter>
                <CButton color="secondary" onClick={() => closeModal()}>
                  {t('CLOSE')}
                </CButton>
                <CButton
                  disabled={isUpdatingProject || isCreatingProject}
                  style={{ backgroundColor: 'green' }}
                  type="submit"
                  color="primary"
                >
                  {isUpdatingProject || isCreatingProject ? (
                    <CSpinner component="span" size="sm" aria-hidden="true" />
                  ) : (
                    ''
                  )}
                  &nbsp; {selectedProject ? t('SAVE') : t('CREATE')}
                </CButton>
              </CModalFooter>
            </Form>
          )}
        </Formik>
      </CModal>
      <CModal visible={showDeleteModal} onClose={() => closeModal()}>
        <CModalHeader closeButton>
          <CModalTitle>{t('PROJECT.DESTROY_PROJECT')}</CModalTitle>
        </CModalHeader>
        <CModalBody>{t('PROJECT.ARE_YOU_SURE')}</CModalBody>
        <div className="d-flex pb-1 px-3">
          <CFormCheck
            checked={deleteToggle}
            onClick={() => setDeleteToggle(!deleteToggle)}
            id="checkboxNoLabel"
            value=""
            aria-label="..."
          />
          &nbsp;{t('PROJECT.I_CONFIRM')}
        </div>
        <div className="px-3 text-secondary pt-3">{t('PROJECT.NOTE')} </div>

        <CModalFooter>
          <CButton color="secondary" onClick={() => closeModal()}>
            {t('CLOSE')}
          </CButton>
          <CButton
            className="border-1"
            style={{
              border: '1px solid #ff0000',
              marginLeft: '10px',
              backgroundColor: 'white',
              color: 'red',
            }}
            onClick={() => onDeleteClick()}
            type="submit"
            disabled={!deleteToggle}
          >
            {isDeleteingProjectsById ? (
              <CSpinner component="span" size="sm" aria-hidden="true" />
            ) : (
              ''
            )}
            &nbsp; {t('DESTROY')}
          </CButton>
        </CModalFooter>
      </CModal>
    </div>
  )
}

export default Projects
