import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { deviceUnregister } from '../services/deviceListing'
import { postAutoGenerate, postRegisterDevice } from '../services/registerDevice'
import { showAlert } from './toastSlice'

const initialState = {
  isDeviceRegister: false,
  isAutoGenerate: false,
  autoGenerateDetails: '',
}

export const registerDeviceSlice = createSlice({
  name: 'registerDevice',
  initialState,
  reducers: {
    clearAutoGenerate: (state, action) => {
      return {
        autoGenerateDetails: { mqttUsername: '', mqttPassword: '', mqttClientId: '' },
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(autoGenerate.pending, (state) => {
        state.isAutoGenerate = true
      })
      .addCase(autoGenerate.fulfilled, (state, action) => {
        state.isAutoGenerate = false
        state.autoGenerateDetails = action.payload?.data[0]
      })
      .addCase(autoGenerate.rejected, (state) => {
        state.isAutoGenerate = false
      })
      .addCase(registerDevice.pending, (state) => {
        state.isDeviceRegister = true
      })
      .addCase(registerDevice.fulfilled, (state, action) => {
        state.isDeviceRegister = false
      })
      .addCase(registerDevice.rejected, (state) => {
        state.isDeviceRegister = false
      })
  },
})

export const autoGenerate = createAsyncThunk(
  'register/autogenerate',
  async (userData, thunkAPI) => {
    try {
      const response = await postAutoGenerate(userData)
      thunkAPI.dispatch(showAlert({ type: 'SUCCESS', message: response?.message }))
      return response
    } catch (err) {
      thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
      return thunkAPI.rejectWithValue(err)
    }
  },
)
export const registerDevice = createAsyncThunk('register/device', async (userData, thunkAPI) => {
  try {
    const response = await postRegisterDevice(userData)
    thunkAPI.dispatch(showAlert({ type: 'SUCCESS', message: response?.message }))
    return response
  } catch (err) {
    thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
    return thunkAPI.rejectWithValue(err)
  }
})
export const unregisterDevice = createAsyncThunk(
  'unregister/device',
  async (userData, thunkAPI) => {
    try {
      const response = await deviceUnregister(userData)
      thunkAPI.dispatch(showAlert({ type: 'SUCCESS', message: response?.message }))
      return response
    } catch (err) {
      thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
      return thunkAPI.rejectWithValue(err)
    }
  },
)
export const { clearAutoGenerate } = registerDeviceSlice.actions
export default registerDeviceSlice.reducer
export const deviceRegisterReducer = registerDeviceSlice.reducer
