import React from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardText,
  CCardTitle,
  CCol,
  CContainer,
  CFormLabel,
  CModal,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSpinner,
} from '@coreui/react-pro'
import { ErrorMessage, Form, Formik } from 'formik'
import debounce from 'lodash.debounce'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AsyncSelect from 'react-select/async'
import ImageContainer from 'src/views/components/image/image'
import PluginLoader from 'src/views/components/pluginLoader/pluginLoader'
import { getAllDeviceDetails } from 'src/views/slices/device'
import { getMetaData, getPlugins, postMetaData } from 'src/views/slices/plugins'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import SelectAsyncPaginate from 'src/views/components/AsyncSelectPagination/AsyncSelectPagination'

const Plugin = () => {
  const [visible, setVisible] = useState(false)
  const [cardData, setCardData] = useState()
  const { pluginListing, isPlugin, isPostMetaData, isGetMetaData } = useSelector(
    (state) => state.plugins,
  )

  const { t } = useTranslation('common')

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handlePlugin = async (data) => {
    const [project, model, serialNumber] = data?.deviceName?.split('/')
    try {
      let coordinate = { x: 0, y: 0 }
      const metaData = await dispatch(getMetaData())
      const response = await dispatch(getMetaData({ page: metaData?.payload?.data[0]?.totalPages }))

      if (response?.payload?.data[0]?.data?.length === 0) {
        coordinate.x = 0
        coordinate.y = 0
      }
      response?.payload?.data[0]?.data?.forEach((value, index) => {
        if (value.y > coordinate.y) {
          coordinate.x = value.x
          coordinate.y = value.y
        }
        if (value.y === coordinate.y) {
          if (value.x > coordinate.x) coordinate.x = value.x
        }
      })
      if (coordinate.x === 0) {
        coordinate.x = coordinate.x + 1
      } else {
        coordinate.y = coordinate.y + 1
        coordinate.x = 0
      }
      await dispatch(
        postMetaData({
          pluginName: cardData?.plugins?.name,
          pluginId: cardData?.plugins?.id,
          gridIndex: response?.payload?.data[0]?.data?.length
            ? Number(
                response?.payload?.data[0]?.data[response?.payload?.data[0]?.data?.length - 1]
                  ?.gridIndex,
              ) + 1
            : 0,
          // gridIndex: '0',
          height: 6.5,
          width: 1,
          connectedDevice: data?.deviceName,
          project,
          model,
          serialNumber,
          x: coordinate.x,
          y: coordinate.y,
        }),
      ).unwrap()

      setVisible(false)

      navigate('/dashboard')
    } catch (err) {
      return null
    }
  }
  useEffect(() => {
    dispatch(getPlugins())
  }, [])

  const deviceApi = async (page) => {
    const response = await dispatch(
      getAllDeviceDetails({
        page: page + 1,
        size: 10,
      }),
    )
    return {
      data: response?.payload?.data[0]?.data?.map((value) => {
        return {
          value: `${value.project?.projectName}/${value.deviceModel?.modelName}/${value.serialNumber}`,
          label: `${value.project?.projectName}/${value.deviceModel?.modelName}/${value.serialNumber}`,
        }
      }),
      page: response?.payload?.data[0]?.page,
      size: response?.payload?.data[0]?.page,
      totalPages: response?.payload?.data[0]?.totalPages,
      totalElements: response?.payload?.data[0]?.totalElements,
    }
  }
  return (
    <div>
      <div className="w-100  d-flex justify-content-between mb-3">
        {' '}
        <h1>{t('PLUGIN.PLUGINS')}</h1>
      </div>

      <CModal
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="LiveDemoExampleLabel"
      >
        <CModalHeader onClose={() => setVisible(false)}>
          <CModalTitle id="LiveDemoExampleLabel">{t('PLUGIN.SELECT_DEVICE')}</CModalTitle>
        </CModalHeader>
        {
          <Formik
            enableReinitialize
            initialValues={{ deviceName: '' }}
            validationSchema={Yup.object().shape({
              deviceName: Yup.mixed().required('Please select a device'),
            })}
            onSubmit={(data) => handlePlugin(data)}
          >
            {({
              errors,
              touched,
              values,
              setFieldValue,
              setFieldTouched,
              handleBlur,
              handleChange,
            }) => (
              <Form>
                <CRow className="my-3 mx-1 ">
                  <CFormLabel htmlFor="colFormLabel" className="col-sm-3 col-form-label">
                    {t('PLUGIN.DEVICE_NAME')}
                  </CFormLabel>
                  <CCol sm={9}>
                    <div className="form-group">
                      <SelectAsyncPaginate
                        isSearchable={true}
                        apiCall={deviceApi}
                        className="flex-grow-1 "
                        value={{
                          value: values?.deviceName,
                          label: values?.deviceName,
                        }}
                        onChange={(e) => {
                          setFieldValue(`deviceName`, e?.value)
                        }}
                      />
                      <ErrorMessage
                        name="deviceName"
                        component="div"
                        className={`invalid-feedback  border border-danger `}
                      />{' '}
                    </div>
                    <div className="text-danger">
                      {errors.deviceName && touched.deviceName && errors.deviceName}
                    </div>
                  </CCol>
                </CRow>
                <CModalFooter>
                  <CButton color="secondary" onClick={() => setVisible(false)}>
                    {t('CLOSE')}
                  </CButton>
                  <CButton disabled={isPostMetaData || isGetMetaData} type="submit" color="primary">
                    {isPostMetaData || isGetMetaData ? (
                      <CSpinner component="span" size="sm" aria-hidden="true" />
                    ) : (
                      ''
                    )}
                    &nbsp; {t('PLUGIN.ADD_TO_DASHBOARD')}
                  </CButton>
                </CModalFooter>
              </Form>
            )}
          </Formik>
        }
      </CModal>

      <CContainer style={{ maxWidth: '100%' }}>
        <CRow md={{ cols: 3, gutter: 4 }} lg={{ cols: 5, gutter: 4 }} xl={{ cols: 5, gutter: 4 }}>
          {isPlugin
            ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value, index) => (
                <CCol key={index}>
                  {' '}
                  <PluginLoader />
                </CCol>
              ))
            : pluginListing?.map((value, index) => (
                <CCol key={index}>
                  <CCard style={{ width: '18rem' }}>
                    <ImageContainer
                      id={value?.defaultPluginImageId}
                      style={{ width: '288px', height: '370px' }}
                      alternate={''}
                    />
                    <CCardBody>
                      <CCardTitle>{value?.plugins?.name}</CCardTitle>
                      <CCardText>{value?.plugins?.subdescription}</CCardText>
                      <CButton onClick={() => (setVisible(true), setCardData(value))}>
                        {t('PLUGIN.ADD_THIS_PLUGIN')}
                      </CButton>
                    </CCardBody>
                  </CCard>
                </CCol>
              ))}
        </CRow>
      </CContainer>
    </div>
  )
}

export default Plugin
