import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  lang: '',
}

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    changeLanguage: (state, action) => {
      return {
        lang: action.payload?.type,
      }
    },
  },
})

export const { changeLanguage } = languageSlice.actions
export default languageSlice.reducer
