import React from 'react'
import Automation from './views/pages/automation/automation'
import DeviceRegister from './views/pages/deviceRegister/deviceRegister'
import Plugin from './views/pages/plugins/plugins'

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const Projects = React.lazy(() => import('./views/pages/projects/Projects'))
const Models = React.lazy(() => import('./views/pages/models/Models'))
const DeviceListing = React.lazy(() => import('./views/pages/deviceListing/index'))
const Users = React.lazy(() => import('./views/pages/users/users'))
const Settings = React.lazy(() => import('./views/pages/settings/settings'))

const routes = [
  // { path: '/', exact: true, name: 'Dashboard' },

  {
    path: '/dashboard',
    name: 'Dashboard',
    element: <Dashboard />,
    isProtected: true,
    roles: ['USER', 'MANAGER', 'ADMIN'],
  },
  {
    path: '/projects',
    name: 'Projects',
    element: <Projects />,
    isProtected: true,
    roles: ['MANAGER', 'ADMIN'],
  },
  {
    path: '/models',
    name: 'Models',
    element: <Models />,
    isProtected: true,
    roles: ['MANAGER', 'ADMIN'],
  },
  {
    path: '/plugins',
    name: 'Plugins',
    element: <Plugin />,
    isProtected: true,
    roles: ['MANAGER', 'ADMIN', 'USER'],
  },
  {
    path: '/automation',
    name: 'Automation',
    element: <Automation />,
    isProtected: true,
    roles: ['MANAGER', 'ADMIN', 'USER'],
  },
  {
    path: '/devices',
    name: 'Devices',
    isProtected: true,
    element: <DeviceListing />,
    roles: ['USER', 'MANAGER', 'ADMIN'],
  },
  {
    path: '/users',
    name: 'Users',
    isProtected: true,
    element: <Users />,
    roles: ['ADMIN', 'MANAGER'],
  },
  {
    path: '/device-register',
    name: 'Device register',
    isProtected: true,
    element: <DeviceRegister />,
    roles: ['ADMIN', 'MANAGER'],
  },
  {
    path: '/settings',
    name: 'Settings',
    isProtected: true,
    element: <Settings />,
    roles: ['ADMIN', 'MANAGER', 'USER'],
  },
]

export default routes
