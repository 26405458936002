import React from 'react'
import {
  cilCaretBottom,
  cilCaretTop,
  cilColorBorder,
  cilSwapVertical,
  cilTrash,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CButton,
  CCol,
  CFormCheck,
  CFormLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSmartPagination,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react-pro'
import { Editor } from '@monaco-editor/react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import AsyncReactSelect from 'src/views/components/asyncRactSelec/asyncReactSelect'
import NoData from 'src/views/components/noData/noData'
import Search from 'src/views/components/serach/search'
import { handleUtcToMMDDYY } from 'src/views/helpers/utilityFunctions'
import {
  createModels,
  deleteModelsById,
  getModelById,
  getModels,
  getModelSettings,
  updataModelsById,
} from 'src/views/slices/modelSlice'
import { getProjects } from 'src/views/slices/projectSlice'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

const Models = () => {
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectDeleteModel, setSelectDeleteModel] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedModel, setSelectedModel] = useState(null)
  const [editData, setModelEdit] = useState('')
  const [searchParams, setSearchParams] = useSearchParams()
  const [deleteToggle, setDeleteToggle] = useState(false)
  const [projectId, setProjectId] = useState()
  const { t } = useTranslation('common')

  const dispatch = useDispatch()
  const {
    isgettingModels,
    models,
    isCreatingModel,
    isUpdatingModel,
    isDeleteingModelsById,
    isModalSettings,
    modelSettings,
  } = useSelector((store) => store.model)
  useEffect(() => {
    if (!searchParams.get('page')) setSearchParams({ page: 1, size: 10 })
    else {
      const params = {}
      searchParams.forEach((value, key) => {
        params[key] = value
      })
      params['page'] = currentPage
      setSearchParams(params)
    }
  }, [currentPage])

  useEffect(() => {
    if (searchParams.get('page')) {
      const params = {}
      searchParams.forEach((value, key) => {
        params[key] = value
      })
      if (searchParams.get('keyword')) {
        params['modelName'] = searchParams.get('keyword')
        delete params['keyword']
      }

      dispatch(getModels(params))
    }
  }, [searchParams])

  const openEditModal = async (project) => {
    try {
      const response = await dispatch(getModelById(project?.id))
      const modelData = response.payload?.data[0]
      setSelectedModel(project)
      setModelEdit({
        modelName: modelData?.modelName,
        projectName: modelData?.projectId?.projectName,
        settings: modelData?.settings,
        projectId: modelData?.projectId?.id,
      })
      setShowCreateModal(true)
    } catch (err) {
      return null
    }
  }

  const handleCreate = async (data) => {
    var payload = data
    const temp = JSON.parse(data?.settings)
    delete payload['settings']

    payload = { ...payload, ...temp }
    try {
      await dispatch(createModels(payload)).unwrap()
      setModelEdit('')
      setShowCreateModal(false)
      dispatch(getModels({ page: currentPage, size: '10' }))
    } catch (err) {
      return null
    }
  }

  const loadOptionsFunctionProject = (response, loadedOptions) => {
    response?.payload?.data[0]?.data?.map((value, index) => {
      Object.keys(value).map((item) => {
        if (item === 'projectName')
          loadedOptions.push({
            value: value?.projectName,
            label: value?.projectName,
            id: value?.id,
          })
      })
    })
  }
  function formatJSON(val = {}) {
    try {
      const res = JSON.parse(val)
      return JSON.stringify(res, null, 2)
    } catch {}
  }
  const handleUpdate = async (data) => {
    if (!selectedModel) return
    var payload = data
    const temp = JSON.parse(data?.settings)
    delete payload['settings']
    payload['projectName'] = editData?.projectId
    payload = { ...payload, settings: { ...temp } }
    try {
      await dispatch(
        updataModelsById({
          payload,
          id: selectedModel.id,
        }),
      )
      setModelEdit('')
      setShowCreateModal(false)
      dispatch(getModels({ page: currentPage, size: '10' }))
    } catch (err) {
      return null
    }
  }
  const onDeleteClick = async () => {
    try {
      await dispatch(deleteModelsById(selectDeleteModel)).unwrap()
      setDeleteToggle(false)
    } catch (err) {
      setDeleteToggle(false)
      return err
    }
    closeModal()
    setSelectDeleteModel(null)
    dispatch(getModels({ page: currentPage, size: '10' }))
  }
  const onDeleteModalOpen = async (model) => {
    setShowDeleteModal(true)
    setSelectDeleteModel(model?.id)
  }
  const closeModal = () => {
    setShowCreateModal(false)
    setShowDeleteModal(false)
    setModelEdit('')
    setSelectDeleteModel(null)
  }
  const handleSort = (asc, desc) => {
    const params = {}
    searchParams.forEach((value, key) => {
      params[key] = value
    })
    if (!searchParams.get('sort') || ![asc, desc].includes(searchParams.get('sort'))) {
      params['sort'] = asc
    }
    if (searchParams.get('sort') === asc) {
      params['sort'] = desc
    } else params['sort'] = asc
    setSearchParams(params)
  }
  const tableHeader = [
    {
      title: t('MODEL.MODEL_NAME'),
      value: 'MODEL_NAME',
      asc: 'modelName:asc',
      desc: 'modelName:desc',
      required: true,
    },
    {
      title: t('REGISTERED_DEVICE'),
      value: 'REGISTERED_DEVICE',
      asc: 'registeredDevices:asc',
      desc: 'registeredDevices:desc',
      required: true,
    },
    {
      title: t('UN-REGISTERED_DEVICES'),
      value: 'UN-REGISTERED_DEVICES',
      asc: 'unRegisteredDevices:asc',
      desc: 'unRegisteredDevices:desc',
      required: true,
    },
    {
      title: t('AUTHORIZED_DEVICES'),
      value: 'AUTHORIZED_DEVICES',
      asc: 'authorizedDevices:asc',
      desc: 'authorizedDevices:desc',
      required: true,
    },
    {
      title: t('CREATED_DATE'),
      value: 'CREATED_DATE',
      asc: 'createdDate:asc',
      desc: 'createdDate:desc',
      required: true,
    },

    {
      title: t('ACTIONS'),
      value: 'ACTIONS',

      required: false,
    },
  ]
  const renderTable = () => {
    const selectStyle = (value) => {
      const color = { ACTIVATED: 'green', DEACTIVATED: 'gray' }
      const boxStyle = {
        padding: '2px 4px 2px 4px',
        backgroundColor: color[value],
        color: 'white',
        borderRadius: '4px',
        textAlign: 'center',
      }
      return boxStyle
    }
    return isgettingModels ? (
      <div
        style={{ height: '10rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <CSpinner />
      </div>
    ) : models?.data?.length > 0 ? (
      <div>
        <CTable striped responsive>
          <CTableHead>
            <CTableRow>
              {tableHeader.map((item, index) => (
                <CTableHeaderCell key={item.value + index}>
                  <div className="d-flex gap-3  inline ">
                    {' '}
                    {item.title}{' '}
                    {item.required ? (
                      <CIcon
                        size="lg"
                        icon={
                          [item.asc, item.desc].includes(searchParams.get('sort'))
                            ? searchParams.get('sort') === item.asc
                              ? cilCaretTop
                              : cilCaretBottom
                            : cilSwapVertical
                        }
                        // className="me-2 "
                        onClick={() => {
                          if (item.required) handleSort(item.asc, item.desc)
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </CTableHeaderCell>
              ))}
            </CTableRow>
          </CTableHead>

          <CTableBody>
            {models?.data?.map((model, index) => (
              <CTableRow key={model?.id}>
                <CTableDataCell>{model?.modelName}</CTableDataCell>
                <CTableDataCell>{model?.registeredDevices}</CTableDataCell>
                <CTableDataCell>{model?.unRegisteredDevices}</CTableDataCell>
                <CTableDataCell>{model?.authorizedDevices}</CTableDataCell>
                <CTableDataCell>{handleUtcToMMDDYY(model?.createdDate)}</CTableDataCell>
                <CTableDataCell>
                  <CButton
                    style={{
                      backgroundColor: '#3a9bf0',
                    }}
                    onClick={() => openEditModal(model)}
                  >
                    <CIcon icon={cilColorBorder} className="me-2" />
                    {t('EDIT')}
                  </CButton>
                  <CButton
                    style={{
                      border: '1px solid #ff0000',
                      marginLeft: '10px',
                      backgroundColor: 'white',
                      color: 'red',
                    }}
                    onClick={() => onDeleteModalOpen(model)}
                  >
                    <CIcon icon={cilTrash} className="me-2" />
                    {t('DESTROY')}
                  </CButton>
                </CTableDataCell>
              </CTableRow>
            ))}
          </CTableBody>
        </CTable>
        <div className="w-100 d-flex justify-content-center pt-5">
          <CSmartPagination
            align="center"
            activePage={Number(searchParams.get('page'))}
            pages={Number(models?.totalPages)}
            onActivePageChange={setCurrentPage}
          />
        </div>
      </div>
    ) : (
      <NoData />
    )
  }
  return (
    <div className=" zoom-in">
      <div className="d-flex">
        <h1>{t('MODEL.MODEL')}</h1>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '20px',
          }}
        >
          <CButton
            style={{ backgroundColor: 'green' }}
            onClick={() => (
              setShowCreateModal(true), setSelectedModel(false), dispatch(getModelSettings())
            )}
          >
            {t('CREATE_NEW')}
          </CButton>
        </div>
      </div>
      <div className="w-50">
        <Search />
      </div>

      {renderTable()}
      <CModal visible={showCreateModal} onClose={() => closeModal()}>
        <Formik
          enableReinitialize
          initialValues={{
            modelName: editData?.modelName,
            projectName: editData?.projectName,
            settings: selectedModel
              ? JSON.stringify(editData?.settings)
              : JSON.stringify(modelSettings),
          }}
          validationSchema={Yup.object().shape({
            modelName: Yup.string().required('Model Name is required.'),
            projectName: Yup.string().required('Project Name is required.'),
            //  modelSettings:
          })}
          onSubmit={(data) => (selectedModel ? handleUpdate(data) : handleCreate(data))}
        >
          {({ errors, touched, values, setFieldValue, handleChange }) => (
            <Form>
              <CModalHeader closeButton>
                <CModalTitle>
                  {selectedModel ? t('MODEL.EDIT_MODEL') : t('MODEL.CREATE_NEW')}
                </CModalTitle>
              </CModalHeader>
              <CModalBody className="mt-4">
                <CRow className="pb-2">
                  <CFormLabel htmlFor="inputEmail3" className="col-sm-3 col-form-label">
                    Project
                  </CFormLabel>
                  <CCol sm={9}>
                    {' '}
                    <AsyncReactSelect
                      value={{ value: values?.projectName, label: values?.projectName }}
                      isDisabled={selectedModel ? true : false}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          borderColor:
                            errors.projectName && touched.projectName
                              ? 'red'
                              : provided.borderColor,
                          '&:hover': {
                            borderColor:
                              errors.projectName && touched.projectName
                                ? 'red'
                                : provided.borderColor,
                          },
                        }),
                      }}
                      className={`form-control ${
                        errors.projectName && touched.projectName ? 'border-danger' : ''
                      }`}
                      dispatchFunction={!selectedModel && getProjects}
                      keyword="keyword"
                      name="projectName"
                      setFieldValue={setFieldValue}
                      setId={setProjectId}
                      loadOptionsFunction={loadOptionsFunctionProject}
                      handleChange={handleChange}
                    />
                    <div className="text-danger">
                      <ErrorMessage name="projectName" className="invalid-feedback " />
                    </div>
                  </CCol>
                </CRow>
                <CRow className="">
                  <CFormLabel htmlFor="colFormLabel" className="col-sm-3 col-form-label">
                    {t('MODEL.MODEL_NAME')}
                  </CFormLabel>
                  <CCol sm={9}>
                    <Field
                      type="text"
                      name="modelName"
                      className={`form-control ${
                        errors.modelName && touched.modelName ? 'is-invalid' : ''
                      }`}
                    />
                    <ErrorMessage name="modelName" component="div" className="invalid-feedback" />{' '}
                  </CCol>
                </CRow>
                <CRow>
                  <CFormLabel htmlFor="inputEmail3" className="col-sm-6 col-form-label pt-3">
                    {t('MODEL.MODEL_SETTINGS')}:
                  </CFormLabel>
                  <Editor
                    disabled
                    loading={isModalSettings}
                    name="settings"
                    height="40vh"
                    language="json"
                    theme="vs-dark"
                    value={formatJSON(values?.settings)}
                    onChange={(e) => setFieldValue('settings', e)}
                    options={{
                      inlineSuggest: true,
                      fontSize: '16px',
                      formatOnType: true,
                      autoClosingBrackets: true,
                    }}
                  />{' '}
                  {errors?.settings}
                </CRow>
                <CFormLabel></CFormLabel>
              </CModalBody>
              <CModalFooter>
                <CButton color="secondary" onClick={() => closeModal()}>
                  {t('CLOSE')}
                </CButton>
                <CButton style={{ backgroundColor: 'green' }} type="submit" color="primary">
                  {isUpdatingModel || isCreatingModel ? (
                    <CSpinner component="span" size="sm" aria-hidden="true" />
                  ) : (
                    ''
                  )}
                  &nbsp; {selectedModel ? t('SAVE') : t('CREATE')}
                </CButton>
              </CModalFooter>
            </Form>
          )}
        </Formik>
      </CModal>

      <CModal visible={showDeleteModal} onClose={() => closeModal()}>
        <CModalHeader closeButton>
          <CModalTitle>{t('MODEL.DESTROY_MODEL')}</CModalTitle>
        </CModalHeader>
        <CModalBody>{t('MODEL.ARE_YOU_SURE')}</CModalBody>
        <div className="d-flex pb-1 px-3">
          <CFormCheck
            checked={deleteToggle}
            onClick={() => setDeleteToggle(!deleteToggle)}
            id="checkboxNoLabel"
            value=""
            aria-label="..."
          />
          &nbsp; {t('MODEL.I_CONFIRM')}
        </div>
        <div className="px-3 text-secondary pt-3">{t('MODEL.NOTE')}</div>
        <CModalFooter>
          <CButton color="secondary" onClick={() => closeModal()}>
            {t('CLOSE')}
          </CButton>
          <CButton
            style={{
              border: '1px solid #ff0000',
              marginLeft: '10px',
              backgroundColor: 'white',
              color: 'red',
            }}
            onClick={() => onDeleteClick()}
            type="submit"
            color="primary"
            disabled={!deleteToggle}
          >
            {isDeleteingModelsById ? (
              <CSpinner component="span" size="sm" aria-hidden="true" />
            ) : (
              ''
            )}
            &nbsp; {t('DESTROY')}
          </CButton>
        </CModalFooter>
      </CModal>
    </div>
  )
}

export default Models
