import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchDashboardDetails, fetchSystemInfo } from '../services/dashboard'
import { showAlert } from './toastSlice'

const initialState = {
  isTotalCount: false,
  totalDevices: '',
  isSystemInfo: false,
  systemInfo: '',
}

export const dashboardSlice = createSlice({
  name: 'dashboardDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTotalDevices.pending, (state) => {
        state.isTotalCount = true
      })
      .addCase(getTotalDevices.fulfilled, (state, action) => {
        state.isTotalCount = false
        state.totalDevices = action.payload?.data[0]
      })
      .addCase(getTotalDevices.rejected, (state) => {
        state.isTotalCount = false
      })
      .addCase(getSystemInfo.pending, (state) => {
        state.isSystemInfo = true
      })
      .addCase(getSystemInfo.fulfilled, (state, action) => {
        state.isSystemInfo = false
        state.systemInfo = action.payload?.data[0]
      })
      .addCase(getSystemInfo.rejected, (state) => {
        state.isSystemInfo = false
      })
  },
})
export const getTotalDevices = createAsyncThunk(
  'dashboard/deviceCount',
  async (userData, thunkAPI) => {
    try {
      const response = await fetchDashboardDetails()
      return response
    } catch (err) {
      thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
      return thunkAPI.rejectWithValue(err)
    }
  },
)
export const getSystemInfo = createAsyncThunk(
  'dashboard/systemInfo',
  async (userData, thunkAPI) => {
    try {
      const response = await fetchSystemInfo()
      return response
    } catch (err) {
      thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
      return thunkAPI.rejectWithValue(err)
    }
  },
)

export default dashboardSlice.reducer
