import React from 'react'
import { cilExternalLink } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardText,
  CFormSwitch,
  CTooltip,
} from '@coreui/react-pro'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useSearchParams } from 'react-router-dom'
import io from 'socket.io-client'
import { WEBSOCKET_URL } from 'src/views/config/env'
import { getAccessToken } from 'src/views/helpers/localStorage'
import { generateCode } from 'src/views/helpers/utilityFunctions'
import { deleteMetaData } from 'src/views/slices/plugins'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import './index.css'

const TerminalPlugin = ({ props }) => {
  const dispatch = useDispatch()
  const [sniffToggle, setSniffToggle] = useState(false)
  const [timeStampToggle, setTimeStampToggle] = useState(false)
  const [originId, setOriginId] = useState('')
  const [command, setCommad] = useState()
  const [commandLine, setCommandLine] = useState([])
  const [lineNumber, setLineNumber] = useState(1)
  const [socket, setSocket] = useState()
  const { userDetails } = useSelector((state) => state.authentication)
  const { t } = useTranslation('common')
  const [searchParams] = useSearchParams()

  useEffect(() => {
    setOriginId({ id: generateCode() })
  }, [])
  const generateActiveId = () => {
    let tempLineNumber = ''
    let length = String(lineNumber).length
    for (let i = 1; i <= 8 - length; i++) {
      tempLineNumber += '0'
    }
    setLineNumber(lineNumber + 1)
    return `${originId.id}${generateCode()}${tempLineNumber}${lineNumber}`
  }
  const handleCommand = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.target.blur()

      if (command) {
        const newCommand = {
          ActionID: generateActiveId(),
          payload: { Command: command },
          Parameter: [
            {
              ISDN: 'reps',
            },
          ],
          serialNumber: props?.serialNumber,
          model: props?.model,
          project: props?.project,
        }
        setCommandLine([
          ...commandLine,
          { ...newCommand, type: 'SEND', TimeStamp: moment.utc().format() },
        ])

        socket?.emit('events', newCommand, (response) => {
          // const temp = { ...response }
          // delete temp['TimeStamp']
          // timeStampToggle
          //   ? setCommandLine((prev) => [...prev, response])
          //   : setCommandLine((prev) => [...prev, temp])
        })
        setCommad('')
      }
    }
  }
  const handleSniffmode = async () => {
    // dispatch(
    //   putMetaData({
    //     id: props?.i,
    //     data: {
    //       sniffMode: !props?.sniffMode,
    //       clientEmail: userDetails?.email,
    //       connectedDevice: props?.project + '/' + props?.model + '/' + props?.serialNumber,
    //     },
    //   }),
    // )
    const data = {
      sniffMode: !sniffToggle,
      clientEmail: userDetails?.email,
      connectedDevice: props?.project + '/' + props?.model + '/' + props?.serialNumber,
    }
    socket?.emit('sniff', data, (response) => {
      setSniffToggle(!sniffToggle)
    })
  }

  useEffect(() => {
    const connectSocket = async () => {
      const newSocket = io(WEBSOCKET_URL + '/devices', {
        extraHeaders: {
          Authorization: `Bearer ${getAccessToken()}`,
          // 'x-plugin-type': 'Map',
          'x-connected-device': props?.project + '/' + props?.model + '/' + props?.serialNumber,
        },
      })

      newSocket.on('connect', () => {
        setSocket(newSocket)
      })

      newSocket.on('events', (data) => {
        setCommandLine((prev) => [...prev, !prev.includes(data) && data])
      })

      try {
        socket.on('error', (err) => {
          if (err && err.message === 'unauthorized event') {
            socket.disconnect()
          }
        })
      } catch (err) {
        return err
      }

      newSocket.on('disconnect', () => {})

      return () => {
        newSocket.disconnect()
      }
    }
    connectSocket()
  }, [])
  const timeFormat = (value) => {
    // Guess the user's local time zone
    const moment = require('moment-timezone')
    const timeZone = moment.tz.guess()
    // Define the UTC date and time as a Moment object
    const utcDate = moment.utc(value)

    // Convert the UTC date to the user's local time zone
    const localDate = utcDate?.tz(timeZone)

    // Print the local date and time
    if (value)
      return (
        localDate?.format('ddd MMM D YYYY HH:mm:ss [GMT]Z') +
        ' ' +
        '(' +
        moment.tz(new Date(), timeZone).zoneName() +
        ')'
      )
  }
  return (
    <CCard style={{ minHeight: '300px', width: '100%' }}>
      <CCardHeader
        className="d-flex justify-content-between align-items-center  "
        style={{ cursor: 'move' }}
      >
        <h5 className="pt-2  hello flex-grow-1">Terminal plugin</h5>{' '}
        <div className="d-flex gap-3 align-items-center remove " style={{ cursor: 'pointer' }}>
          {searchParams.get('lister_origin_id') ? (
            ''
          ) : (
            <CTooltip content="Open in a new tab">
              <Link
                to={
                  '/plugin-details?lister_origin_id=' +
                  originId?.id +
                  '&model=' +
                  props?.model +
                  '&project=' +
                  props?.project +
                  '&serialNumber=' +
                  props?.serialNumber
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <CIcon icon={cilExternalLink} size="md" style={{ zIndex: '1000' }} />
              </Link>
            </CTooltip>
          )}
          <div onClick={() => dispatch(deleteMetaData(props.terminalId))} role="button">
            {t('TERMINAL_PLUGIN.REMOVE')}
          </div>
        </div>
      </CCardHeader>
      <CCardBody>
        <div className="d-flex ">
          <div className="d-flex " style={{ width: '', flexGrow: 1 }}>
            Listner orgin ID:{' '}
            <span style={{ width: '', flexGrow: 1 }}>
              {searchParams.get('lister_origin_id')
                ? searchParams.get('lister_origin_id')
                : originId?.id}
            </span>
          </div>
          <div
            className="d-flex justify-centent-between g align-item-center"
            style={{ width: '30rem' }}
          >
            <div className="d-flex gap-2" style={{ width: '45%' }}>
              Sniff mode
              <CFormSwitch
                checked={sniffToggle}
                label=""
                id="formSwitchCheckChecked"
                name="userSuspended"
                onClick={() => (setSniffToggle(!sniffToggle), handleSniffmode())}
              />
            </div>
            <div className="d-flex gap-2" style={{ width: '45%' }}>
              {t('TERMINAL_PLUGIN.TIME_STAMP')}{' '}
              <CFormSwitch
                checked={timeStampToggle}
                label=""
                id="formSwitchCheckChecked"
                name="userSuspended"
                onClick={() => setTimeStampToggle(!timeStampToggle)}
              />
            </div>
            <CButton onClick={() => setCommandLine([])} style={{ height: '40px', width: '50%' }}>
              {t('TERMINAL_PLUGIN.CLEAR_TERMINAL')}
            </CButton>
          </div>
        </div>
        <div className="d-flex gap-3">
          {' '}
          <div className="pb-3">
            Device id: {searchParams.get('serialNumber') ?? props?.serialNumber}
          </div>
          <div className="pb-3">
            {t('DEVICE.MODEL')}: {searchParams.get('model') ?? props?.model}
          </div>
          <div className="pb-3">
            {t('DEVICE.PROJECT')}: {searchParams.get('model') ?? props?.project}
          </div>
        </div>

        <CCardText
          className="d-flex flex-column gap-2 "
          style={{
            backgroundColor: 'lightGrey',
            border: '1px',
            borderRadius: '5px',
            height: '400px',
            overflowY: 'scroll',
          }}
        >
          {/* {` <SSE services started`} */}
          {commandLine.map((value, index) => (
            <div key={index} className="text-justify px-2">
              <div
                style={{ width: '100%' }}
                className={`text-justify w ${
                  value?.type === 'SEND'
                    ? 'text-danger'
                    : value?.Status === 'Event'
                    ? 'text-secondary'
                    : 'text-success'
                }  `}
              >
                {' '}
                {value?.type === 'SEND' ? (
                  <div
                    className=" "
                    style={{ width: '100%', height: '100%', whiteSpace: 'pre-wrap' }}
                  >
                    {/* &gt; {value?.payload?.Command} */}
                    {`>${value?.payload?.Command}`}

                    {value?.TimeStamp && timeStampToggle ? (
                      <div className="" style={{ color: 'gray', fontSize: '13px' }}>
                        {' '}
                        {timeFormat(value?.TimeStamp)}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  <div>
                    {value ? (
                      <div style={{ textAlign: 'justify' }}>
                        &lt;
                        {JSON.stringify(value).split(',').join(', ')}
                      </div>
                    ) : (
                      ''
                    )}
                    <div style={{ fontSize: '13px' }}>
                      {value?.TimeStamp && timeStampToggle ? (
                        <div className="" style={{ color: 'gray', marginLeft: '5px' }}>
                          {timeFormat(value?.TimeStamp)}
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </CCardText>
        {/* <CButton href="#">Go somewhere</CButton> */}
      </CCardBody>
      <CCardFooter style={{ backgroundColor: 'white', borderTop: 'none' }}>
        {/* <CFormTextarea
          onMouseEnter={(e) => handleCommand(e)}
          placeholder="Type a command..."
          id="floatingTextarea2"
          // floatingLabel="Type a command..."
          style={{ height: '100px' }}
        ></CFormTextarea> */}
        <CCard className="border border-1 " style={{ height: '100px' }}>
          <CCardBody>
            <input
              onChange={(e) => setCommad(e.target.value)}
              onKeyPress={(e) => handleCommand(e)}
              className="w-100"
              placeholder="Type a command..."
              style={{ border: 'none', outline: 'none' }}
              value={command}
            ></input>
          </CCardBody>
        </CCard>
      </CCardFooter>
    </CCard>
  )
}

export default TerminalPlugin
