import React from 'react'
import { Route, Routes } from 'react-router-dom'
import RouteDetails from '../constants/routes'
import Index from '../pages/indexpage'
import ProtectedRoute from './protectecRoute'
import PublicRoute from './publicRoute'

const RouteContainer = () => {
  return (
    <>
      <Routes>
        {RouteDetails.map((value, index) => (
          <Route key={`${index + value}`}>
            {value?.roles?.length ? (
              <Route
                key={index}
                path={value.path}
                element={
                  <ProtectedRoute>
                    <Index>{value.component}</Index>
                  </ProtectedRoute>
                }
              ></Route>
            ) : (
              <Route
                key={index}
                path={value.path}
                element={<PublicRoute>{value.component}</PublicRoute>}
              ></Route>
            )}
          </Route>
        ))}
      </Routes>
    </>
  )
}

export default RouteContainer
