import React from 'react'
import PropTypes from 'prop-types'
import { AppFooter, AppHeader, AppSidebar } from '../../../components/index'

const Index = ({ children }) => {
  return (
    <div>
      {' '}
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
        <AppHeader />
        <div className="body flex-grow-1 px-3">{children}</div>
        <AppFooter />
      </div>
    </div>
  )
}

export default Index
Index.propTypes = {
  children: PropTypes.any.isRequired,
}
