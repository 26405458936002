import React from 'react'
import TerminalPlugin from '../components/terminalPlugin/terminalPlugin'
import Dashboard from '../dashboard/Dashboard'
import Automation from '../pages/automation/automation'
import DeviceListing from '../pages/deviceListing'
import DeviceRegister from '../pages/deviceRegister/deviceRegister'
import Login from '../pages/login/Login'
import Models from '../pages/models/Models'
import NewAutomation from '../pages/newAutomation/newAutomation'
import NotVerified from '../pages/notVerified/notVerified'
import PluginCreateEdit from '../pages/pluginCreateEdit/pluginCreateEdit'
import Plugin from '../pages/plugins/plugins'
import Projects from '../pages/projects/Projects'
import ResetPassword from '../pages/resetPassword/resetPassword'
import Settings from '../pages/settings/settings'
import UserDetails from '../pages/userDetails/userDetails'
import Users from '../pages/users/users'
import VerificationLandingPage from '../pages/verificationLandingPage/verification'
import IndexRoute from '../routes/indexRoute'

const RouteDetails = [
  {
    path: '*',
    component: <IndexRoute />,
    isProtected: false,
  },
  {
    path: '/login',
    component: <Login />,
    isProtected: false,
  },
  {
    path: '/email-verification',
    component: <VerificationLandingPage />,
    isProtected: false,
  },
  {
    path: '/reset-password',
    component: <ResetPassword />,
    isProtected: false,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: <Dashboard />,
    isProtected: true,
    roles: ['USER', 'MANAGER', 'ADMIN'],
  },
  {
    path: '/projects',
    name: 'Projects',
    component: <Projects />,
    isProtected: true,
    roles: ['MANAGER', 'ADMIN'],
  },
  {
    path: '/models',
    name: 'Models',
    component: <Models />,
    isProtected: true,
    roles: ['MANAGER', 'ADMIN'],
  },
  {
    path: '/devices',
    name: 'Devices',
    isProtected: true,
    component: <DeviceListing />,
    roles: ['USER', 'MANAGER', 'ADMIN'],
  },
  {
    path: '/plugins',
    name: 'Plugins',
    isProtected: true,
    component: <Plugin />,
    roles: ['USER', 'MANAGER', 'ADMIN'],
  },
  {
    path: '/plugin-details',
    name: 'Plugins details',
    isProtected: true,
    component: <TerminalPlugin />,
    roles: ['USER', 'MANAGER', 'ADMIN'],
  },
  {
    path: '/plugins-edit',
    name: 'Plugins edit',
    isProtected: true,
    component: <PluginCreateEdit />,
    roles: ['USER', 'MANAGER', 'ADMIN'],
  },
  {
    path: '/plugins-create',
    name: 'Plugins create',
    isProtected: true,
    component: <PluginCreateEdit />,
    roles: ['USER', 'MANAGER', 'ADMIN'],
  },
  {
    path: '/automation',
    name: 'Automation',
    isProtected: true,
    component: <Automation />,
    roles: ['MANAGER', 'ADMIN', 'USER'],
  },
  {
    path: '/new-automation',
    name: 'New automation',
    isProtected: true,
    component: <NewAutomation />,
    roles: ['MANAGER', 'ADMIN', 'USER'],
  },
  {
    path: '/users',
    name: 'Users',
    isProtected: true,
    component: <Users />,
    roles: ['ADMIN', 'MANAGER'],
  },
  {
    path: '/settings',
    name: 'Settings',
    isProtected: true,
    component: <Settings />,
    roles: ['ADMIN', 'MANAGER', 'USER'],
  },
  {
    path: '/user-details',
    name: 'User details',
    isProtected: true,
    component: <UserDetails />,
    roles: ['ADMIN', 'MANAGER'],
  },
  {
    path: '/device-register',
    name: 'register',
    isProtected: true,
    component: <DeviceRegister />,
    roles: ['ADMIN', 'MANAGER', 'USER'],
  },
  {
    path: '/not-verified',
    component: <NotVerified />,
    isProtected: true,
  },
]
export default RouteDetails
