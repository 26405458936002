import { loginPost, putValues, UserDeleteById, userGet } from './api'
export const getAllPlugins = async (data) => {
  return await userGet('plugin')
}
export const getAllPluginMetaData = async (data) => {
  return await userGet('plugin/metaData')
}
export const postPluginMetaData = async (data) => {
  return await loginPost('plugin/metaData', data)
}
export const putPluginMetaData = async (data) => {
  return await putValues('plugin/' + data?.id, data?.data)
}
export const deletePluginMetaData = async (data) => {
  return await UserDeleteById('plugin/' + data)
}
