import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CImage, CSidebar, CSidebarBrand, CSidebarNav } from '@coreui/react-pro'

import { AppSidebarNav } from './AppSidebarNav'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

// sidebar nav config
import { setSideBarShow } from 'src/views/slices/sideBarSlice'
import navigation from '../_nav'

const AppSidebar = () => {
  const { REACT_APP_LOGO_URL } = process.env
  const dispatch = useDispatch()
  const [nav, setNav] = useState([])
  const { sidebarShow } = useSelector((state) => state.sideBar)
  const { userDetails } = useSelector((state) => state.authentication)
  useEffect(() => {
    setNav(navigation.filter((data) => data.roles.includes(userDetails?.level)))
  }, [userDetails.level])
  return (
    <CSidebar
      position="fixed"
      narrow={sidebarShow}
      // unfoldable={false}
      // visible={sidebarShow}
      // onVisibleChange={(visible) => {
      //   dispatch(setSideBarShow({ type: visible }))
      // }}
      onMouseEnter={() => {
        dispatch(setSideBarShow({ type: false }))
      }}
      onMouseLeave={() => {
        dispatch(setSideBarShow({ type: true }))
      }}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <CImage className="sidebar-brand-narrow" src={REACT_APP_LOGO_URL} height={50} />
        <CImage className="sidebar-brand-full " src={REACT_APP_LOGO_URL} height={50} width={50} />

        {/* <CIcon className="sidebar-brand-full" icon={logoNegative} height={35} />
        <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} /> */}
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={nav} />
        </SimpleBar>
      </CSidebarNav>
      {/* <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
      /> */}
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
