import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  showAlert: false,
  alertType: '',
  alertMessage: '',
}

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    showAlert: (state, action) => {
      return {
        showAlert: true,
        alertType: action.payload?.type,
        alertMessage: action.payload?.message,
      }
    },
    closeAlert: (state) => {
      return {
        showAlert: false,
      }
    },
  },
})

export const { showAlert, closeAlert } = toastSlice.actions

export default toastSlice.reducer
