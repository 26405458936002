import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { AsyncPaginate } from 'react-select-async-paginate'

const SelectAsyncPaginate = (props) => {
  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const response = await props.apiCall(page)
    return {
      options: response?.data,
      hasMore: response?.totalPages > page,
      additional: {
        page: page + 1,
      },
    }
  }

  const onChange = (option) => {
    if (typeof props.onChange === 'function') {
      props.onChange(option)
    }
  }

  return (
    <AsyncPaginate
      className={props.className}
      value={props.value || ''}
      loadOptions={loadOptions}
      getOptionValue={(option) => option.value}
      getOptionLabel={(option) => option.label}
      onChange={onChange}
      isSearchable={props.isSearchable}
      placeholder="Select House"
      additional={{
        page: 0,
      }}
    />
  )
}

export default SelectAsyncPaginate
