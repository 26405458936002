import { configureStore } from '@reduxjs/toolkit'
import authReducer from './views/slices/authenticationSlice'
import { projectReducer } from './views/slices/projectSlice'
import { modelReducer } from './views/slices/modelSlice'
import deviceReducer from './views/slices/device'
import toastReducer from './views/slices/toastSlice'
import userReducer from './views/slices/userSlice'
import registerDeviceReducer from './views/slices/deviceRegisterSlice'
import dashboardReducer from './views/slices/dashboard'
import pluginReducer from './views/slices/plugins'
import sideBarSlice from './views/slices/sideBarSlice'
import { automationReducer } from './views/slices/automation'
import languageReducer from './views/slices/languageSlice'

export const store = configureStore({
  reducer: {
    authentication: authReducer,
    project: projectReducer,
    model: modelReducer,
    device: deviceReducer,
    toast: toastReducer,
    users: userReducer,
    registerDevice: registerDeviceReducer,
    dashboard: dashboardReducer,
    plugins: pluginReducer,
    sideBar: sideBarSlice,
    automation: automationReducer,
    language: languageReducer,
  },
})
