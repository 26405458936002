import React, { useRef } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CFormLabel,
  CImage,
  CSpinner,
} from '@coreui/react-pro'
import { Editor } from '@monaco-editor/react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getFile } from 'src/views/services/api'
import { userRoleManagement } from 'src/views/slices/authenticationSlice'
import { updateUsers, uploadProfileImage } from 'src/views/slices/userSlice'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

const Settings = () => {
  const formRef = useRef()
  const [image, setImage] = useState()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { userDetails, isRoleManagement } = useSelector((state) => state.authentication)
  const { isFetchAllUsers, isProfilePicture } = useSelector((state) => state.users)
  const [loader, setLoader] = useState(false)
  const { t } = useTranslation('common')

  useEffect(() => {
    if (userDetails?.profileImageId)
      try {
        setLoader(true)
        const getImageData = async () => {
          await getFile(`users/${userDetails?.profileImageId}/download`).then((v) => {
            const blob = new Blob([v?.data])

            let objectURL = URL.createObjectURL(blob)
            setLoader(false)
            setImage(objectURL)
          })
        }
        getImageData()
      } catch (err) {
        setLoader(false)
        return null
      }
  }, [userDetails?.profileImageId])
  const handleProfilePicture = async (value) => {
    if (value.file) {
      const formData = new FormData()
      formData.append('file', value.file)
      formData.append('fileType', 'file')

      try {
        await dispatch(
          uploadProfileImage({
            file: value.file,
            type: 'file',
            id: userDetails?.id,
          }),
        )
        await dispatch(userRoleManagement())
      } catch (err) {
        return err
      }
    }
  }
  const handleSubmit = async (event) => {
    var data = event
    data['id'] = userDetails?.id
    const temp = JSON.parse(event?.platformSettings)
    delete data['platformSettings']
    Object.assign(data, temp)

    try {
      dispatch(updateUsers(data))
    } catch (err) {
      return null
    }
  }
  function formatJSON(val = {}) {
    try {
      const res = JSON.parse(val)
      return JSON.stringify(res, null, 2)
    } catch {}
  }
  return (
    <div>
      <Formik
        initialValues={{
          fullName: userDetails?.fullName,
          email: userDetails?.email,
          accountStatus: userDetails?.accountStatus,
          phoneNumber: userDetails?.phoneNumber,
          address: userDetails?.address,
          companyName: userDetails?.companyName,
          level: userDetails?.level,
          platformSettings: JSON.stringify(userDetails?.platformSettings),
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string(),
          lastName: Yup.string(),
          email: Yup.string(),
          accountStatus: Yup.string(),
          phoneNumber: Yup.string(),
          address: Yup.string(),
          companyName: Yup.string(),
          level: Yup.string(),
          // platformSettings: Yup.object(),
        })}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <Form>
            <CCard style={{ width: '60%' }}>
              {' '}
              <CCardHeader>{t('SETTINGS_PAGE.ACCOUNT_DETAILS')}</CCardHeader>
              <CCardBody>
                <Formik
                  innerRef={formRef}
                  initialValues={{
                    file: '',
                  }}
                  validationSchema={Yup.object().shape({
                    file: Yup.string(),
                  })}
                  onSubmit={handleProfilePicture}
                >
                  {({ errors, touched, values, setFieldValue }) => (
                    <Form>
                      <div className="clearfix py-3 px-3 d-flex">
                        <div className="position-relative">
                          <label htmlFor="file">
                            {!loader ? (
                              <CImage
                                rounded
                                thumbnail
                                src={image ? image : '/assets/profile-pic.svg'}
                                style={{ cursor: 'pointer', width: '200px', height: '200px' }}
                              />
                            ) : (
                              <CCard
                                className="d-flex justify-content-center align-items-center"
                                style={{
                                  cursor: 'pointer',
                                  width: '200px',
                                  height: '200px',
                                  border: '1px',
                                }}
                              >
                                <CSpinner color="info" />{' '}
                              </CCard>
                            )}
                          </label>
                          <input
                            type="file"
                            name="file"
                            id="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={(e) => {
                              setFieldValue('file', e.target.files[0])
                              const file = e.target.files[0]
                              const reader = new FileReader()

                              reader.onload = () => {
                                setImage(reader.result)
                              }

                              if (file) {
                                reader.readAsDataURL(file)
                              }
                            }}
                          />
                          <ErrorMessage
                            name="file"
                            component="div"
                            className={`invalid-feedback  `}
                          />
                        </div>

                        <div
                          className="d-flex flex-column justify-content-between"
                          style={{ marginLeft: '10px' }}
                        >
                          <CButton
                            onClick={() => formRef.current.handleSubmit()}
                            style={{ height: '30px' }}
                            className="d-flex gap-1 align-items-center bg-success mt-auto"
                          >
                            {isProfilePicture ? <CSpinner component="span" size="sm" /> : ''}
                            {t('SETTINGS_PAGE.UPLOAD_PICTURE')}
                          </CButton>
                        </div>
                      </div>
                      <div className="px-3 is-invalid text-danger">
                        {errors.profilePicture && touched.profilePicture && errors.profilePicture}
                      </div>
                    </Form>
                  )}
                </Formik>
                <div className="form-group row">
                  <div className="col-sm-2 col-form-label">
                    <CFormLabel>{t('SETTINGS_PAGE.FULL_NAME')}</CFormLabel>
                  </div>
                  <div className="col-sm-10">
                    {' '}
                    <Field
                      type={'text'}
                      placeholder={t('SETTINGS_PAGE.FULL_NAME')}
                      name="fullName"
                      className={`form-control col-sm-10 rounded-1  ${
                        errors.fullName && touched.fullName ? 'is-invalid' : ''
                      }`}
                    />
                  </div>
                </div>

                <ErrorMessage name="fullName" component="div" className="invalid-feedback mt-10" />

                <div className="form-group row">
                  <div className="col-sm-2 col-form-label">
                    <CFormLabel>{t('SETTINGS_PAGE.EMAIL')} </CFormLabel>
                  </div>
                  <div className="col-sm-10">
                    {' '}
                    <Field
                      disabled={true}
                      type={'text'}
                      placeholder={t('SETTINGS_PAGE.EMAIL')}
                      name="email"
                      className={`form-control col-sm-10 rounded-1  ${
                        errors.email && touched.email ? 'is-invalid' : ''
                      }`}
                    />
                  </div>
                </div>
                <ErrorMessage name="email" component="div" className="invalid-feedback" />
                <div className="form-group row">
                  <div className="col-sm-2 col-form-label">
                    <CFormLabel>{t('SETTINGS_PAGE.ACCTOUNT_STATUS')}</CFormLabel>
                  </div>
                  <div className="col-sm-10">
                    {' '}
                    <Field
                      disabled={true}
                      type={'text'}
                      placeholder={t('SETTINGS_PAGE.ACCTOUNT_STATUS')}
                      name="accountStatus"
                      className={`form-control col-sm-10 rounded-1  ${
                        errors.accountStatus && touched.accountStatus ? 'is-invalid' : ''
                      }`}
                    />
                  </div>
                </div>
                <ErrorMessage name="accountStatus" component="div" className="invalid-feedback" />
                <div className="form-group row">
                  <div className="col-sm-2 col-form-label">
                    <CFormLabel>{t('SETTINGS_PAGE.PHONE_NUMBER')}</CFormLabel>
                  </div>
                  <div className="col-sm-10">
                    {' '}
                    <Field
                      type={'text'}
                      placeholder={t('SETTINGS_PAGE.PHONE_NUMBER')}
                      name="phoneNumber"
                      className={`form-control col-sm-10 rounded-1  ${
                        errors.phoneNumber && touched.phoneNumber ? 'is-invalid' : ''
                      }`}
                    />
                  </div>
                </div>
                <ErrorMessage name="phoneNumber" component="div" className="invalid-feedback" />
                <div className="form-group row">
                  <div className="col-sm-2 col-form-label">
                    <CFormLabel>{t('SETTINGS_PAGE.ADDRESS')}</CFormLabel>
                  </div>
                  <div className="col-sm-10">
                    {' '}
                    <Field
                      type={'text'}
                      placeholder={t('SETTINGS_PAGE.ADDRESS')}
                      name="address"
                      className={`form-control col-sm-10 rounded-1  ${
                        errors.address && touched.address ? 'is-invalid' : ''
                      }`}
                    />
                  </div>
                </div>
                <ErrorMessage name="address" component="div" className="invalid-feedback" />
                <div className="form-group row">
                  <div className="col-sm-2 col-form-label">
                    <CFormLabel>{t('SETTINGS_PAGE.COMPANY')}</CFormLabel>
                  </div>
                  <div className={`col-sm-10  `}>
                    {' '}
                    <Field
                      type={'text'}
                      placeholder={t('SETTINGS_PAGE.COMPANY')}
                      name="companyName"
                      className={`form-control col-sm-10 rounded-1  ${
                        errors.companyName && touched.companyName ? 'is-invalid' : ''
                      }`}
                    />
                  </div>
                </div>
                <ErrorMessage name="companyName" component="div" className="invalid-feedback" />
                {userDetails?.level === 'ADMIN' ? (
                  <div className="form-group row">
                    <div className="col-sm-2 col-form-label">
                      <CFormLabel>{t('SETTINGS_PAGE.PRIVILAGE')}</CFormLabel>
                    </div>
                    <div className="col-sm-10">
                      {' '}
                      <Field
                        disabled={true}
                        type={'text'}
                        placeholder={t('SETTINGS_PAGE.PRIVILAGE')}
                        name="level"
                        className={`form-control col-sm-10 rounded-1  ${
                          errors.level && touched.level ? 'is-invalid' : ''
                        }`}
                      />
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <ErrorMessage name="level" component="div" className="invalid-feedback" />
              </CCardBody>
            </CCard>
            <CCard className={`mt-5 `} style={{ width: '60%' }}>
              <CCardHeader>{t('SETTINGS_PAGE.PLATFORM_SETTINGS')}</CCardHeader>
              <CCardBody>
                <Editor
                  disabled
                  loading={isRoleManagement}
                  name="platformSettings"
                  height="40vh"
                  language="json"
                  theme="vs-dark"
                  value={formatJSON(values?.platformSettings)}
                  onChange={(e) => setFieldValue('platformSettings', e)}
                  options={{
                    inlineSuggest: true,
                    fontSize: '16px',
                    formatOnType: true,
                    autoClosingBrackets: true,
                    readOnly: userDetails?.level !== 'ADMIN',
                  }}
                />{' '}
                {errors?.platformSettings}
              </CCardBody>
            </CCard>
            <CCard className="mt-5" style={{ width: '60%' }}>
              <CCardBody>
                {' '}
                <div className="d-flex gap-3  ">
                  <CButton
                    disabled={isFetchAllUsers}
                    type="submit"
                    className="d-flex gap-1 align-items-center bg-success"
                  >
                    {isFetchAllUsers ? <CSpinner component="span" size="sm" /> : ''}
                    {t('SETTINGS_PAGE.UPDATE')}
                  </CButton>
                  <div>
                    <CButton
                      onClick={() => navigate('/dashboard')}
                      color="info"
                      className="text-white col"
                    >
                      {t('CANCEL')}
                    </CButton>
                  </div>
                </div>
              </CCardBody>
            </CCard>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default Settings
