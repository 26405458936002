import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  fetchAllDeviceDetails,
  fetchCreateDevice,
  fetchDeleteDevice,
  fetchDeviceCount,
  fetchDeviceDetailsById,
  fetchModelUnderProject,
  fetchUpdateDeviceDetails,
  postFiledata,
} from '../services/deviceListing'
import { showAlert } from './toastSlice'

const initialState = {
  isDeviceListing: false,
  deviceDetails: '',
  isCreateDevice: false,
  isDeviceDetailById: false,
  deviceDetailsById: '',
  isUpdateDevice: false,
  isdeleteDevice: false,
  isDeviceCount: false,
  deviceCount: '',
  isUpload: false,
  isModelUnderProject: false,
  modelsUnderProject: [],
}

export const deviceSlice = createSlice({
  name: 'deviceDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllDeviceDetails.pending, (state) => {
        state.isDeviceListing = true
      })
      .addCase(getAllDeviceDetails.fulfilled, (state, action) => {
        state.isDeviceListing = false
        state.deviceDetails = action.payload?.data[0]
      })
      .addCase(getAllDeviceDetails.rejected, (state) => {
        state.isDeviceListing = false
      })
      .addCase(createDevice.pending, (state) => {
        state.isCreateDevice = true
      })
      .addCase(createDevice.fulfilled, (state, action) => {
        state.isCreateDevice = false
      })
      .addCase(createDevice.rejected, (state) => {
        state.isCreateDevice = false
      })
      .addCase(getDeviceDetailsById.pending, (state) => {
        state.isDeviceDetailById = true
      })
      .addCase(getDeviceDetailsById.fulfilled, (state, action) => {
        state.isDeviceDetailById = false
        state.deviceDetailsById = action.payload?.data[0]
      })
      .addCase(getDeviceDetailsById.rejected, (state) => {
        state.isDeviceDetailById = false
      })
      .addCase(updateDevice.pending, (state) => {
        state.isUpdateDevice = true
      })
      .addCase(updateDevice.fulfilled, (state, action) => {
        state.isUpdateDevice = false
      })
      .addCase(updateDevice.rejected, (state) => {
        state.isUpdateDevice = false
      })
      .addCase(deleteDevice.pending, (state) => {
        state.isdeleteDevice = true
      })
      .addCase(deleteDevice.fulfilled, (state, action) => {
        state.isdeleteDevice = false
      })
      .addCase(deleteDevice.rejected, (state) => {
        state.isdeleteDevice = false
      })
      .addCase(getDeviceCount.pending, (state) => {
        state.isDeviceCount = true
      })
      .addCase(getDeviceCount.fulfilled, (state, action) => {
        state.isDeviceCount = false
        state.deviceCount = action.payload?.data[0]
      })
      .addCase(getDeviceCount.rejected, (state) => {
        state.isDeviceCount = false
      })
      .addCase(uploadFile.pending, (state) => {
        state.isUpload = true
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        state.isUpload = false
      })
      .addCase(uploadFile.rejected, (state) => {
        state.isUpload = false
      })
      .addCase(getModelUnderProject.pending, (state) => {
        state.isModelUnderProject = true
      })
      .addCase(getModelUnderProject.fulfilled, (state, action) => {
        state.isModelUnderProject = false
        state.modelsUnderProject = action.payload?.data[0]
      })
      .addCase(getModelUnderProject.rejected, (state) => {
        state.isModelUnderProject = false
      })
  },
})
export const getDeviceDetailsById = createAsyncThunk(
  'device/detailsById',
  async (userData, thunkAPI) => {
    try {
      const response = await fetchDeviceDetailsById(userData)
      return response
    } catch (err) {
      thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
      return thunkAPI.rejectWithValue(err)
    }
  },
)
export const getAllDeviceDetails = createAsyncThunk(
  'device/listing',
  async (userData, thunkAPI) => {
    try {
      const response = await fetchAllDeviceDetails(userData)
      return response
    } catch (err) {
      thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
      return thunkAPI.rejectWithValue(err)
    }
  },
)
export const getDeviceCount = createAsyncThunk('device/count', async (userData, thunkAPI) => {
  try {
    const response = await fetchDeviceCount()
    return response
  } catch (err) {
    thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
    return thunkAPI.rejectWithValue(err)
  }
})
export const createDevice = createAsyncThunk('device/createDevice', async (userData, thunkAPI) => {
  try {
    const response = await fetchCreateDevice(userData)
    thunkAPI.dispatch(showAlert({ type: 'SUCCESS', message: response?.message }))
    return response
  } catch (err) {
    thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
    return thunkAPI.rejectWithValue(err)
  }
})
export const updateDevice = createAsyncThunk(
  'device/updateDeviceDetails',
  async (userData, thunkAPI) => {
    try {
      const response = await fetchUpdateDeviceDetails(userData)
      thunkAPI.dispatch(showAlert({ type: 'SUCCESS', message: response?.message }))
      return response
    } catch (err) {
      thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
      return thunkAPI.rejectWithValue(err)
    }
  },
)
export const deleteDevice = createAsyncThunk('device/delteDevice', async (userData, thunkAPI) => {
  try {
    const response = await fetchDeleteDevice(userData)
    thunkAPI.dispatch(showAlert({ type: 'SUCCESS', message: response?.message }))
    return response
  } catch (err) {
    thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
    return thunkAPI.rejectWithValue(err)
  }
})
export const uploadFile = createAsyncThunk('device/uploadDevice', async (userData, thunkAPI) => {
  try {
    const response = await postFiledata(userData)
    thunkAPI.dispatch(showAlert({ type: 'SUCCESS', message: response?.message }))
    return response
  } catch (err) {
    thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
    return thunkAPI.rejectWithValue(err)
  }
})
export const getModelUnderProject = createAsyncThunk(
  'model/underProjects',
  async (userData, thunkAPI) => {
    try {
      const response = await fetchModelUnderProject(userData)
      return response
    } catch (err) {
      thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
      return thunkAPI.rejectWithValue(err)
    }
  },
)

export default deviceSlice.reducer
