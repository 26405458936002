import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sidebarShow: true,
}

export const sideBarSlice = createSlice({
  name: 'sideBar',
  initialState,
  reducers: {
    setSideBarShow: (state, action) => {
      return {
        sidebarShow: action.payload?.type,
      }
    },
  },
})

export const { setSideBarShow } = sideBarSlice.actions

export default sideBarSlice.reducer
