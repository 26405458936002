import {
  CButton,
  CCard,
  CCardBody,
  CCardText,
  CCardTitle,
  CCol,
  CPlaceholder,
} from '@coreui/react-pro'
import React from 'react'

const PluginLoader = () => {
  return (
    <CCol>
      <CCard style={{ width: '18rem' }}>
        <div
          className=" rounded-top"
          style={{ width: '288px', height: '370px', backgroundColor: '#B1B1B1' }}
        ></div>
        <CCardBody>
          <CPlaceholder component={CCardTitle} animation="glow" xs={7}>
            <CPlaceholder xs={6} />
          </CPlaceholder>
          <CPlaceholder component={CCardText} animation="glow">
            <CPlaceholder xs={7} />
            <CPlaceholder xs={4} />
            <CPlaceholder xs={4} />
            <CPlaceholder xs={6} />
          </CPlaceholder>
          <CPlaceholder component={CButton} disabled href="#" tabIndex={-1} xs={6}></CPlaceholder>
        </CCardBody>
      </CCard>
    </CCol>
  )
}

export default PluginLoader
