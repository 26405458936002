import React from 'react'
import { CButton, CCard, CCardBody, CFormLabel, CSpinner } from '@coreui/react-pro'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  autoGenerate,
  clearAutoGenerate,
  registerDevice,
} from 'src/views/slices/deviceRegisterSlice'
import { showAlert } from 'src/views/slices/toastSlice'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

const DeviceRegister = () => {
  const [value, setValue] = useState({
    dId: '',
    friendlyName: '',
  })

  const { isDeviceRegister, isAutoGenerate, autoGenerateDetails } = useSelector(
    (state) => state.registerDevice,
  )
  const { t } = useTranslation('common')

  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffect(() => {
    if (
      autoGenerateDetails?.mqttUsername &&
      autoGenerateDetails?.mqttPassword &&
      autoGenerateDetails?.mqttClientId
    )
      dispatch(clearAutoGenerate())
  }, [])
  const handleAutoGenerate = async (dId, friendlyName, setErrors, setTouched) => {
    setValue({ dId, friendlyName })
    try {
      if (dId && friendlyName) {
        const response = await dispatch(autoGenerate({ dId, friendlyName })).unwrap()
        dispatch(showAlert({ type: 'SUCCESS', message: response?.payload?.data?.message }))
      } else {
        const error = { dId: true, friendlyName: true }
        const touched = { dId: true, friendlyName: true }
        if (!dId && !friendlyName) {
          setErrors(error)
          setTouched(touched)
        } else {
          if (!dId) {
            delete error['friendlyName']
            delete touched['friendlyName']
            setErrors(error)
            setTouched(touched)
          } else {
            delete error['dId']
            delete touched['dId']
            setErrors(error)
            setTouched(touched)
          }
        }
      }
    } catch (err) {
      return null
    }
  }
  const handleSubmit = async (event, resetForm) => {
    let data = event
    data['dId'] = value.dId
    data['friendlyName'] = value.friendlyName

    try {
      const response = await dispatch(registerDevice(data)).unwrap()
      resetForm()
      dispatch(clearAutoGenerate())
      dispatch(showAlert({ type: 'SUCCESS', message: response?.payload?.data?.message }))
      setValue({ dId: '', friendlyName: '' })
      await dispatch(clearAutoGenerate())
    } catch (err) {
      return null
    }
    resetForm()
  }
  return (
    <div
      style={{ justifyContent: 'left' }}
      className="bg-light d-flex flex-row align-items-center "
    >
      {/* <CRow className="w-full ">
        <CCol> */}
      <CCard className="mx-4" style={{ width: '60%' }}>
        <CCardBody className="p-4">
          <h1>{t('DEVICE.REGISTER_DEVICE')}</h1>
          <Formik
            enableReinitialize
            initialValues={{
              dId: value.dId,
              friendlyName: value.friendlyName,
              mqttUsername: autoGenerateDetails?.mqttUsername,
              mqttPassword: autoGenerateDetails?.mqttPassword,
              mqttClientId: autoGenerateDetails?.mqttClientId,
            }}
            validationSchema={Yup.object().shape({
              friendlyName: Yup.string().required(t('DEVICE.FRIENDLY_NAME_REQUIRED')),
              dId: Yup.string().required(t('DEVICE.DID_REQUIRED')),
              mqttUsername: Yup.string().required(t('DEVICE.MQTT_USERNAME_REQUIRED')),
              mqttPassword: Yup.string().required(t('DEVICE.MQTT_PASSWORD_REQUIRED')),
              mqttClientId: Yup.string().required(t('DEVICE.MQTT_ID_REQUIRED')),
            })}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values, resetForm)
            }}
          >
            {({ errors, touched, values, handleBlur, setErrors, setTouched }) => (
              <Form className="w-100">
                <div className="form-group row">
                  <div className="col-sm-3 col-form-label">
                    <CFormLabel>DID &nbsp;{t('DEVICE.DEVICE_IDENTIFICATION')}</CFormLabel>
                  </div>
                  <div className={`col-sm-9`}>
                    {' '}
                    <Field
                      onBlur={handleBlur}
                      type={'text'}
                      placeholder="Project/Model/4ACT3W4329775DCE..."
                      name="dId"
                      className={`form-control col-sm-10 rounded-1  ${
                        errors.dId && touched.dId ? 'is-invalid' : ''
                      }`}
                    />
                    <ErrorMessage
                      name="dId"
                      component="div"
                      className="invalid-feedback"
                      style={{ paddingBottom: '4px' }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-3 col-form-label">
                    <CFormLabel>{t('DEVICE.FRIENDLY_NAME_REGISTER')} </CFormLabel>
                  </div>
                  <div className="col-sm-9">
                    {' '}
                    <Field
                      onBlur={handleBlur}
                      type={'text'}
                      placeholder="My controller one.."
                      name="friendlyName"
                      className={`form-control col-sm-10 rrounded-1  ${
                        errors.friendlyName && touched.friendlyName ? 'is-invalid' : ''
                      }`}
                    />
                    <ErrorMessage
                      name="friendlyName"
                      component="div"
                      className="invalid-feedback pb-3"
                      style={{ paddingBottom: '4px' }}
                    />
                  </div>
                </div>

                <div className="d-flex flex-row bd-highlight  py-4  mb-4 gap-3 border-top ">
                  <div className="">
                    <CButton
                      disabled={isAutoGenerate}
                      color="success"
                      className="text-white col"
                      onClick={() =>
                        handleAutoGenerate(values.dId, values.friendlyName, setErrors, setTouched)
                      }
                    >
                      {isAutoGenerate ? (
                        <CSpinner component="span" size="sm" aria-hidden="true" />
                      ) : (
                        ''
                      )}
                      &nbsp;{t('DEVICE.AUTO_GENERATE')}
                    </CButton>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-3 col-form-label">
                    <CFormLabel>{t('DEVICE.MQTT_USERNAME')} </CFormLabel>
                  </div>
                  <div className="col-sm-9">
                    {' '}
                    <Field
                      onBlur={handleBlur}
                      type={'text'}
                      placeholder={t('DEVICE.UNIQUE_USESRNAME')}
                      name="mqttUsername"
                      className={`form-control col-sm-10 rounded-1  ${
                        errors.mqttUsername && touched.mqttUsername ? 'is-invalid' : ''
                      }`}
                      value={values.mqttUsername}
                    />
                    <ErrorMessage
                      name="mqttUsername"
                      component="div"
                      className="invalid-feedback "
                      style={{ paddingBottom: '4px' }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-3 col-form-label">
                    <CFormLabel>{t('DEVICE.MQTT_PASSWORD')}</CFormLabel>
                  </div>
                  <div className="col-sm-9">
                    {' '}
                    <Field
                      value={values.mqttPassword}
                      type={'text'}
                      placeholder={t('DEVICE.UNIQUE_PASSWORD')}
                      name="mqttPassword"
                      className={`form-control col-sm-10 rounded-1  ${
                        errors.mqttPassword && touched.mqttPassword ? 'is-invalid' : ''
                      }`}
                    />
                    <ErrorMessage
                      name="mqttPassword"
                      component="div"
                      className="invalid-feedback"
                      style={{ paddingBottom: '4px' }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-3 col-form-label">
                    <CFormLabel>{t('DEVICE.MQTT_CLIENT_ID')}</CFormLabel>
                  </div>
                  <div className="col-sm-9">
                    {' '}
                    <Field
                      value={values.mqttClientId}
                      type={'text'}
                      placeholder={t('DEVICE.UNIQUE_CID')}
                      name="mqttClientId"
                      className={`form-control col-sm-10 rounded-1  ${
                        errors.mqttClientId && touched.mqttClientId ? 'is-invalid' : ''
                      }`}
                    />
                    <ErrorMessage
                      name="mqttClientId"
                      component="div"
                      className="invalid-feedback"
                      style={{ paddingBottom: '4px' }}
                    />
                  </div>
                </div>

                <div className="d-flex flex-row bd-highlight  pt-4 gap-3">
                  <div className="">
                    <CButton
                      disabled={isDeviceRegister}
                      type="submit"
                      color="success"
                      className="text-white col"
                    >
                      {isDeviceRegister ? (
                        <CSpinner component="span" size="sm" aria-hidden="true" />
                      ) : (
                        ''
                      )}
                      &nbsp; {t('DEVICE.REGISTER_MY_DEVICE')}
                    </CButton>
                  </div>
                  <div>
                    <CButton
                      onClick={() => (navigate('/dashboard'), dispatch(clearAutoGenerate()))}
                      color="info"
                      className="text-white col"
                    >
                      {t('CLOSE')}
                    </CButton>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </CCardBody>
      </CCard>
      {/* </CCol>
      </CRow> */}
    </div>
  )
}

export default DeviceRegister
