import { debounce } from 'lodash'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import AsyncSelect from 'react-select/async'

const AsyncReactSelect = ({
  styles,
  dispatchFunction = '',
  keyword,
  name,
  handleChange,
  setFieldValue,
  loadOptionsFunction,
  loadMoreOptionsFunction,
  className,
  isDisabled = false,
  id,
  setId,
  key,
  value,
  field = '',
  defaultValue,
  dependentFielCLear = '',
  placeholderValue,
  ...rest
}) => {
  const [search, setSearch] = useState()
  const [pageNumber, setPageNumber] = useState()

  const dispatch = useDispatch()
  const loadOptions = async (inputValue, callback) => {
    setSearch(inputValue)
    try {
      debouncedLoadOptions(inputValue, callback)
    } catch (error) {
      return error
    }
  }

  const fetchData = async (inputValue, callback) => {
    // Simulate an API call with a delay  friendlyNameOrserialNumber
    var response = []
    if (!inputValue) {
      if (dispatchFunction) {
        response = id
          ? await dispatch(dispatchFunction({ id, data: { page: pageNumber, size: 10 } }))
          : await dispatch(dispatchFunction({ page: pageNumber, size: 10 }))
      }
    } else {
      response = id
        ? await dispatch(
            dispatchFunction({ id, data: { page: pageNumber, size: 10, keyword: search } }),
          )
        : await dispatch(dispatchFunction({ page: pageNumber, size: 10, keyword: search }))
    }
    var loadedOptions = []

    return new Promise((resolve) => {
      loadOptionsFunction(response, loadedOptions)
      resolve(loadedOptions)
    }).then((value) => callback(loadedOptions))
  }

  const debouncedLoadOptions = debounce(fetchData, 2000)

  const [additionalOptions, setAdditionalOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const loadMoreOptions = async () => {
    if (isLoading) {
      return
    }

    try {
      setPageNumber(pageNumber + 1)
      setIsLoading(true)
      const loadedOptions = []
      // Simulate loading more options from another API endpoint
      let response = []

      if (!search) {
        response = id
          ? await dispatch(dispatchFunction({ id, data: { page: pageNumber, size: 10 } }))
          : await dispatch(dispatchFunction({ page: pageNumber, size: 10 }))
      } else {
        response = id
          ? await dispatch(
              dispatchFunction({ id, data: { page: pageNumber, size: 10, keyword: search } }),
            )
          : await dispatch(dispatchFunction({ page: pageNumber, size: 10, keyword: search }))
      }
      // response?.payload?.data[0]?.data?.map((value, index) => {
      loadMoreOptionsFunction(response, loadedOptions)
      // Object.keys(value).map((item) => {
      //   if (item === 'deviceModel') {
      //     if (value?.deviceModel?.modelName)
      //       loadedOptions.push({
      //         value: value?.deviceModel?.modelName,
      //         label: value?.deviceModel?.modelName,
      //       })
      //   }
      // })
      // })

      setAdditionalOptions((prevOptions) => [...prevOptions, ...loadedOptions])
    } catch (error) {
      setPageNumber(pageNumber - 1)
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <AsyncSelect
      value={value}
      key={key}
      styles={styles}
      isDisabled={isDisabled}
      className
      name={name}
      isSearchable
      isClearable
      cacheOptions
      defaultOptions={true}
      loadOptions={debouncedLoadOptions}
      onInputChange={(inputValue, { action }) => {
        // Only trigger API call on pressing Enter or when the user clicks the "Search" button
        if (action === 'input-change' && inputValue.endsWith(' ')) {
          // You can adjust the condition based on how you want to trigger the API call
          loadOptions(inputValue.trim()) // Trim any leading/trailing spaces
        }
      }}
      onMenuScrollToBottom={loadMoreOptions}
      options={additionalOptions}
      placeholder={placeholderValue}
      onChange={(option) => {
        if (option?.value) {
          handleChange(name)(option?.value)
          field ? setFieldValue(name, option[field]) : setFieldValue(name, option?.value)
          setId && setId(option?.id)
          dependentFielCLear && setFieldValue(dependentFielCLear, null)
        }
      }}
    />
  )
}

export default AsyncReactSelect
