import React from 'react'
import { CFooter } from '@coreui/react-pro'

const AppFooter = () => {
  const version = process.env.REACT_APP_VERSION
  return (
    <CFooter>
      <div>
        CE3 Eelectronics
        <span className="ms-1  font">&copy; &nbsp;[Ver &nbsp; {version}]</span>
      </div>
      <div className="ms-auto"></div>
    </CFooter>
  )
}

export default React.memo(AppFooter)
