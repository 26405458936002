import React from 'react'
import { cilAccountLogout, cilSettings } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CDropdown,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react-pro'
import { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import { getFile } from 'src/views/services/api'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const AppHeaderDropdown = () => {
  const { userDetails } = useSelector((state) => state.authentication)
  const [image, setImage] = useState()
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  useEffect(() => {
    try {
      const getImageData = async () => {
        await getFile(`users/${userDetails?.profileImageId}/download`).then((v) => {
          const blob = new Blob([v?.data])

          let objectURL = URL.createObjectURL(blob)
          setImage(objectURL)
        })
      }
      if (userDetails?.profileImageId) getImageData()
    } catch (err) {
      return null
    }
  }, [])
  return (
    <CDropdown variant="nav-item" alignment="end">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <img
          src={image ? image : '/assets/avatar.svg'}
          alt=""
          className="w-10 h-10 "
          style={{
            width: '50px',
            height: '50px',
            borderRadius: '25px',
            borderWidth: '2px',
            borderColor: 'black',
          }}
        ></img>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0">
        <CDropdownHeader className="bg-light dark:bg-white dark:bg-opacity-10 fw-semibold py-2">
          {t('ACCOUNT')}
        </CDropdownHeader>
        {/* <CDropdownItem href="#">
          <CIcon icon={cilBell} className="me-2" />
          Updates
          <CBadge color="info-gradient" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilEnvelopeOpen} className="me-2" />
          Messages
          <CBadge color="success-gradient" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilTask} className="me-2" />
          Tasks
          <CBadge color="danger-gradient" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilCommentSquare} className="me-2" />
          Comments
          <CBadge color="warning-gradient" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownHeader className="bg-light dark:bg-white dark:bg-opacity-10 fw-semibold py-2">
          Settings
        </CDropdownHeader>
        <CDropdownItem href="#">
          <CIcon icon={cilUser} className="me-2" />
          Profile
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilSettings} className="me-2" />
          Settings
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilCreditCard} className="me-2" />
          Payments
          <CBadge color="secondary-gradient" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilFile} className="me-2" />
          Projects
          <CBadge color="primary-gradient" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownDivider />
        <CDropdownItem href="#">
          <CIcon icon={cilLockLocked} className="me-2" />
          Lock Account
        </CDropdownItem> */}
        <CDropdownItem onClick={() => navigate('/settings')} className="py-2">
          <CIcon icon={cilSettings} className="me-2 " />
          {t('SETTINGS')}
        </CDropdownItem>
        <CDropdownItem className="border-top" href="/login" onClick={() => localStorage.clear()}>
          <CIcon icon={cilAccountLogout} className="me-2" />
          {t('LOG_OUT')}
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdown
