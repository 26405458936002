import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilCalculator,
  cilClipboard,
  cilCopy,
  cilDevices,
  cilFork,
  cilPeople,
  cilPuzzle,
  cilSettings,
  cilSpeedometer,
} from '@coreui/icons'
import { CNavItem } from '@coreui/react-pro'

const _nav = [
  {
    component: CNavItem,
    name: 'DASHBOARD',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    roles: ['USER', 'MANAGER', 'ADMIN'],
  },

  {
    component: CNavItem,
    name: 'PROJECTS',
    to: '/projects',
    icon: <CIcon icon={cilCopy} customClassName="nav-icon" />,
    roles: ['MANAGER', 'ADMIN'],
  },
  {
    component: CNavItem,
    name: 'MODELS',
    to: '/models',
    icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
    roles: ['MANAGER', 'ADMIN'],
  },
  {
    component: CNavItem,
    name: 'DEVICES',
    to: '/devices',
    icon: <CIcon icon={cilDevices} customClassName="nav-icon" />,
    roles: ['MANAGER', 'ADMIN', 'USER'],
  },
  {
    component: CNavItem,
    name: 'PLUGINS',
    to: '/plugins',
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
    roles: ['MANAGER', 'ADMIN', 'USER'],
  },
  {
    component: CNavItem,
    name: 'AUTOMATION',
    to: '/automation',
    icon: <CIcon icon={cilFork} customClassName="nav-icon" />,
    roles: ['MANAGER', 'ADMIN', 'USER'],
  },
  {
    component: CNavItem,
    name: 'USERS',
    to: '/users',
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
    roles: ['MANAGER', 'ADMIN'],
  },
  {
    component: CNavItem,
    name: 'DEVICE_REGISTER',
    to: '/device-register',
    icon: <CIcon icon={cilClipboard} customClassName="nav-icon" />,
    roles: ['MANAGER', 'ADMIN', 'USER'],
  },

  {
    component: CNavItem,
    name: 'SETTINGS',
    to: '/settings',
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
    roles: ['MANAGER', 'ADMIN', 'USER'],
  },
]

export default _nav
