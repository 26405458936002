import React from 'react'
import { CSpinner } from '@coreui/react-pro'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { getAccessToken } from '../helpers/localStorage'
import { userRoleManagement } from '../slices/authenticationSlice'

const ProtectedRoute = ({ children }) => {
  const dispatch = useDispatch()
  const loginAuth = localStorage.getItem('token')
  const { isRoleManagement } = useSelector((state) => state.authentication)
  useEffect(() => {
    if (getAccessToken()) {
      dispatch(userRoleManagement())
    }
  }, [dispatch])
  return loginAuth ? (
    isRoleManagement ? (
      <div className="d-flex vh-100 wh-100 justify-content-center align-items-center ">
        {' '}
        <CSpinner className="text-primary" />
      </div>
    ) : (
      children
    )
  ) : (
    <Navigate to={'/login'} replace state={{ path: '/login' }} />
  )
}

export default ProtectedRoute
ProtectedRoute.propTypes = {
  children: PropTypes.any.isRequired,
}
