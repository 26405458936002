import React from 'react'
import { CButton, CCard, CCardBody, CFormLabel, CImage, CSpinner } from '@coreui/react-pro'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import * as Yup from 'yup'

const PluginCreateEdit = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const isFetchUserById = false
  const isFetchAllUsers = false
  const handleSubmit = () => {}

  return (
    <div>
      {' '}
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        {isFetchUserById ? (
          <div className="w-100vw h-100vh  ">
            <CSpinner component="span" size="xxl" aria-hidden="true" />
          </div>
        ) : (
          <CCard className="w-75">
            <CCardBody className="p-4  ">
              <h1 className="pb-4">
                {location.pathname === '/plugins-edit' ? 'Edit plugin' : 'Create plugin'}
              </h1>
              <Formik
                initialValues={{
                  fullName: '',
                  email: '',
                  accountStatus: '',
                  phoneNumber: '',
                  address: '',
                  company: '',
                  level: '',
                  userSuspended: '',
                }}
                validationSchema={Yup.object().shape({
                  firstName: Yup.string(),
                  lastName: Yup.string(),
                  email: Yup.string(),
                  accountStatus: Yup.string(),
                  phoneNumber: Yup.string(),
                  address: Yup.string(),
                  company: Yup.string(),
                  level: Yup.string(),
                  userSuspended: Yup.string(),
                })}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, values, setFieldValue }) => (
                  <Form>
                    <CImage
                      rounded
                      thumbnail
                      src="/assets/device.jpeg"
                      className="mb-4"
                      width={200}
                      height={200}
                    />
                    <div className="form-group row">
                      <div className="col-sm-2 col-form-label">
                        <CFormLabel>Plugin title</CFormLabel>
                      </div>
                      <div className="col-sm-10">
                        {' '}
                        <Field
                          type={'text'}
                          placeholder="Plugin title"
                          name="fullName"
                          className={`form-control col-sm-10 rounded-1  ${
                            errors.fullName && touched.fullName ? 'is-invalid' : ''
                          }`}
                        />
                      </div>
                    </div>
                    <ErrorMessage name="fullName" component="div" className="invalid-feedback" />
                    <div className="form-group row">
                      <div className="col-sm-2 col-form-label">
                        <CFormLabel>Description </CFormLabel>
                      </div>
                      <div className="col-sm-10">
                        {' '}
                        <Field
                          type={'text'}
                          placeholder="Description"
                          name="email"
                          className={`form-control col-sm-10 rounded-1  ${
                            errors.email && touched.email ? 'is-invalid' : ''
                          }`}
                        />
                      </div>
                    </div>
                    <ErrorMessage name="email" component="div" className="invalid-feedback" />

                    <div className="d-flex flex-row bd-highlight  pt-4 gap-3">
                      <div className="">
                        <CButton type="submit" color="success" className="text-white col">
                          {isFetchAllUsers ? (
                            <CSpinner component="span" size="sm" aria-hidden="true" />
                          ) : (
                            ''
                          )}
                          {location.pathname === '/plugins-edit' ? 'Edit' : 'Create'}
                        </CButton>
                      </div>
                      <div>
                        <CButton
                          onClick={() => navigate('/plugins')}
                          color="info"
                          className="text-white col"
                        >
                          Cancel
                        </CButton>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </CCardBody>
          </CCard>
        )}
      </div>
    </div>
  )
}

export default PluginCreateEdit
