import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchUserUpdate, getAllUsers, getUserById, postProfilePicture } from '../services/user'
import { userRoleManagement } from './authenticationSlice'
import { showAlert } from './toastSlice'

const initialState = {
  isFetchAllUsers: false,
  users: [],
  isFetchUserById: false,
  userById: '',
  isProfilePicture: false,
}

export const userSlice = createSlice({
  name: 'models',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUsers.pending, (state) => {
        state.isFetchAllUsers = true
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        state.isFetchAllUsers = false
        state.users = action.payload?.data[0]
      })
      .addCase(fetchAllUsers.rejected, (state) => {
        state.isFetchAllUsers = false
      })
      .addCase(updateUsers.pending, (state) => {
        state.isFetchAllUsers = true
      })
      .addCase(updateUsers.fulfilled, (state, action) => {
        state.isFetchAllUsers = false
      })
      .addCase(updateUsers.rejected, (state) => {
        state.isFetchAllUsers = false
      })
      .addCase(fetchUserById.pending, (state) => {
        state.isFetchUserById = true
      })
      .addCase(fetchUserById.fulfilled, (state, action) => {
        state.isFetchUserById = false
        state.userById = action.payload?.data[0]
      })
      .addCase(fetchUserById.rejected, (state) => {
        state.isFetchUserById = false
      })
      .addCase(uploadProfileImage.pending, (state) => {
        state.isProfilePicture = true
      })
      .addCase(uploadProfileImage.fulfilled, (state, action) => {
        state.isProfilePicture = false
      })
      .addCase(uploadProfileImage.rejected, (state) => {
        state.isProfilePicture = false
      })
  },
})

export const fetchAllUsers = createAsyncThunk('get/users', async (userData, thunkAPI) => {
  try {
    const response = await getAllUsers(userData)

    return response
  } catch (err) {
    thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.message }))
    return thunkAPI.rejectWithValue(err)
  }
})
export const fetchUserById = createAsyncThunk('getById/users', async (userData, thunkAPI) => {
  try {
    const response = await getUserById(userData)

    return response
  } catch (err) {
    thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.message }))
    return thunkAPI.rejectWithValue(err)
  }
})
export const updateUsers = createAsyncThunk('update/updateUser', async (userData, thunkAPI) => {
  try {
    const response = await fetchUserUpdate(userData)
    thunkAPI.dispatch(userRoleManagement())

    thunkAPI.dispatch(showAlert({ type: 'SUCCESS', message: response?.message }))
    return response
  } catch (err) {
    thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.message }))
    return thunkAPI.rejectWithValue(err)
  }
})
export const uploadProfileImage = createAsyncThunk(
  'update/profilePicture',
  async (userData, thunkAPI) => {
    try {
      const response = await postProfilePicture(userData)
      thunkAPI.dispatch(showAlert({ type: 'SUCCESS', message: response?.message }))

      return response
    } catch (err) {
      thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.message }))
      return thunkAPI.rejectWithValue(err)
    }
  },
)

export default userSlice.reducer
export const modelReducer = userSlice.reducer
