import React from 'react'
import { cilMinus, cilPlus } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDateRangePicker,
  CFormLabel,
  CFormSwitch,
  CFormTextarea,
  CInputGroup,
  CInputGroupText,
  CLoadingButton,
  CRow,
  CTimePicker,
} from '@coreui/react-pro'
import Editor from '@monaco-editor/react'
import { ErrorMessage, Field, FieldArray, Form, Formik, useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import {
  createAutomationData,
  getApiKey,
  getAutomationData,
  getAutomationTemplate,
  putApiKey,
  removeAutomationData,
  resetAutomationData,
  updateAutomationData,
} from 'src/views/slices/automation'
import { getAllDeviceDetails } from 'src/views/slices/device'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { AsyncPaginate } from 'react-select-async-paginate'
import SelectAsyncPaginate from 'src/views/components/AsyncSelectPagination/AsyncSelectPagination'

const NewAutomation = () => {
  const formik = useFormikContext()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [fieldIndex, setFieldIndex] = useState(0)
  const [, setCopyToggle] = useState(false)
  const [automationTemplate, setAutomationTemplate] = useState('')

  const { deviceDetails } = useSelector((state) => state.device)
  const { automationData, isCreateAutomationData, apiKey, automationTemplateData } = useSelector(
    (state) => state.automation,
  )
  const [weekdays, setWeekdays] = useState(
    automationData?.days ? automationData?.days : ['MON', 'TUE', 'WED', 'THU', 'FRI'],
  )
  const { t } = useTranslation('common')

  const [deviceOptions, setDeviceOptions] = useState([])

  useEffect(() => {
    if (!searchParams.get('id')) dispatch(resetAutomationData())
    const fetchData = async () => {
      try {
        let temp1
        if (searchParams.get('id')) {
          temp1 = await dispatch(getAutomationData(searchParams.get('id')))
        }
        await dispatch(getAllDeviceDetails())
        temp1?.payload?.data?.[0]?.apiKey
          ? dispatch(getApiKey(automationData?.apiKey))
          : dispatch(getApiKey())
        let temp = deviceDetails?.data?.map((data) => {
          return data?.friendlyName
        })
        temp = new Set(temp)
        temp = [...temp]
        temp = temp?.map((data) => ({ value: data, label: data }))
        setDeviceOptions(temp)
      } catch (error) {
        return null
      }
    }
    fetchData()
  }, [])
  const handleApiKey = () => {
    automationData?.apiKey ? dispatch(putApiKey(automationData?.apiKey)) : dispatch(getApiKey())
    setCopyToggle(false)
  }
  useEffect(() => {
    automationTemplate && dispatch(getAutomationTemplate(automationTemplate))
  }, [automationTemplate])
  const handleAutomation = async (setFieldValue, data) => {
    let response
    try {
      if (data) {
        response = await dispatch(getAutomationTemplate(data)).unwrap()
        setFieldValue('code', response?.data?.[0]?.code)
      }
    } catch (err) {}
  }
  const handleForm = async (e) => {
    try {
      searchParams.get('id')
        ? await dispatch(updateAutomationData({ id: searchParams.get('id'), data: e })).unwrap()
        : await dispatch(createAutomationData(e)).unwrap()
      navigate('/automation')
    } catch (err) {
      return null
    }
  }
  const handleDelete = async () => {
    try {
      await dispatch(removeAutomationData(searchParams.get('id'))).unwrap()
      navigate('/automation')
    } catch (err) {
      return null
    }
  }
  const handleWeekdays = (setField, value) => {
    const exemptionDay = ['SUN', 'SAT']

    if (!exemptionDay.includes(value)) {
      var temp
      if (weekdays?.includes(value)) {
        temp = weekdays.filter((data) => data !== value)

        setWeekdays(temp)
        setField('days', temp)
      } else {
        temp = weekdays.map((value) => value)
        temp.push(value)
        setField('days', temp)
        setWeekdays(temp)
      }
    }
  }
  const frequencyOptions = [
    { value: 'Always run', label: t('NEW_AUTOMATION.ALWAYS_RUN') },
    { value: 'Run between times', label: t('NEW_AUTOMATION.RUN_BETWEEN_TIMES') },
    { value: 'Run between date', label: t('NEW_AUTOMATION.RUN_BETWEEN_DATES') },
    { value: 'Run on weekdays', label: t('NEW_AUTOMATION.RUN_ON_WEEKDAYS') },
  ]

  const deviceApi = async (page) => {
    const response = await dispatch(
      getAllDeviceDetails({
        page: page + 1,
        size: 10,
      }),
    )
    return {
      data: response?.payload?.data[0]?.data?.map((value) => {
        return {
          value: `${value.project?.projectName}/${value.deviceModel?.modelName}/${value.serialNumber}`,
          label: `${value.project?.projectName}/${value.deviceModel?.modelName}/${value.serialNumber}`,
        }
      }),
      page: response?.payload?.data[0]?.page,
      size: response?.payload?.data[0]?.page,
      totalPages: response?.payload?.data[0]?.totalPages,
      totalElements: response?.payload?.data[0]?.totalElements,
    }
  }

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          name: automationData?.name,
          device: automationData?.device,
          conditions: automationData?.conditions?.length
            ? automationData?.conditions
            : [{ statement: 'IF', condition: 'EQUALS', keywords: '' }],
          frequency: automationData?.frequency ? automationData?.frequency : 'Always run',
          stop: automationData ? automationData?.stop : true,
          startingDate: automationData?.startingDate,
          endingDate: automationData?.endingDate,
          startingTime: automationData?.startingTime,
          endingTime: automationData?.endingTime,
          automationScript: automationData?.automationScript,
          code: automationData?.code,
          days: automationData?.days,
          apiKey: automationData?.apiKey,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Name is required.'),
          device: Yup.string().required('Device name  is required.'),
          conditions: Yup.array().of(
            Yup.object().shape({
              logic: Yup.string(),
              keywords: Yup.string().required('Keywords are required.'),
              statement: Yup.string(),
              condition: Yup.string(),
            }),
          ),
          frequency: Yup.string().required(t('NEW_AUTOMATION.FREQUENCY_REQUIRED')),
          stop: Yup.boolean().required(t('NEW_AUTOMATION.STOP_REQUIRED')),
          startingDate: Yup.string(),
          endingDate: Yup.string(),
          startingTime: Yup.string(),
          endingTime: Yup.string(),
          automationScript: Yup.string().required(t('NEW_AUTOMATION.AUTOMATION_REQUIRED')),
          code: Yup.string().required(t('NEW_AUTOMATION.CODE_REQUIRED')),
          days: Yup.array().of(Yup.string().trim()),
          apiKey: Yup.string(),
        })}
        onSubmit={(e) => handleForm(e)}
      >
        {({ errors, touched, values, setFieldValue }) => {
          return (
            <Form>
              <CCard className="w-50">
                <CCardHeader component="h5">{t('NEW_AUTOMATION.RULE')}</CCardHeader>
                <CCardBody>
                  <CInputGroup className="mb-3">
                    <CInputGroupText className="px-3" style={{ width: '90px' }} id="basic-addon1">
                      {t('NEW_AUTOMATION.NAME')}
                    </CInputGroupText>
                    <Field
                      type="text"
                      name="name"
                      value={values?.name}
                      className={`form-control ${
                        errors?.uuId && touched?.uuId ? 'is-invalid' : ''
                      }`}
                    />
                  </CInputGroup>
                  <div>
                    {' '}
                    <ErrorMessage name="name" component="div" className=" text-danger" />{' '}
                  </div>
                  <CInputGroup className="mb-3  rounded-end">
                    {' '}
                    {/* Add the rounded-end class */}
                    <CInputGroupText id="basic-addon1" style={{ width: '90px' }}>
                      {t('NEW_AUTOMATION.DEVICE')}
                    </CInputGroupText>
                    <SelectAsyncPaginate
                      isSearchable={true}
                      apiCall={deviceApi}
                      className="flex-grow-1 "
                      value={{
                        value: values?.device,
                        label: values?.device,
                      }}
                      onChange={(e) => {
                        setFieldValue(`device`, e?.value)
                      }}
                    />
                  </CInputGroup>

                  <div>
                    {' '}
                    <ErrorMessage name="device" component="div" className=" text-danger" />{' '}
                  </div>
                  <CRow className="pb-2">
                    <CCol> {t('NEW_AUTOMATION.CONDITION')}</CCol>
                    <CCol></CCol>
                    <CCol xs={6}>{t('NEW_AUTOMATION.KEYWORDS')}</CCol>
                  </CRow>
                  <FieldArray
                    name="conditions"
                    render={(arrayHelpers) => {
                      const condition = values.conditions
                      return (
                        <div className="d-flex gap-3 flex-column">
                          {condition && condition.length > 0
                            ? condition.map((value, index) => (
                                <div key={index}>
                                  {index ? (
                                    <CRow>
                                      {' '}
                                      <div style={{ width: 168 }}>
                                        <CCol>
                                          <Select
                                            key={values.conditions?.[index]?.logic}
                                            isDisabled={!(values.name && values.device)}
                                            defaultValue={{ value: 'AND', label: 'AND' }}
                                            // defaultInputValue="AND"
                                            onChange={(e) => {
                                              setFieldValue(`conditions.${index}.logic`, e?.value)
                                            }}
                                            className="pb-3"
                                            name={`conditions.${index}.logic`}
                                            options={[
                                              { value: 'AND', label: 'AND' },
                                              { value: 'OR', label: 'OR' },
                                            ]}
                                          />
                                        </CCol>
                                      </div>
                                    </CRow>
                                  ) : (
                                    ''
                                  )}
                                  <CRow>
                                    <CCol>
                                      <Select
                                        isDisabled={!(values?.name && values?.device)}
                                        // defaultInputValue={'IF'}
                                        defaultValue={
                                          { value: 'IF', label: 'IF' }
                                          //   {
                                          //   value: values?.conditions?.[index]?.statement,
                                          //   label: values?.conditions?.[index]?.statement,
                                          // }
                                        }
                                        name={`conditions.${index}.statement`}
                                        onChange={(e) => {
                                          setFieldValue(`conditions.${index}.statement`, e?.value)
                                        }}
                                        options={[
                                          { value: 'IF', label: 'IF' },
                                          { value: 'NOT', label: 'NOT' },
                                        ]}
                                      />
                                    </CCol>
                                    <CCol>
                                      <Select
                                        isDisabled={!(values?.name && values?.device)}
                                        // defaultInputValue={'EQUALS'}
                                        defaultValue={
                                          { value: 'EQUALS', label: 'Equal' }
                                          //   {
                                          //   value: values?.conditions?.[index]?.condition,
                                          //   label: values?.conditions?.[index]?.condition,
                                          // }
                                        }
                                        name={`conditions.${index}.condition`}
                                        onChange={(e) => {
                                          setFieldValue(`conditions.${index}.condition`, e?.value)
                                        }}
                                        options={[
                                          { value: 'EQUALS', label: 'Equal' },
                                          { value: 'CONTAINS', label: 'Contains' },
                                        ]}
                                      />
                                    </CCol>
                                    <CCol xs={5}>
                                      <CreatableSelect
                                        isDisabled={!(values?.name && values?.device)}
                                        defaultInputValue={values?.conditions[index]?.keywords}
                                        name={`conditions.${index}.keywords`}
                                        options={[
                                          { value: 'EQUAL', label: 'Equal' },
                                          { value: 'CONTAINS', label: 'Contains' },
                                        ]}
                                        onChange={(e) => {
                                          setFieldValue(`conditions.${index}.keywords`, e?.value)
                                        }}
                                        isClearable
                                        // options={}
                                      />
                                      <div>
                                        {' '}
                                        <ErrorMessage
                                          name={`conditions.${index}.keywords`}
                                          component="div"
                                          className=" text-danger"
                                        />
                                      </div>
                                    </CCol>
                                    <CCol xs={1}>
                                      {index ? (
                                        <>
                                          <CCard
                                            color="secondary"
                                            className="d-flex  pl-3"
                                            onClick={() => (
                                              arrayHelpers.remove(index),
                                              setFieldIndex(fieldIndex - 1)
                                            )}
                                            type="button"
                                            style={{
                                              width: 40,
                                              height: 40,
                                              paddingTop: 12,
                                              paddingLeft: 10,
                                              borderRadius: 20,
                                            }}
                                          >
                                            <CIcon icon={cilMinus} />
                                          </CCard>
                                        </>
                                      ) : (
                                        ''
                                      )}
                                    </CCol>
                                  </CRow>
                                </div>
                              ))
                            : null}
                          {fieldIndex < 4 ? (
                            <CRow className="px-3">
                              <CCard
                                color="secondary"
                                onClick={() => {
                                  if (values?.name && values?.device)
                                    return (
                                      arrayHelpers.push({
                                        statement: 'IF',
                                        condition: 'EQUALS',
                                        keywords: '',
                                        logic: 'AND',
                                      }),
                                      setFieldIndex(fieldIndex + 1)
                                    )
                                }}
                                type="button"
                                style={{ width: 40, height: 40, paddingTop: 12, borderRadius: 20 }}
                              >
                                <CIcon icon={cilPlus} />
                              </CCard>
                            </CRow>
                          ) : (
                            ''
                          )}
                        </div>
                      )
                    }}
                  />

                  <br />
                  <div className="py-2">{t('NEW_AUTOMATION.FREQUENCY')}</div>

                  <CRow>
                    <CCol xs={4}>
                      <Select
                        isDisabled={!(values?.name && values?.device)}
                        value={{ value: values?.frequency, label: values?.frequency }}
                        name="frequency"
                        onChange={(e) => {
                          setFieldValue('frequency', e.value)
                          if (e.value === 'Run on weekdays') {
                            setFieldValue('days', ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'])
                          }
                        }}
                        options={frequencyOptions}
                      />
                    </CCol>
                    <CCol>
                      {values?.frequency === 'Run between date' ? (
                        <CDateRangePicker
                          startDate={values?.startingDate}
                          endDate={values?.endingDate}
                          onStartDateChange={(date) => setFieldValue('startingDate', date)}
                          onEndDateChange={(date) => setFieldValue('endingDate', date)}
                          selectEndDate
                          size=""
                        />
                      ) : (
                        ''
                      )}
                      {values?.frequency === 'Run between times' ? (
                        <div className="d-flex gap-3   ">
                          {' '}
                          <CTimePicker
                            time={values?.startingTime}
                            onTimeChange={(date) => setFieldValue('startingTime', date)}
                            name="startingTime"
                            placeholder="intial time"
                            locale="en-US"
                          />{' '}
                          <CTimePicker
                            time={values?.endingTime}
                            onTimeChange={(date) => setFieldValue('endingTime', date)}
                            name="endingTime"
                            placeholder="final time"
                            locale="en-US"
                          />
                        </div>
                      ) : (
                        ''
                      )}
                      {values?.frequency === 'Run on weekdays' ? (
                        <div className={`d-flex gap-3   `}>
                          {
                            <CRow
                              name="days"
                              className="d-flex flex-grow-1 mx-1 border rounded align-items-center"
                            >
                              {['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'].map(
                                (value, index) => (
                                  <CCol
                                    className="d-flex justify-content-center align-items-center"
                                    key={index}
                                    onClick={() => {
                                      handleWeekdays(setFieldValue, value)
                                    }}
                                    style={{
                                      backgroundColor: weekdays?.includes(value) ? 'lightGrey' : '',
                                      height: '36px',
                                      paddingTop: 2,
                                      paddingBottom: 2,
                                      borderRight: index === 6 ? '0px' : '1px solid',
                                      borderColor: 'lightGrey',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {value}
                                  </CCol>
                                ),
                              )}
                            </CRow>
                          }
                        </div>
                      ) : (
                        ''
                      )}
                    </CCol>
                  </CRow>
                  <CRow className="d-flex justify-content-end  pt-3  ">
                    <div className="d-flex  " style={{ width: 'fit-content' }}>
                      <CFormSwitch
                        disabled={!(values?.name && values?.device)}
                        defaultChecked
                        checked={values?.stop}
                        label={t('NEW_AUTOMATION.STOP')}
                        id="formSwitchCheckChecked"
                        name="stop"
                        onClick={(e) => setFieldValue('stop', !values?.stop)}
                      />
                    </div>
                  </CRow>
                </CCardBody>
              </CCard>
              <CCard className=" w-50 mt-5  ">
                <CCardHeader className="" component="h6">
                  <span className="p-2"> API key </span>
                </CCardHeader>
                <CCardBody className="">
                  <CFormLabel htmlFor="exampleFormControlTextarea1"></CFormLabel>
                  <CFormTextarea
                    defaultValue={apiKey?.jwtApiKey}
                    name="apiKey"
                    disabled={true}
                    id="exampleFormControlTextarea1"
                    // label="Example textarea"
                    rows={3}
                    value={apiKey?.jwtApiKey}
                    // text="Must be 8-20 words long."
                  ></CFormTextarea>
                  <div
                    onClick={() => {
                      setFieldValue('apiKey', apiKey?.id)
                    }}
                    className="d-flex flex-grow-1 justify-content-end pt-3"
                  >
                    <CButton onClick={() => handleApiKey()}>{'Regenerate'}</CButton>
                  </div>
                </CCardBody>
              </CCard>
              <CCard color="info" className=" w-50 mt-5 ">
                <CCardHeader className="text-white" component="h6">
                  {t('NEW_AUTOMATION.AUTOMATION_TEMPLATES')}
                </CCardHeader>
                <Select
                  isDisabled={!(values?.name && values?.device)}
                  value={{
                    value: values?.automationScript,
                    label: values?.automationScript,
                  }}
                  className="p-3"
                  name={`automationScript`}
                  options={[
                    {
                      value: `Http_GET/POST`,

                      label: `Http GET/POST`,
                    },
                    { value: `Pushover`, label: `Pushover` },
                    { value: `IFTTT`, label: `IFTTT` },
                  ]}
                  onChange={(e) => {
                    setFieldValue('automationScript', e.value)
                    handleAutomation(setFieldValue, e.value)
                  }}
                />
                <div className="text-danger px-3 pb-2">
                  <ErrorMessage name="automationScript" className="invalid-feedback " />
                </div>
              </CCard>
              <CCard className=" w-50 mt-5 ">
                <CCardHeader component="h7">
                  {' '}
                  {t('NEW_AUTOMATION.ACTION')}
                  (Javascript)
                </CCardHeader>
                <CCardBody>
                  {' '}
                  <Editor
                    disabled={!(values?.name && values?.device)}
                    name="code"
                    height="300px"
                    language="javascript"
                    theme="vs-dark"
                    value={values?.code}
                    onChange={(e) => setFieldValue('code', e)}
                    options={{
                      inlineSuggest: true,
                      fontSize: '16px',
                      formatOnType: true,
                      autoClosingBrackets: true,
                    }}
                  />{' '}
                  <div className="text-danger">
                    <ErrorMessage name="code" className="invalid-feedback " />

                    {/* {errors?.code && touched?.code ? errors?.code : ''} */}
                  </div>
                </CCardBody>
              </CCard>
              <CCard className=" w-50 mt-5 d-flex  ">
                <CRow className="p-3 ">
                  <CCol className="d-flex gap-2">
                    {' '}
                    {searchParams.get('id') ? (
                      <CLoadingButton
                        onClick={handleDelete}
                        color="danger"
                        variant="outline"
                        timeout={2000}
                      >
                        {t('NEW_AUTOMATION.REMOVE_RULE')}
                      </CLoadingButton>
                    ) : (
                      ''
                    )}
                    <CLoadingButton
                      onClick={() => setFieldValue('apiKey', apiKey?.id)}
                      disabledOnLoading={true}
                      disabled={!(values?.name && values?.device)}
                      active={isCreateAutomationData}
                      type="submit"
                      color="success"
                      className="text-white"
                      // timeout={2000}
                    >
                      {searchParams.get('id')
                        ? t('NEW_AUTOMATION.UPDATE_RULE')
                        : t('NEW_AUTOMATION.CREATE_RULE')}
                    </CLoadingButton>
                    <CLoadingButton
                      onClick={() => navigate('/automation')}
                      color="info"
                      variant="outline"
                    >
                      {t('CANCEL')}
                    </CLoadingButton>
                  </CCol>
                </CRow>
              </CCard>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default NewAutomation
