import React from 'react'
import { cilLayers, cilSpeedometer, cilUser } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCardText,
  CCardTitle,
  CCol,
  CProgress,
  CProgressBar,
  CRow,
  CWidgetStatsF,
} from '@coreui/react-pro'
import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { getSystemInfo, getTotalDevices } from '../slices/dashboard'
import { getDeviceCount } from '../slices/device'
import WidgetsDropdown from '../widgets/WidgetsDropdown'
import './index.css'
import { useTranslation } from 'react-i18next'
import ReactGrid from '../components/reactGrid/reactGrid'

const Dashboard = () => {
  const dispatch = useDispatch()
  const { userDetails, isRoleManagement } = useSelector((state) => state.authentication)
  const { totalDevices, systemInfo } = useSelector((state) => state.dashboard)
  const { t } = useTranslation('common')
  useEffect(() => {
    dispatch(getDeviceCount())
    dispatch(getSystemInfo())
  }, [])
  useEffect(() => {
    !!userDetails && userDetails?.level !== 'USER' && dispatch(getTotalDevices())
  }, [userDetails])

  return (
    <>
      <CCard className="mb-3">
        <CCardHeader>{t('STATUS')}</CCardHeader>
      </CCard>
      <WidgetsDropdown />
      <CRow>
        {isRoleManagement ? (
          ''
        ) : userDetails?.level !== 'USER' ? (
          <>
            <CCol xs={4}>
              <CWidgetStatsF
                className="mb-3"
                color="primary"
                icon={<CIcon icon={cilUser} height={24} />}
                title={t('TOTAL_USERS')}
                value={totalDevices?.totalUsers}
              />
            </CCol>
            <CCol xs={4}>
              <CWidgetStatsF
                className="mb-3"
                color="warning"
                icon={<CIcon icon={cilUser} height={24} />}
                title={t('TOTAL_MANAGERS')}
                value={totalDevices?.totalManagers}
              />
            </CCol>
            <CCol xs={4}>
              {' '}
              <CCard>
                <CCardBody>
                  <CCardTitle style={{ color: 'info' }} className="mb-3">
                    {t('ACTIVE_USERS')}
                  </CCardTitle>
                  <CCardText className="mb-1">
                    <CProgress color="info" value={totalDevices?.activeUsersPercentage}>
                      <CProgressBar>{totalDevices?.activeUsersPercentage}%</CProgressBar>
                    </CProgress>{' '}
                  </CCardText>
                </CCardBody>
              </CCard>
            </CCol>
          </>
        ) : (
          ''
        )}
        <CCol xs={4}>
          <CWidgetStatsF
            className="mb-3"
            color="info"
            icon={<CIcon icon={cilLayers} height={24} />}
            title={t('DATA_BASE')}
            value={systemInfo?.databaseMemory?.fsUsedSize}
          />
        </CCol>
        <CCol xs={4}>
          <CWidgetStatsF
            className="mb-3"
            color="info"
            icon={<CIcon icon={cilSpeedometer} height={24} />}
            title={t('SERVER_MEMORY')}
            value={systemInfo?.memory?.memoryPercentage}
          />
        </CCol>
      </CRow>

      <div className="pt-5">
        <ReactGrid />
      </div>
    </>
  )
}

export default Dashboard
