import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  fetchApiKey,
  fetchAutomationData,
  fetchAutomationTemplate,
  fetchputApiKey,
  postAutomationData,
  postAutomationTemplate,
  putAutomationData,
  removeAutomationDatabyId,
} from '../services/automation'
import { showAlert } from './toastSlice'

const initialState = {
  isAutomation: false,
  automationData: '',
  isCreateAutomationData: false,
  apiKey: '',
  isGetApiKey: false,
  automationTemplateData: '',
  isAutomationTemplate: false,
}

export const automationSlice = createSlice({
  name: 'automation',
  initialState,
  reducers: {
    resetAutomationData: (state) => {
      state.automationData = ''
      state.apiKey = ''
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getAutomationData.pending, (state) => {
        state.isAutomation = true
      })
      .addCase(getAutomationData.fulfilled, (state, action) => {
        state.isAutomation = false
        state.automationData = action.payload?.data[0]
      })
      .addCase(getAutomationData.rejected, (state) => {
        state.isAutomation = false
      })
      .addCase(createAutomationData.pending, (state) => {
        state.isCreateAutomationData = true
      })
      .addCase(createAutomationData.fulfilled, (state, action) => {
        state.isCreateAutomationData = false
      })
      .addCase(createAutomationData.rejected, (state) => {
        state.isCreateAutomationData = false
      })
      .addCase(getApiKey.pending, (state) => {
        state.isGetApiKey = true
        state.apiKey = ''
      })
      .addCase(getApiKey.fulfilled, (state, action) => {
        state.isGetApiKey = false
        state.apiKey = action.payload?.data?.[0]
      })
      .addCase(getApiKey.rejected, (state) => {
        state.isGetApiKey = false
      })
      .addCase(putApiKey.pending, (state) => {
        state.isGetApiKey = true
        state.apiKey = ''
      })
      .addCase(putApiKey.fulfilled, (state, action) => {
        state.isGetApiKey = false
        state.apiKey = action.payload?.data?.[0]
      })
      .addCase(putApiKey.rejected, (state) => {
        state.isGetApiKey = false
      })
      .addCase(getAutomationTemplate.pending, (state) => {
        state.isAutomationTemplate = true
      })
      .addCase(getAutomationTemplate.fulfilled, (state, action) => {
        state.isAutomationTemplate = false
        state.automationTemplateData = action.payload?.data?.[0]
      })
      .addCase(getAutomationTemplate.rejected, (state) => {
        state.isAutomationTemplate = false
      })
  },
})
export const getAutomationTemplate = createAsyncThunk(
  'automationTemplate/get',
  async (userData, thunkAPI) => {
    try {
      const response = await postAutomationTemplate(userData)
      return response
    } catch (err) {
      thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
      return thunkAPI.rejectWithValue(err)
    }
  },
)
export const getApiKey = createAsyncThunk('apiKey/get', async (userData, thunkAPI) => {
  try {
    const response = await fetchApiKey(userData)
    return response
  } catch (err) {
    thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
    return thunkAPI.rejectWithValue(err)
  }
})
export const putApiKey = createAsyncThunk('apiKey/put', async (userData, thunkAPI) => {
  try {
    const response = await fetchputApiKey(userData)
    return response
  } catch (err) {
    thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
    return thunkAPI.rejectWithValue(err)
  }
})

export const getAutomationData = createAsyncThunk('automation/get', async (userData, thunkAPI) => {
  try {
    const response = await fetchAutomationData(userData)
    return response
  } catch (err) {
    thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
    return thunkAPI.rejectWithValue(err)
  }
})
export const createAutomationData = createAsyncThunk(
  'automation/post',
  async (userData, thunkAPI) => {
    try {
      const response = await postAutomationData(userData)
      thunkAPI.dispatch(showAlert({ type: 'SUCCESS', message: response?.message }))
      return response
    } catch (err) {
      thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
      return thunkAPI.rejectWithValue(err)
    }
  },
)
export const updateAutomationData = createAsyncThunk(
  'automation/update',
  async (userData, thunkAPI) => {
    try {
      const response = await putAutomationData(userData)
      thunkAPI.dispatch(showAlert({ type: 'SUCCESS', message: response?.message }))

      return response
    } catch (err) {
      thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
      return thunkAPI.rejectWithValue(err)
    }
  },
)
export const removeAutomationData = createAsyncThunk(
  'automation/remove',
  async (userData, thunkAPI) => {
    try {
      const response = await removeAutomationDatabyId(userData)
      thunkAPI.dispatch(showAlert({ type: 'SUCCESS', message: response?.message }))

      return response
    } catch (err) {
      thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
      return thunkAPI.rejectWithValue(err)
    }
  },
)

export const { resetAutomationData } = automationSlice.actions
export default automationSlice.reducer
export const automationReducer = automationSlice.reducer
