import { deleteById, get, post, put } from './api'

export const getProjectService = async (data) => {
  return await get('project', data)
}
export const getProjectByIdService = async (id) => {
  return await get('project/' + id)
}
export const postProjectService = async (data) => {
  return await post('project', data)
}
export const putProjectByIdService = async (data, id) => {
  return await put('project/' + id, data)
}
export const deleteProjectByIdService = async (id) => {
  return await deleteById('project/' + id)
}
