import { cilPlus } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CCard, CCardBody, CCardText, CCol } from '@coreui/react-pro'
import React, { useState, useEffect } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getMetaData, putMetaData } from 'src/views/slices/plugins'
import TerminalLoader from '../terminalLoader/terminalLoader'
import TerminalPlugin from '../terminalPlugin/terminalPlugin'
import '/node_modules/react-grid-layout/css/styles.css'
import '/node_modules/react-resizable/css/styles.css'

const ResponsiveReactGridLayout = WidthProvider(Responsive)

export default function ReactGrid() {
  const [compactType, setcompactType] = useState('vertical')
  const [mounted, setmounted] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [layout, setLayout] = useState([])
  const [count, setCount] = useState(1)

  const { pluginMetaData, isGetMetaData, isDeleteMetaData } = useSelector((state) => state.plugins)
  useEffect(() => {
    ;(async () => {
      try {
        let coordinate = { x: 0, y: 0 }
        const response = pluginMetaData
        if (response?.data?.length === 0) {
          coordinate.x = 0
          coordinate.y = 0
        }
        response?.data?.forEach((value, index) => {
          if (value.y > coordinate.y) {
            coordinate.x = value.x
            coordinate.y = value.y
          }
          if (value.y === coordinate.y) {
            if (value.x > coordinate.x) coordinate.x = value.x
          }
        })
        if (coordinate.x === 0) {
          coordinate.x = coordinate.x + 1
        } else {
          coordinate.y = coordinate.y + 1
          coordinate.x = 0
        }
        if (response?.data?.length) {
          setLayout([
            ...response?.data?.map((value, index) => ({
              i: value?.id,
              x: Number(value?.x),
              y: Number(value?.y),
              w: 1,
              h: 6.5,
              connectedDevice: value?.connectedDevice,
              terminalId: value?.id,
              sniffMode: value?.sniffMode,
              model: value?.model,
              project: value?.project,
              serialNumber: value?.serialNumber,
            })),
            { i: 'addPlugin', x: coordinate.x, y: coordinate.y, w: 1, h: 6.5 },
          ])
        } else {
          setLayout([{ i: 'addPlugin', x: 0, y: 0, w: 1, h: 6.5 }])
        }
      } catch (err) {
        return err
      }
    })()
  }, [pluginMetaData])

  useEffect(() => {
    dispatch(getMetaData())
    setmounted(true)
  }, [])
  const onLayoutChange = async (newLayout) => {
    let temp = []
    if (count > 1) {
      pluginMetaData?.data?.forEach((value, index) => {
        newLayout?.forEach((item) => {
          if (value?.id === item?.i && (value?.x !== item?.x || value?.y !== item?.y)) {
            temp.push({
              id: value?.id,
              x: item?.x,
              y: item?.y % 6.5 === 0 ? item?.y / 6.5 : item?.y,
            })
          }
        })
      })
      temp?.forEach((value) => {
        try {
          let temp = { ...value }
          delete temp['id']
          const upload = async () => {
            await dispatch(putMetaData({ id: value?.id, data: temp }))
          }
          upload()
        } catch (err) {
          return err
        }
      })
    }
    setCount(count + 1)
  }

  const onDrop = (elemParams) => {
    alert(`Element parameters:\n${JSON.stringify(elemParams, ['x', 'y', 'w', 'h'], 2)}`)
  }

  return (
    <div>
      {/* <div>Compaction type: {compactType || 'No Compaction'}</div>
      <button onClick={onCompactTypeChange}>Change Compaction Type</button>
      <div
        className="droppable-element"
        draggable={true} */}
      {/* // unselectable="on" // this is a hack for firefox // Firefox requires some kind of
      initialization // which we can do by adding this attribute // @see
      https://bugzilla.mozilla.org/show_bug.cgi?id=568313 */}
      {/* onDragStart={(e) => e.dataTransfer.setData('text/plain', '')}
      >
        Droppable Element (Drag me!)
      </div> */}
      {isGetMetaData || isDeleteMetaData ? <TerminalLoader /> : ''}
      <ResponsiveReactGridLayout
        rowHeight={100}
        cols={{ lg: 2, md: 10, sm: 6, xs: 4, xxs: 2 }}
        layout={layout}
        onLayoutChange={onLayoutChange}
        onDrop={onDrop}
        // WidthProvider option
        measureBeforeMount={false}
        // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
        // and set `measureBeforeMount={true}`.
        useCSSTransforms={mounted}
        compactType={compactType}
        preventCollision={!compactType}
        isDroppable={true}
        isResizable={false}
        droppingItem={{ i: 'xx', h: 200, w: 550 }}
        draggableHandle=".hello"
      >
        {layout.map((itm, i) =>
          i !== layout?.length - 1 ? (
            <div key={itm.i} data-grid={itm} className="block  ">
              <TerminalPlugin props={itm} />
            </div>
          ) : (
            <CCol key={itm.i} data-grid={itm} onClick={() => navigate('/plugins')}>
              <CCard style={{ minHeight: '300px', marginTop: '5px' }}>
                <CCardBody
                  className="d-flex justify-content-center align-items-center "
                  style={{ cursor: 'pointer' }}
                >
                  <CCardText className="d-flex gap-3 align-items-center">
                    <CIcon
                      icon={cilPlus}
                      className="text-primary border borderWidth-2  "
                      style={{ borderRadius: '25px' }}
                      size="xxl"
                    />
                    Add plugin
                  </CCardText>
                </CCardBody>
              </CCard>
            </CCol>
          ),
        )}
      </ResponsiveReactGridLayout>
    </div>
  )
}
