import React from 'react'
import { cilEnvelopeClosed, cilLockLocked, cilUser, cilWarning } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CInputGroup,
  CInputGroupText,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSpinner,
} from '@coreui/react-pro'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { values } from 'lodash'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import {
  checkEmail,
  createUser,
  forgotPassword,
  logInUser,
  setUserEmail,
  userRoleManagement,
} from 'src/views/slices/authenticationSlice'
import { showAlert } from 'src/views/slices/toastSlice'
import * as Yup from 'yup'
import './index.css'

const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [toggle, setToggle] = useState('EMAIL')
  const [visible, setVisible] = useState(false)
  const { isAuthentication, isEmailValidating } = useSelector((store) => store.authentication)

  const handleSubmit = async (data) => {
    setEmail(data.email)
    try {
      await dispatch(checkEmail(data)).unwrap()
      setToggle('PASSWORD')
    } catch (err) {
      setToggle('CREATE_NEW_ACCOUNT')
      return err
    }
  }

  const handleLogin = async (item) => {
    try {
      await dispatch(logInUser({ email: email, password: item.password })).unwrap()
      const value = await dispatch(userRoleManagement()).unwrap()
      if (value?.data[0]?.emailVerified) {
        return navigate('/dashboard')
      } else if (!value?.data[0]?.emailVerified) {
        await localStorage.clear()
        navigate('/not-verified')
      }
    } catch (err) {
      return err
    }
  }
  const handleRegister = async (data) => {
    try {
      const response = await dispatch(
        createUser({
          email: email,
          password: data?.password,
          fullName: data?.fullName,
        }),
      ).unwrap()
      setVisible(true)
      dispatch(showAlert({ type: 'SUCCESS', message: response?.message }))
      setToggle('EMAIL')
    } catch (err) {
      setToggle('EMAIL')
      navigate('/login')
      return err
    }
  }
  const handleForgotPassword = async (data) => {
    try {
      const response = await dispatch(forgotPassword(data)).unwrap()
      dispatch(showAlert({ type: 'SUCCESS', message: response?.message }))
      dispatch(setUserEmail({ email: email }))
    } catch (err) {
      return err
    }
  }
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4 loginCard">
                <CCardBody className="d-flex justify-content-between flex-column">
                  {isEmailValidating ? (
                    <div className="h-100 d-flex justify-content-center align-items-center">
                      <CSpinner variant="grow" />
                    </div>
                  ) : toggle === 'EMAIL' ? (
                    <EmailVerification email={email} handleSubmit={handleSubmit} />
                  ) : toggle === 'PASSWORD' ? (
                    <PasswordVerification
                      handleLogin={handleLogin}
                      setToggle={setToggle}
                      isAuthentication={isAuthentication}
                    />
                  ) : toggle === 'FORGOTPASSWORD' ? (
                    <ForgotPassword
                      handleForgotPassword={handleForgotPassword}
                      setToggle={setToggle}
                      isAuthentication={isAuthentication}
                    />
                  ) : (
                    <CreateAccount
                      handleRegister={handleRegister}
                      setToggle={setToggle}
                      isAuthentication={isAuthentication}
                    />
                  )}
                </CCardBody>
              </CCard>
              <CCard className="text-white bg-primary py-5">
                <CCardBody className="text-center">
                  <div>
                    <h2>Order online</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                      tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                    <Link to="/register">
                      <CButton color="primary" className="mt-3" active tabIndex={-1}>
                        Go to
                      </CButton>
                    </Link>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
        <CModal
          alignment="center"
          visible={visible}
          onClose={() => setVisible(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample">
              <CIcon style={{ color: 'yellowgreen' }} icon={cilWarning} size="xl" />
              &nbsp; Please verify
            </CModalTitle>
          </CModalHeader>
          <CModalBody>
            <div>Verification email send to {email}</div>
            <div>
              Please click on the link provided in the verification mail to gain access to your
              account
            </div>
          </CModalBody>
          <CModalFooter>
            <CButton color="secondary" onClick={() => setVisible(false)}>
              Close
            </CButton>
          </CModalFooter>
        </CModal>
      </CContainer>
    </div>
  )
}
const EmailVerification = ({ email, handleSubmit }) => {
  return (
    <Formik
      initialValues={{ email: email }}
      validationSchema={Yup.object().shape({
        email: Yup.string().required('Email is required.'),
      })}
      onSubmit={handleSubmit}
    >
      {({ errors, touched }) => (
        <Form>
          <h1 className="justify-content-center d-flex mb-4">Login</h1>
          <div className="d-flex flex-row align-items-center  ">
            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                <CIcon icon={cilEnvelopeClosed} size="lg" />
              </CInputGroupText>
              <Field
                type="text"
                placeholder="Email"
                name="email"
                className={`form-control rounded-0  ${
                  errors.email && touched.email ? 'is-invalid' : ''
                }`}
              />
            </CInputGroup>
          </div>
          <div>
            {' '}
            <ErrorMessage name="email" component={'div'} className=" text-danger" />
          </div>

          <div className="justify-content-center d-flex  w-100 py-4">
            <CButton type="submit" color="primary">
              Continue
            </CButton>
          </div>
        </Form>
      )}
    </Formik>
  )
}
const PasswordVerification = ({ handleLogin, setToggle, isAuthentication }) => {
  const [showHidePassword, changeShowHidePassword] = useState(false)

  return (
    <Formik
      initialValues={{ password: '' }}
      validationSchema={Yup.object().shape({
        password: Yup.string().required('Password is required.'),
      })}
      onSubmit={handleLogin}
      validateOnBlur={true}
    >
      {({ errors, touched, handleBlur, values }) => (
        <Form>
          <h1 className="justify-content-center d-flex mb-4">Password</h1>
          <div className="d-flex flex-row align-items-center  ">
            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                <CIcon icon={cilLockLocked} size="lg" />
              </CInputGroupText>
              <Field
                onBlur={handleBlur}
                type={showHidePassword ? 'text' : 'password'}
                placeholder="Password"
                name="password"
                // value={values.password}
                className={`form-control rounded-0  ${
                  errors.password && touched.password ? 'is-invalid' : ''
                }`}
              />
              <CInputGroupText
                onClick={() => changeShowHidePassword(!showHidePassword)}
                id="basic-addon2"
              >
                <img
                  src={showHidePassword ? `/assets/visibilityOff.svg` : `/assets/visibilityOn.svg`}
                  alt=""
                ></img>
              </CInputGroupText>
            </CInputGroup>
          </div>
          <div>
            {' '}
            <ErrorMessage name="password" component={'div'} className=" text-danger" />
          </div>

          <div className={`justify-content-center d-flex  w-100 pt-4 `}>
            <div className="w-100 d-flex justify-content-between align-items-end color-primary">
              <CButton type="submit" color="primary" className="d-flex gap-1 align-items-center">
                {isAuthentication ? <CSpinner component="span" size="sm" aria-hidden="true" /> : ''}
                Login
              </CButton>

              <div
                onClick={() => setToggle('FORGOTPASSWORD')}
                className="underline"
                role={'button'}
              >
                forgot password?
              </div>
            </div>
          </div>
          <div className="w-100 d-flex justify-content-end">
            {' '}
            <div onClick={() => setToggle('EMAIL')} className=" pe-auto" role={'button'}>
              Go back
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}
const ForgotPassword = ({ handleForgotPassword, setToggle, isAuthentication }) => {
  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={Yup.object().shape({
        email: Yup.string().required('Email is required.'),
      })}
      onSubmit={handleForgotPassword}
      validateOnBlur={true}
    >
      {({ errors, touched, handleBlur, values }) => (
        <Form>
          <h1 className="justify-content-center d-flex mb-4">Forgot password</h1>
          <div className="d-flex flex-row align-items-center  ">
            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                <CIcon icon={cilLockLocked} size="lg" />
              </CInputGroupText>
              <Field
                onBlur={handleBlur}
                type={'text'}
                placeholder="Email"
                name="email"
                // value={values.password}
                className={`form-control rounded-0  ${
                  errors.email && touched.email ? 'is-invalid' : ''
                }`}
              />
            </CInputGroup>
          </div>
          <div>
            {' '}
            <ErrorMessage name="email" component={'div'} className=" text-danger" />
          </div>

          <div className={`justify-content-center d-flex  w-100 pt-4 `}>
            <div className="w-100 d-flex justify-content-between align-items-end color-primary">
              <CButton type="submit" color="primary" className="d-flex gap-1 align-items-center">
                {isAuthentication ? <CSpinner component="span" size="sm" aria-hidden="true" /> : ''}
                continue
              </CButton>

              <div onClick={() => setToggle('PASSWORD')} className="underline" role={'button'}>
                Go back
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}
const CreateAccount = ({ handleRegister, setToggle, isAuthentication }) => {
  const [showHidePassword, changeShowHidePassword] = useState(false)
  const [showHideConfirmPassword, changeShowHideConfirmPassword] = useState(false)

  return (
    <Formik
      initialValues={{
        password: '',
        confirmPassword: '',
        fullName: '',
      }}
      validationSchema={Yup.object().shape({
        fullName: Yup.string().required('Full name is required.'),
        password: Yup.string()
          .required('Password required')
          .matches(/^.*[0-9].*/, 'Atleast one number')
          .matches(/[-!$%^&*()_+|~=`{}[:;<>?,.@#\]]/g, 'Atleast one symbol')
          .matches(/.*[A-Z].*/, 'Atleast one uppercase character')
          .matches(/.*[a-z].*/, 'Atleast one lower case character')
          .min(6, 'Minimum 6 characters')
          .max(8, 'Maximum 8 characters')
          .matches(/^\S*$/, 'Should bot contain space'),
        confirmPassword: Yup.string()
          .required('Confirm password is required.')
          .oneOf([Yup.ref('password'), undefined], 'Passwords must match'),
      })}
      onSubmit={handleRegister}
    >
      {({ errors, touched, handleBlur }) => (
        <Form>
          <h1 className="justify-content-center d-flex mb-4">Create account</h1>
          <div className="d-flex flex-row align-items-center  ">
            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                <CIcon icon={cilUser} size="lg" />
              </CInputGroupText>
              <Field
                type="text"
                placeholder="Full name"
                name="fullName"
                value={values.fullName}
                className={`form-control rounded-0  ${
                  errors.fullName && touched.fullName ? 'is-invalid' : ''
                }`}
              />
            </CInputGroup>
          </div>
          <div>
            {' '}
            <ErrorMessage name="fullName" component={'div'} className=" text-danger" />
          </div>

          <div className="d-flex flex-row align-items-center  ">
            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                {' '}
                <CIcon icon={cilLockLocked} size="lg" />
              </CInputGroupText>
              <Field
                onBlur={handleBlur}
                type={showHidePassword ? 'text' : 'password'}
                placeholder="Password"
                name="password"
                className={`form-control rounded-0  ${
                  errors.password && touched.password ? 'is-invalid' : ''
                }`}
              />
              <CInputGroupText
                onClick={() => changeShowHidePassword(!showHidePassword)}
                id="basic-addon2"
              >
                <img
                  src={showHidePassword ? `/assets/visibilityOff.svg` : `/assets/visibilityOn.svg`}
                  alt=""
                ></img>
              </CInputGroupText>
            </CInputGroup>
          </div>
          <div>
            <ErrorMessage
              type="password"
              name="password"
              component={'div'}
              className=" text-danger"
            />
          </div>

          <div className="d-flex flex-row align-items-center mt-2  ">
            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                {' '}
                <CIcon icon={cilLockLocked} size="lg" />
              </CInputGroupText>
              <Field
                onBlur={handleBlur}
                type={showHideConfirmPassword ? 'text' : 'password'}
                placeholder="Confirm password"
                name="confirmPassword"
                className={`form-control rounded-0  ${
                  errors.confirmPassword && touched.confirmPassword ? 'is-invalid' : ''
                }`}
              />
              <CInputGroupText
                onClick={() => changeShowHideConfirmPassword(!showHideConfirmPassword)}
                id="basic-addon2"
              >
                <img
                  src={
                    showHideConfirmPassword
                      ? `/assets/visibilityOff.svg`
                      : `/assets/visibilityOn.svg`
                  }
                  alt=""
                ></img>
              </CInputGroupText>
            </CInputGroup>
          </div>
          <div>
            {' '}
            <ErrorMessage name="confirmPassword" component={'div'} className=" text-danger" />
          </div>
          <div className={`justify-content-center d-flex  w-100 pt-4 `}>
            <div className="w-100 d-flex justify-content-between align-items-end color-primary">
              <CButton type="submit" color="primary" className="d-flex gap-1 align-items-center">
                {isAuthentication ? <CSpinner component="span" size="sm" aria-hidden="true" /> : ''}
                Create
              </CButton>

              <div role={'button'} onClick={() => setToggle('EMAIL')} className="underline">
                Go back
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}
export default Login
