import React from 'react'
import { CButton, CCard, CCardBody, CFormLabel, CFormSwitch, CSpinner } from '@coreui/react-pro'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { showAlert } from 'src/views/slices/toastSlice'
import { fetchUserById, updateUsers } from 'src/views/slices/userSlice'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

const UserDetails = () => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { userDetails } = useSelector((state) => state.authentication)
  const { userById, isFetchUserById, isFetchAllUsers } = useSelector((state) => state.users)
  const [searchParams] = useSearchParams()
  const [userSuspendToggle, setUserSuspendToggle] = useState(userById?.userSuspended)
  useEffect(() => {
    if (searchParams.get('id')) {
      dispatch(fetchUserById(searchParams.get('id')))
    }
  }, [])
  const handleSubmit = async (event) => {
    const data = event

    data['id'] = searchParams.get('id')
    Object.keys(data).forEach((value) => {
      if (!data[value]) delete data[value]
    })
    try {
      const response = await dispatch(updateUsers(data)).unwrap()

      dispatch(showAlert({ type: 'SUCCESS', message: response?.payload?.message }))
    } catch (err) {
      return null
    }
  }

  return (
    // <div className="">
    <>
      {' '}
      {isFetchUserById ? (
        <div className="w-100vw h-100vh  d-flex justify-content-center align-items-center  ">
          <CSpinner component="span" size="xxl" aria-hidden="true" />
        </div>
      ) : (
        <CCard className="mx-4" style={{ width: '60%' }}>
          <CCardBody className="p-4  ">
            <h1 className="pb-4">{t('USER.UPDATE_USER')}</h1>
            <Formik
              initialValues={{
                fullName: userById?.fullName,
                email: userById?.email,
                accountStatus: userById?.accountStatus,
                phoneNumber: userById?.phoneNumber,
                address: userById?.address,
                company: userById?.company,
                level: userById?.level,
                userSuspended: userById?.userSuspended,
              }}
              validationSchema={Yup.object().shape({
                firstName: Yup.string(),
                lastName: Yup.string(),
                email: Yup.string(),
                accountStatus: Yup.string(),
                phoneNumber: Yup.string(),
                address: Yup.string(),
                company: Yup.string(),
                level: Yup.string(),
                userSuspended: Yup.string(),
              })}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, values, setFieldValue }) => (
                <Form>
                  <div className="form-group row">
                    <div className="col-sm-2 col-form-label">
                      <CFormLabel>{t('USER.FULL_NAME')}</CFormLabel>
                    </div>
                    <div className="col-sm-10">
                      {' '}
                      <Field
                        type={'text'}
                        placeholder={t('USER.FULL_NAME')}
                        name="fullName"
                        className={`form-control col-sm-10 rounded-1  ${
                          errors.fullName && touched.fullName ? 'is-invalid' : ''
                        }`}
                      />
                    </div>
                  </div>
                  <ErrorMessage name="fullName" component="div" className="invalid-feedback" />
                  <div className="form-group row">
                    <div className="col-sm-2 col-form-label">
                      <CFormLabel>{t('USER.EMAIL')} </CFormLabel>
                    </div>
                    <div className="col-sm-10">
                      {' '}
                      <Field
                        type={'text'}
                        placeholder={t('USER.EMAIL')}
                        name="email"
                        className={`form-control col-sm-10 rounded-1  ${
                          errors.email && touched.email ? 'is-invalid' : ''
                        }`}
                      />
                    </div>
                  </div>
                  <ErrorMessage name="email" component="div" className="invalid-feedback" />
                  <div className="form-group row">
                    <div className="col-sm-2 col-form-label">
                      <CFormLabel>{t('USER.ACCOUNT_STATUS')}</CFormLabel>
                    </div>
                    <div className="col-sm-10">
                      {' '}
                      <Field
                        disabled
                        type={'text'}
                        placeholder={t('USER.ACCOUNT_STATUS')}
                        name="accountStatus"
                        className={`form-control col-sm-10 rounded-1  ${
                          errors.accountStatus && touched.accountStatus ? 'is-invalid' : ''
                        }`}
                      />
                    </div>
                  </div>
                  <ErrorMessage name="accountStatus" component="div" className="invalid-feedback" />
                  <div className="form-group row">
                    <div className="col-sm-2 col-form-label">
                      <CFormLabel>{t('USER.PHONE')}</CFormLabel>
                    </div>
                    <div className="col-sm-10">
                      {' '}
                      <Field
                        type={'text'}
                        placeholder={t('USER.PHONE')}
                        name="phoneNumber"
                        className={`form-control col-sm-10 rounded-1  ${
                          errors.phoneNumber && touched.phoneNumber ? 'is-invalid' : ''
                        }`}
                      />
                    </div>
                  </div>
                  <ErrorMessage name="phoneNumber" component="div" className="invalid-feedback" />
                  <div className="form-group row">
                    <div className="col-sm-2 col-form-label">
                      <CFormLabel>{t('USER.ADDRESS')}</CFormLabel>
                    </div>
                    <div className="col-sm-10">
                      {' '}
                      <Field
                        type={'text'}
                        placeholder={t('USER.ADDRESS')}
                        name="address"
                        className={`form-control col-sm-10 rounded-1  ${
                          errors.address && touched.address ? 'is-invalid' : ''
                        }`}
                      />
                    </div>
                  </div>
                  <ErrorMessage name="address" component="div" className="invalid-feedback" />
                  <div className="form-group row">
                    <div className="col-sm-2 col-form-label">
                      <CFormLabel>{t('USER.COMPANY')}</CFormLabel>
                    </div>
                    <div className="col-sm-10">
                      {' '}
                      <Field
                        type={'text'}
                        placeholder={t('USER.COMPANY')}
                        name="company"
                        className={`form-control col-sm-10 rounded-1  ${
                          errors.company && touched.company ? 'is-invalid' : ''
                        }`}
                      />
                    </div>
                  </div>
                  <ErrorMessage name="company" component="div" className="invalid-feedback" />
                  {userDetails?.level === 'ADMIN' ? (
                    <div className="form-group row">
                      <div className="col-sm-2 col-form-label">
                        <CFormLabel>{t('USER.PRIVILEGE_LEVEL')}</CFormLabel>
                      </div>
                      <div className="col-sm-10">
                        {' '}
                        <Field
                          as="select"
                          placeholder="Level"
                          name="level"
                          className={`form-control rounded-1  ${
                            errors.level && touched.level ? 'is-invalid' : ''
                          }`}
                        >
                          <option value={''} label={'Select level'} />
                          <option key={1} value={'USER'} label={'USER'} />
                          <option key={2} value={'MANAGER'} label={'MANAGER'} />
                        </Field>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  <ErrorMessage name="level" component="div" className="invalid-feedback" />
                  <CFormSwitch
                    checked={userSuspendToggle === 'ON' ? true : false}
                    label={t('USER.SUSPENDE_USER')}
                    id="formSwitchCheckChecked"
                    name="userSuspended"
                    onClick={() => {
                      if (userSuspendToggle === 'ON') {
                        setUserSuspendToggle('OFF')
                        return setFieldValue('userSuspended', 'OFF')
                      } else {
                        setUserSuspendToggle('ON')

                        return setFieldValue('userSuspended', 'ON')
                      }
                    }}
                  />
                  <div className="d-flex flex-row bd-highlight  pt-4 gap-3">
                    <div className="">
                      <CButton
                        disabled={isFetchAllUsers}
                        type="submit"
                        color="success"
                        className="text-white col"
                      >
                        {isFetchAllUsers ? (
                          <CSpinner component="span" size="sm" aria-hidden="true" />
                        ) : (
                          ''
                        )}
                        &nbsp; {t('USER.UPDATE')}
                      </CButton>
                    </div>
                    <div>
                      <CButton
                        onClick={() => navigate('/users')}
                        color="info"
                        className="text-white col"
                      >
                        {t('CANCEL')}
                      </CButton>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </CCardBody>
        </CCard>
      )}
    </>

    // </div>
  )
}

export default UserDetails
