import { cilCheck, cilXCircle } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CToast, CToastBody, CToastClose, CToaster } from '@coreui/react-pro'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeAlert } from 'src/views/slices/toastSlice'

const ToastComponent = () => {
  const dispatch = useDispatch()
  const { showAlert, alertType, alertMessage } = useSelector((state) => state.toast)

  const [toast, addToast] = useState(0)
  const toaster = useRef()
  useEffect(() => {
    if (showAlert) {
      addToast(exampleToast)
      setTimeout(() => {
        dispatch(closeAlert())
      }, 3000)
    }
  }, [showAlert])
  const exampleToast = (
    <CToast
      visible={true}
      color={`${alertType === 'ERROR' ? 'danger' : 'success'}`}
      className="text-white align-items-center"
    >
      <div className="d-flex align-items-center ">
        <div className="d-flex h-100 px-1">
          {alertType === 'ERROR' ? (
            <CIcon icon={cilXCircle} size="lg" />
          ) : (
            <CIcon icon={cilCheck} size="lg" />
          )}
        </div>
        <CToastBody> {alertMessage}</CToastBody>
        <CToastClose className="me-2 m-auto" white />
      </div>
    </CToast>
  )
  return (
    <>
      <CToaster ref={toaster} push={toast} placement="top-end" />
    </>
  )
}

export default ToastComponent
