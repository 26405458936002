import React from 'react'
import { CSpinner } from '@coreui/react-pro'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { userEmailVerification } from 'src/views/slices/authenticationSlice'

const VerificationLandingPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  useEffect(() => {
    const otp = searchParams.get('otp')
    const email = searchParams.get('email')
    if (otp && email) {
      dispatch(userEmailVerification({ otp, email }))
    }
  }, [])
  const { isVerifiying, isSuccess, isError } = useSelector((store) => store.authentication)
  return (
    <div className="container d-flex flex-column justify-content-center align-items-center vh-100">
      {isVerifiying ? (
        <div className="text-center">
          <CSpinner size="sm" variant="grow" style={{ width: '3rem', height: '3rem' }} />
        </div>
      ) : (
        <div className="text-center p-4 rounded shadow h-25 w-50">
          {isVerifiying ? (
            <img src="/assets/inProgress.gif" alt=""></img>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 -960 960 960"
              width="75"
              height="75"
              fill="green"
            >
              <path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
            </svg>
          )}

          <h3>
            Email Verification{' '}
            {isVerifiying ? 'in progress' : isSuccess ? 'Successful' : isError ? 'Failed' : ''}!
          </h3>
          {isSuccess ? (
            ''
          ) : (
            <button className="btn btn-primary" onClick={() => navigate('/login')}>
              CONTINUE
            </button>
          )}
        </div>
      )}
    </div>
  )
}

export default VerificationLandingPage
