import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  deleteModelByIdService,
  fetchModelSettings,
  getModelByIdService,
  getModelService,
  postModelService,
  putModelByIdService,
} from '../services/models'
import { showAlert } from './toastSlice'

const initialState = {
  isgettingModels: false,
  models: [],
  isCreatingModel: false,
  isProcessingModelById: false,
  isUpdatingModel: false,
  isDeleteingModelsById: false,
  isModalSettings: false,
  modelSettings: '',
}

export const modelSlice = createSlice({
  name: 'models',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getModels.pending, (state) => {
        state.isgettingModels = true
      })
      .addCase(getModels.fulfilled, (state, action) => {
        state.isgettingModels = false
        state.models = action.payload?.data[0]
      })
      .addCase(getModels.rejected, (state) => {
        state.isgettingModels = false
      })
      .addCase(createModels.pending, (state) => {
        state.isCreatingModel = true
      })
      .addCase(createModels.fulfilled, (state, action) => {
        state.isCreatingModel = false
      })
      .addCase(createModels.rejected, (state) => {
        state.isCreatingModel = false
      })
      .addCase(getModelById.pending, (state) => {
        state.isProcessingModelById = true
      })
      .addCase(getModelById.fulfilled, (state, action) => {
        state.isProcessingModelById = false
      })
      .addCase(getModelById.rejected, (state) => {
        state.isProcessingModelById = false
      })
      .addCase(updataModelsById.pending, (state) => {
        state.isUpdatingModel = true
      })
      .addCase(updataModelsById.fulfilled, (state, action) => {
        state.isUpdatingModel = false
      })
      .addCase(updataModelsById.rejected, (state) => {
        state.isUpdatingModel = false
      })
      .addCase(deleteModelsById.pending, (state) => {
        state.isUpdatingModel = true
      })
      .addCase(deleteModelsById.fulfilled, (state, action) => {
        state.isUpdatingModel = false
      })
      .addCase(deleteModelsById.rejected, (state) => {
        state.isUpdatingModel = false
      })
      .addCase(getModelSettings.pending, (state) => {
        state.isModalSettings = true
      })
      .addCase(getModelSettings.fulfilled, (state, action) => {
        state.isModalSettings = false
        state.modelSettings = action.payload?.data[0]
      })
      .addCase(getModelSettings.rejected, (state) => {
        state.isModalSettings = false
      })
  },
})

export const getModels = createAsyncThunk('models/get', async (userData, thunkAPI) => {
  try {
    const response = await getModelService(userData)
    return response
  } catch (err) {
    thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
    return thunkAPI.rejectWithValue(err)
  }
})
export const getModelById = createAsyncThunk('models/getById', async (id, thunkAPI) => {
  try {
    const response = await getModelByIdService(id)
    return response
  } catch (err) {
    thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
    return thunkAPI.rejectWithValue(err)
  }
})
export const createModels = createAsyncThunk('models/create', async (userData, thunkAPI) => {
  try {
    const response = await postModelService(userData)
    thunkAPI.dispatch(showAlert({ type: 'SUCCESS', message: response?.message }))
    return response
  } catch (err) {
    thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
    return thunkAPI.rejectWithValue(err)
  }
})
export const updataModelsById = createAsyncThunk('models/update', async (data, thunkAPI) => {
  try {
    const response = await putModelByIdService(data?.payload, data.id)
    thunkAPI.dispatch(showAlert({ type: 'SUCCESS', message: response?.message }))
    thunkAPI.dispatch(getModels())
    return response
  } catch (err) {
    thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
    return thunkAPI.rejectWithValue(err)
  }
})
export const deleteModelsById = createAsyncThunk('models/delete', async (id, thunkAPI) => {
  try {
    const response = await deleteModelByIdService(id)
    thunkAPI.dispatch(showAlert({ type: 'SUCCESS', message: response?.message }))
    thunkAPI.dispatch(getModels())
    return response
  } catch (err) {
    thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
    return thunkAPI.rejectWithValue(err)
  }
})
export const getModelSettings = createAsyncThunk('models/modelSettings', async (id, thunkAPI) => {
  try {
    const response = await fetchModelSettings()
    return response
  } catch (err) {
    thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
    return thunkAPI.rejectWithValue(err)
  }
})
export default modelSlice.reducer
export const modelReducer = modelSlice.reducer
