import { CSpinner } from '@coreui/react-pro'
import React from 'react'
import { useEffect, useState } from 'react'
import { getFile } from 'src/views/services/api'

const ImageContainer = ({ id, style, alternate }) => {
  const [image, setImage] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    try {
      if (id) {
        setLoading(true)
        getFile(`users/${id}/download`).then((v) => {
          const blob = new Blob([v?.data])

          let objectURL = URL.createObjectURL(blob)
          setLoading(false)

          setImage(objectURL)
        })
      }
    } catch (err) {
      setLoading(false)

      return null
    }
  }, [])
  return !loading ? (
    id ? (
      <img src={image} className="rounded-top" style={style} alt=""></img>
    ) : (
      <div
        className="bg-secondary  d-flex rounded-top justify-content-center align-items-center"
        style={style}
      >
        <img src="/assets/no-picture.png" style={{ width: '100px', height: '100px' }}></img>
      </div>
    )
  ) : (
    <div className=" d-flex rounded-top justify-content-center align-items-center" style={style}>
      <CSpinner variant="grow" />
    </div>
  )
}
export default ImageContainer
