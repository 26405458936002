import { cilSearch } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CFormInput, CInputGroup, CInputGroupText } from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Search = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [value, setValue] = useState(searchParams.get('keyword'))
  const { t } = useTranslation('common')
  const handleSubmit = () => {
    let params = {}
    searchParams &&
      searchParams.forEach((value, key) => {
        params[key] = value
      })
    if (value) {
      params['keyword'] = value
      params['page'] = 1
    } else {
      delete params['keyword']
      params['page'] = 1
    }
    setSearchParams(params)
  }
  useEffect(() => {
    let params = {}
    searchParams &&
      searchParams.forEach((value, key) => {
        params[key] = value
      })
    if (!value && searchParams.get('keyword')) {
      delete params['keyword']
      setSearchParams(params)
    }
  }, [value])
  return (
    <CInputGroup className="mb-3">
      <CFormInput
        type="search"
        placeholder={t('SEARCH')}
        aria-label="Recipient's username"
        aria-describedby="basic-addon2"
        onChange={(e) => {
          setValue(e.target.value)
        }}
        value={value}
      />{' '}
      <CInputGroupText id="basic-addon2" onClick={handleSubmit}>
        <CIcon icon={cilSearch} size="lg" />
      </CInputGroupText>
    </CInputGroup>
  )
}

export default Search
