import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  deletePluginMetaData,
  getAllPluginMetaData,
  getAllPlugins,
  postPluginMetaData,
  putPluginMetaData,
} from '../services/plugins'
import { showAlert } from './toastSlice'

const initialState = {
  pluginList: [],
  pluginListing: [],
  isPlugin: false,
  isPostMetaData: false,
  pluginMetaData: '',
  isGetMetaData: false,
  isDeleteMetaData: false,
}

export const pluginSlice = createSlice({
  name: 'plugin',
  initialState,
  reducers: {
    addPlugin: (state, action) => {
      return {
        pluginList: [...state.pluginList, action.payload?.data],
      }
    },
    removePlugin: (state, action) => {
      const temp = state.pluginList.filter((value) => value?.title !== action.payload?.title)
      return {
        pluginList: [...temp],
      }
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getPlugins.pending, (state) => {
        state.isPlugin = true
      })
      .addCase(getPlugins.fulfilled, (state, action) => {
        state.isPlugin = false
        state.pluginListing = action.payload?.data
      })
      .addCase(getPlugins.rejected, (state) => {
        state.isPlugin = false
      })
      .addCase(getMetaData.pending, (state) => {
        state.isGetMetaData = true
      })
      .addCase(getMetaData.fulfilled, (state, action) => {
        state.isGetMetaData = false
        state.pluginMetaData = action.payload?.data[0]
      })
      .addCase(getMetaData.rejected, (state) => {
        state.isGetMetaData = false
      })
      .addCase(postMetaData.pending, (state) => {
        state.isPostMetaData = true
      })
      .addCase(postMetaData.fulfilled, (state, action) => {
        state.isPostMetaData = false
      })
      .addCase(postMetaData.rejected, (state) => {
        state.isPostMetaData = false
      })
      .addCase(deleteMetaData.pending, (state) => {
        state.isDeleteMetaData = true
      })
      .addCase(deleteMetaData.fulfilled, (state, action) => {
        state.isDeleteMetaData = false
      })
      .addCase(deleteMetaData.rejected, (state) => {
        state.isDeleteMetaData = false
      })
  },
})

export const getPlugins = createAsyncThunk('models/getById', async (id, thunkAPI) => {
  try {
    const response = await getAllPlugins(id)
    return response
  } catch (err) {
    thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
    return thunkAPI.rejectWithValue(err)
  }
})
export const getMetaData = createAsyncThunk('plugin/getMetaData', async (id, thunkAPI) => {
  try {
    const response = await getAllPluginMetaData(id)
    return response
  } catch (err) {
    thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
    return thunkAPI.rejectWithValue(err)
  }
})
export const postMetaData = createAsyncThunk('plugin/postMetaData', async (data, thunkAPI) => {
  try {
    const response = await postPluginMetaData(data)
    return response
  } catch (err) {
    thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
    return thunkAPI.rejectWithValue(err)
  }
})
export const putMetaData = createAsyncThunk('plugin/putMetaData', async (data, thunkAPI) => {
  try {
    const response = await putPluginMetaData(data)
    thunkAPI.dispatch(getMetaData())
    return response
  } catch (err) {
    thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
    return thunkAPI.rejectWithValue(err)
  }
})
export const deleteMetaData = createAsyncThunk('plugin/deleteMetaData', async (data, thunkAPI) => {
  try {
    const response = await deletePluginMetaData(data)
    thunkAPI.dispatch(getMetaData())
    return response
  } catch (err) {
    thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
    return thunkAPI.rejectWithValue(err)
  }
})
export const { addPlugin, removePlugin } = pluginSlice.actions

export default pluginSlice.reducer
