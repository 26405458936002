import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
const IndexRoute = () => {
  const loginAuth = localStorage.getItem('token')
  const { userDetails } = useSelector((state) => state.authentication)
  return loginAuth ? (
    !userDetails?.emailVerified ? (
      <Navigate to={'/home'} replace state={{ path: '/home' }} />
    ) : (
      <Navigate to={'/not-verified'} replace state={{ path: '/not-verified' }} />
    )
  ) : (
    <Navigate to={'/login'} replace state={{ path: '/login' }} />
  )
}

export default IndexRoute
