export const handleUtcToMMDDYY = (value) => {
  try {
    if (value === 'None') return 'None'
    const date = new Date(value)
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date)
    return formattedDate
  } catch (err) {
    return null
  }
}
export const generateCode = () => {
  const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let code = ''
  for (let i = 0; i < 4; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length)
    code += characters.charAt(randomIndex)
  }
  return code
}
