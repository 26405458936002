import axios from 'axios'
import { getHeaderInfo } from '../helpers/localStorage'
import { getAccessToken } from '../helpers/localStorage'
import { env } from '../config/env'
import { getFormFileHeader } from '../helpers/apiHeaderCreator'

const handleResponse = (response) => {
  if (response?.status === 401) {
    localStorage.clear()
    window.location.replace('/')
    return Promise.reject(response.data)
  }
  if (response?.data?.success) {
    return response.data
  }
  if (response?.status === 201) {
    return response.data
  }
  if (response?.status === 200) {
    return response.data
  }
  return Promise.reject(response)
}
export const post = async function (url, body) {
  const header = await getHeaderInfo()
  const token = await getAccessToken()
  let resp

  try {
    if (token) resp = await axios.post(env.development.BASE_URL + url, body, header)
    else resp = await axios.post(env.development.BASE_URL + url, body)

    return handleResponse(resp)
  } catch (err) {
    return handleResponse(err.response)
  }
}
export const put = async function (url, body) {
  const header = await getHeaderInfo()
  const token = await getAccessToken()
  let resp

  try {
    if (token) resp = await axios.put(env.development.BASE_URL + url, body, header)
    else resp = await axios.put(env.development.BASE_URL + url, body)

    return handleResponse(resp)
  } catch (err) {
    return handleResponse(err.response)
  }
}
export const patch = async function (url, body) {
  const header = await getHeaderInfo()
  try {
    const resp = await axios.patch(env.development.BASE_URL + url, body, header)
    return handleResponse(resp)
  } catch (err) {
    return handleResponse(err.response)
  }
}

export const get = async (url, params) => {
  const header = await getHeaderInfo()
  const token = await getAccessToken()
  let resp
  try {
    if (token) {
      resp = await axios.get(env.development.BASE_URL + url, {
        ...header,
        params,
      })
    } else {
      resp = await axios.get(env.development.BASE_URL + url, {
        params,
      })
    }
    return handleResponse(resp)
  } catch (err) {
    return handleResponse(err.response)
  }
}
export const deleteById = async (url, params) => {
  const header = await getHeaderInfo()
  const token = await getAccessToken()
  let resp
  try {
    if (token) {
      resp = await axios.delete(env.development.BASE_URL + url, {
        ...header,
        params,
      })
    } else {
      resp = await axios.delete(env.development.BASE_URL + url, {
        params,
      })
    }
    return handleResponse(resp)
  } catch (err) {
    return handleResponse(err.response)
  }
}
export const UserDeleteById = async (url, params) => {
  const header = await getHeaderInfo()
  const token = await getAccessToken()
  let resp
  try {
    if (token) {
      resp = await axios.delete(env.development.API_URL + url, {
        ...header,
        params,
      })
    } else {
      resp = await axios.delete(env.development.API_URL + url, {
        params,
      })
    }
    return handleResponse(resp)
  } catch (err) {
    return handleResponse(err.response)
  }
}
export const loginPost = async function (url, body) {
  const header = await getHeaderInfo()
  const token = await getAccessToken()
  let resp
  try {
    if (token) resp = await axios.post(env.development.API_URL + url, body, header)
    else resp = await axios.post(env.development.API_URL + url, body)
    return handleResponse(resp)
  } catch (err) {
    return handleResponse(err.response)
  }
}
export const userGet = async (url, params) => {
  const header = await getHeaderInfo()
  const token = await getAccessToken()
  let resp
  try {
    if (token) {
      resp = await axios.get(env.development.API_URL + url, {
        ...header,
        params,
      })
    } else {
      resp = await axios.get(env.development.API_URL + url, {
        params,
      })
    }
    return handleResponse(resp)
  } catch (err) {
    return handleResponse(err.response)
  }
}
export const putValues = async function (url, body) {
  const header = await getHeaderInfo()
  const token = await getAccessToken()
  let resp

  try {
    if (token) resp = await axios.put(env.development.API_URL + url, body, header)
    else resp = await axios.put(env.development.API_URL + url, body)

    return handleResponse(resp)
  } catch (err) {
    return handleResponse(err.response)
  }
}
export const postProfileFile = async function (url, uploadFile, type) {
  const header = await getFormFileHeader()
  try {
    const formData = new FormData()
    formData.append('file-type', type)
    formData.append('file', uploadFile)
    const res = await axios.post(`${env.development.API_URL}` + url, formData, header)
    return handleResponse(res)
  } catch (err) {
    return handleResponse(err.response)
  }
}
export const getFile = async (url, params = {}) => {
  const header = await getFormFileHeader()
  const token = await getAccessToken()
  let resp
  try {
    if (token) {
      resp = await axios.get(env.development.API_URL + url, {
        ...header,
        responseType: 'arraybuffer',
      })
    } else {
      resp = await axios.get(env.development.API_URL + url, {
        params,
      })
    }
    return resp
  } catch (err) {
    return err.response
  }
}
