import React, { useEffect } from 'react'
import { cilLanguage } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CContainer,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CHeader,
  CHeaderDivider,
  CHeaderNav,
} from '@coreui/react-pro'
import { useDispatch, useSelector } from 'react-redux'
import { AppBreadcrumb } from './index'
import { AppHeaderDropdown } from './header/index'
import { changeLanguage } from 'src/views/slices/languageSlice'
import { useTranslation } from 'react-i18next'

const AppHeader = () => {
  const dispatch = useDispatch()

  const theme = useSelector((state) => state.theme)

  theme === 'dark'
    ? document.body.classList.add('dark-theme')
    : document.body.classList.remove('dark-theme')

  const { i18n } = useTranslation('common')
  const { lang } = useSelector((state) => state.language)
  useEffect(() => {
    if (!localStorage.getItem('lang')) {
      dispatch(changeLanguage({ type: 'en' }))
      localStorage.setItem('lang', 'en')
    } else {
      if (lang !== localStorage.getItem('lang')) {
        i18n.changeLanguage(lang)
        localStorage.setItem('lang', lang)
      }
    }
  }, [lang])
  const languages = [
    { title: 'EN', value: 'en' },
    { title: 'FN', value: 'fn' },
  ]

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <div className="d-flex w-100 justify-content-end gap-5">
          <CDropdown>
            <CDropdownToggle color="white" className="border">
              {' '}
              <CIcon icon={cilLanguage} className="me-2 " size="lg" />
              {lang?.toUpperCase()}
            </CDropdownToggle>
            <CDropdownMenu className="w-20">
              {languages.map((item, index) => (
                <CDropdownItem
                  className={`${lang === item.value && 'bg-info text-white'}`}
                  onClick={() => dispatch(changeLanguage({ type: item.value }))}
                  key={item + index}
                >
                  {item.title}
                </CDropdownItem>
              ))}
            </CDropdownMenu>
          </CDropdown>
          <CHeaderNav className="ms-3 me-4">
            <AppHeaderDropdown />
          </CHeaderNav>
        </div>

        {/* <CHeaderToggler
          className="ps-1"
          onClick={() => dispatch(setSideBarShow({ type: !sidebarShow }))}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none" to="/">
          <CIcon icon={logo} height={48} alt="Logo" />
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto">
          {/* <CNavItem>
            <CNavLink to="/dashboard" component={NavLink}>
              Dashboard
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink to="/devices" component={NavLink}>
              Devices
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">Settings</CNavLink>
          </CNavItem> */}
        <CHeaderNav className="ms-auto me-4">
          {/* <CButtonGroup aria-label="Theme switch">
            <CFormCheck
              type="radio"
              button={{ color: 'primary' }}
              name="theme-switch"
              id="btn-light-theme"
              autoComplete="off"
              label={<CIcon icon={cilSun} />}
              checked={theme === 'default'}
              onChange={() => dispatch({ type: 'set', theme: 'light' })}
            />
            <CFormCheck
              type="radio"
              button={{ color: 'primary' }}
              name="theme-switch"
              id="btn-dark-theme"
              autoComplete="off"
              label={<CIcon icon={cilMoon} />}
              checked={theme === 'dark'}
              onChange={() => dispatch({ type: 'set', theme: 'dark' })}
            />
          </CButtonGroup> */}
        </CHeaderNav>
        <CHeaderNav>
          {/* <AppHeaderDropdownNotif />
          <AppHeaderDropdownTasks />
          <AppHeaderDropdownMssg /> */}
        </CHeaderNav>

        {/* <CHeaderToggler
          className="px-md-0 me-md-3"
          onClick={() => dispatch({ type: 'set', asideShow: !asideShow })}
        >
          <CIcon icon={cilApplicationsSettings} size="lg" />
        </CHeaderToggler> */}
      </CContainer>
      <CHeaderDivider />
      <CContainer fluid>
        <AppBreadcrumb />
      </CContainer>
    </CHeader>
  )
}

export default AppHeader
