import { postProfileFile, putValues, userGet } from './api'

export const getAllUsers = async (data) => {
  return await userGet('users', data)
}
export const getUserById = async (data) => {
  return await userGet('users/' + data)
}
export const fetchUserUpdate = async (data) => {
  return await putValues('users/' + data?.id, data)
}
export const postProfilePicture = async (data) => {
  return await postProfileFile('users/me/profile', data?.file, data?.type)
}
