export const getAccessToken = () => localStorage.getItem('token')?.slice(1, -1)

export const setTokens = (authRes) => {
  localStorage.setItem('token', JSON.stringify(authRes))
}
export const removeToken = () => {
  localStorage.removeItem('token')
}

export const clearLocalStorage = () => {
  localStorage.clear()
}
export const isAuthenticated = () => (getAccessToken() ? true : false)
export const getHeader = async function () {
  const token = await getAccessToken()
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
}
export const getHeaderInfo = async function () {
  const token = await getAccessToken()
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }
}
