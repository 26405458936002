import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import './scss/style.scss'
import ToastComponent from './views/components/toastComponent'
import RouteContainer from './views/routes/routeContainer'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

function App() {
  return (
    <BrowserRouter>
      <ToastComponent />
      <Suspense fallback={loading}>
        <RouteContainer />
      </Suspense>
    </BrowserRouter>
  )
}

export default App
