import { authEndPoints } from '../constants/auth'
import { loginPost, userGet } from './api'
export const emailCheckService = async (data) => {
  return await loginPost('users/find-email', data)
}
export const forgotPasswordVerification = async (data) => {
  return await loginPost('auth/forgot-password', data)
}
export const resetForgotPasswordVerification = async (data) => {
  return await loginPost('auth/reset-password', data)
}
export const authenticate = async (data) => {
  return await loginPost(authEndPoints.LOGIN, data)
}
export const userRegister = async (data) => {
  return await loginPost(authEndPoints.USER_CREATE, data)
}
export const userDetails = async () => {
  return await userGet(authEndPoints.USER_DETAILS)
}
export const userVerification = async (data) => {
  return await userGet(authEndPoints.USER_VERIFY + '?otp=' + data.otp + '&email=' + data.email)
}
