export const env = {
  development: {
    API_URL: process.env.REACT_APP_API_URL,
    BASE_URL: process.env.REACT_APP_BASE_URL,
  },
  production: {
    API_URL: process.env.REACT_APP_API_URL,
    BASE_URL: process.env.REACT_APP_BASE_URL,
  },
  test: {
    API_URL: process.env.REACT_APP_API_URL,
    BASE_URL: process.env.REACT_APP_BASE_URL,
  },
}
export const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL
