import { cilLockLocked, cilUser } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react-pro'
import { ErrorMessage, Field, Formik, Form } from 'formik'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { resetForgotPassword } from 'src/views/slices/authenticationSlice'
import { showAlert } from 'src/views/slices/toastSlice'
import * as Yup from 'yup'

const ResetPassword = () => {
  const [showHidePassword, changeShowHidePassword] = useState(false)
  const [showHideConfirmPassword, changeShowHideConfirmPassword] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const handleResetPassword = async (data) => {
    try {
      const response = await dispatch(resetForgotPassword(data)).unwrap()
      dispatch(showAlert({ type: 'SUCCESS', message: response?.message }))
      navigate('/login')
    } catch (err) {
      return err
    }
  }
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard
                className="p-4"
                style={{
                  width: '30vw',
                  minWidth: '300px',
                }}
              >
                <CCardBody className="d-flex justify-content-between flex-column">
                  <div>
                    {' '}
                    <Formik
                      initialValues={{
                        password: '',
                        confirmPassword: '',
                        email: '',
                        otp: '',
                      }}
                      validationSchema={Yup.object().shape({
                        email: Yup.string().required('Email is required.'),
                        otp: Yup.string().required('OTP is required.'),
                        password: Yup.string().required('Password is required.'),
                        confirmPassword: Yup.string()
                          .required('Confirm password is required.')
                          .oneOf([Yup.ref('password'), undefined], 'Passwords must match'),
                      })}
                      onSubmit={handleResetPassword}
                    >
                      {({ errors, touched, handleBlur }) => (
                        <Form>
                          <h1 className="justify-content-center d-flex mb-4">Reset Password</h1>
                          <div className="d-flex flex-row align-items-center  ">
                            <CInputGroup className="mb-3">
                              <CInputGroupText id="basic-addon1">
                                <CIcon icon={cilUser} size="lg" />
                              </CInputGroupText>
                              <Field
                                // onBlur={handleBlur}
                                type="text"
                                placeholder="Email"
                                name="email"
                                // value={values.password}
                                className={`form-control rounded-0  ${
                                  errors.email && touched.email ? 'is-invalid' : ''
                                }`}
                              />
                            </CInputGroup>
                          </div>
                          <div>
                            {' '}
                            <ErrorMessage
                              style={{ color: 'red' }}
                              name="email"
                              component={'div'}
                              className=" text-danger"
                            />
                          </div>

                          <div className="d-flex flex-row align-items-center  ">
                            <CInputGroup className="mb-3">
                              <CInputGroupText id="basic-addon1">
                                {' '}
                                <CIcon icon={cilLockLocked} size="lg" />
                              </CInputGroupText>
                              <Field
                                onBlur={handleBlur}
                                type={showHidePassword ? 'text' : 'password'}
                                placeholder="Password"
                                name="password"
                                // value={values.password}
                                className={`form-control rounded-0  ${
                                  errors.password && touched.password ? 'is-invalid' : ''
                                }`}
                              />
                              <CInputGroupText
                                onClick={() => changeShowHidePassword(!showHidePassword)}
                                id="basic-addon2"
                              >
                                <img
                                  src={
                                    showHidePassword
                                      ? `/assets/visibilityOff.svg`
                                      : `/assets/visibilityOn.svg`
                                  }
                                  alt=""
                                ></img>
                              </CInputGroupText>
                            </CInputGroup>
                          </div>
                          <div>
                            <ErrorMessage
                              style={{ color: 'red' }}
                              type="password"
                              name="password"
                              component={'div'}
                              className=" text-danger"
                            />
                          </div>

                          <div className="d-flex flex-row align-items-center mt-2  ">
                            <CInputGroup className="mb-3">
                              <CInputGroupText id="basic-addon1">
                                {' '}
                                <CIcon icon={cilLockLocked} size="lg" />
                              </CInputGroupText>
                              <Field
                                onBlur={handleBlur}
                                type={showHideConfirmPassword ? 'text' : 'password'}
                                placeholder="Confirm password"
                                name="confirmPassword"
                                // value={values.password}
                                className={`form-control rounded-0  ${
                                  errors.confirmPassword && touched.confirmPassword
                                    ? 'is-invalid'
                                    : ''
                                }`}
                              />
                              <CInputGroupText
                                onClick={() =>
                                  changeShowHideConfirmPassword(!showHideConfirmPassword)
                                }
                                id="basic-addon2"
                              >
                                <img
                                  src={
                                    showHideConfirmPassword
                                      ? `/assets/visibilityOff.svg`
                                      : `/assets/visibilityOn.svg`
                                  }
                                  alt=""
                                ></img>
                              </CInputGroupText>
                            </CInputGroup>
                          </div>
                          <div>
                            {' '}
                            <ErrorMessage
                              style={{ color: 'red' }}
                              name="confirmPassword"
                              component={'div'}
                              className=" text-danger"
                            />
                          </div>
                          <div className="d-flex flex-row align-items-center  ">
                            <CInputGroup className="mb-3">
                              <CInputGroupText id="basic-addon1">
                                <CIcon icon={cilUser} size="lg" />
                              </CInputGroupText>
                              <Field
                                // onBlur={handleBlur}
                                type="text"
                                placeholder="OTP"
                                name="otp"
                                // value={values.password}
                                className={`form-control rounded-0  ${
                                  errors.otp && touched.otp ? 'is-invalid' : ''
                                }`}
                              />
                            </CInputGroup>
                          </div>
                          <div>
                            {' '}
                            <ErrorMessage
                              style={{ color: 'red' }}
                              name="otp"
                              component={'div'}
                              className=" text-danger"
                            />
                          </div>
                          <div className={`justify-content-center d-flex  w-100 pt-4 `}>
                            <div className="w-100 d-flex justify-content-between align-items-end color-primary">
                              <CButton type="submit" color="primary">
                                Continue
                              </CButton>
                              <div
                                role={'button'}
                                onClick={() => navigate('/login')}
                                className="underline"
                              >
                                Cancel
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </CCardBody>
              </CCard>
              <CCard className="text-white bg-primary py-5" style={{ width: '44%' }}>
                <CCardBody className="text-center">
                  <div>
                    <h2>Order online</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                      tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                    <Link to="/register">
                      <CButton color="primary" className="mt-3" active tabIndex={-1}>
                        Go to
                      </CButton>
                    </Link>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default ResetPassword
