import { deleteById, get, post, put } from './api'

export const getModelService = async (data) => {
  return await get('deviceModel', data)
}
export const getModelByIdService = async (id) => {
  return await get('deviceModel/' + id)
}
export const postModelService = async (data) => {
  return await post('deviceModel', data)
}
export const putModelByIdService = async (data, id) => {
  return await put('deviceModel/' + id, data)
}
export const deleteModelByIdService = async (id) => {
  return await deleteById('deviceModel/' + id)
}
export const fetchModelSettings = async () => {
  return await get('deviceModel/json')
}
