import React from 'react'
import { cilCaretBottom, cilCaretTop, cilColorBorder, cilSwapVertical } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CButton,
  CSmartPagination,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react-pro'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Search from 'src/views/components/serach/search'
import { handleUtcToMMDDYY } from 'src/views/helpers/utilityFunctions'
import { fetchAllUsers } from 'src/views/slices/userSlice'
import { useTranslation } from 'react-i18next'

const Users = () => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1)
  const { users, isFetchAllUsers } = useSelector((state) => state.users)
  const [searchParams, setSearchParams] = useSearchParams()
  useEffect(() => {
    if (!searchParams.get('page')) setSearchParams({ page: 1, size: 10 })
    else {
      const params = {}
      searchParams.forEach((value, key) => {
        params[key] = value
      })
      params['page'] = currentPage
      setSearchParams(params)
    }
  }, [currentPage])

  useEffect(() => {
    if (searchParams.get('page')) {
      const params = {}
      searchParams.forEach((value, key) => {
        params[key] = value
      })
      if (searchParams.get('keyword')) {
        params['emailOrFullName'] = searchParams.get('keyword')
        delete params['keyword']
      }

      dispatch(fetchAllUsers(params))
    }
  }, [searchParams, dispatch])

  const handleSort = (asc, desc) => {
    const params = {}
    searchParams.forEach((value, key) => {
      params[key] = value
    })
    if (!searchParams.get('sort') || ![asc, desc].includes(searchParams.get('sort'))) {
      params['sort'] = asc
    }
    if (searchParams.get('sort') === asc) {
      params['sort'] = desc
    } else params['sort'] = asc
    setSearchParams(params)
  }
  const tableHeader = [
    {
      title: 'FULL NAME',
      value: 'FULL_NAME',
      asc: 'fullName:asc',
      desc: 'fullName:desc',
      required: true,
    },
    {
      title: 'EMAIL',
      value: 'EMAIL',
      asc: 'registeredDevices:asc',
      desc: 'registeredDevices:desc',
      required: false,
    },
    {
      title: 'ACCOUNT',
      value: 'ACCOUNT',
      required: false,
    },
    {
      title: 'LEVEL',
      value: 'LEVEL',
      required: false,
    },
    {
      title: 'COMPANY',
      value: 'COMPANY',
      asc: 'companyName:asc',
      desc: 'companyName:desc',
      required: true,
    },

    {
      title: 'ADDRESS',
      value: 'ADDRESS',
      asc: 'address:asc',
      desc: 'address:desc',
      required: true,
    },
    {
      title: 'PHONE',
      value: 'PHONE',
      required: false,
    },
    {
      title: 'EMAIL VERIFIED',
      value: 'EMAIL_VERIFIED',
      required: false,
    },
    {
      title: 'DATE CREATED',
      value: 'DATE_CREATED',
      asc: 'createdAt:asc',
      desc: 'createdAt:desc',
      required: true,
    },
    {
      title: 'DATE ACTIVATED',
      value: 'DATE_ACTIVATED',
      asc: 'emailVerifiedDate:asc',
      desc: 'emailVerifiedDate:desc',
      required: true,
    },
    {
      title: 'ACTIONS',
      value: 'ACTIONS',
      required: false,
    },
  ]
  const renderTable = () => {
    return isFetchAllUsers ? (
      <div
        style={{ height: '10rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <CSpinner />
      </div>
    ) : users?.data?.length > 0 ? (
      <div>
        <CTable striped responsive>
          <CTableHead>
            <CTableRow>
              {tableHeader.map((item, index) => (
                <CTableHeaderCell key={item.value + index}>
                  <div className="d-flex gap-3  inline ">
                    {' '}
                    {item.title}{' '}
                    {item.required ? (
                      <CIcon
                        size="lg"
                        icon={
                          [item.asc, item.desc].includes(searchParams.get('sort'))
                            ? searchParams.get('sort') === item.asc
                              ? cilCaretTop
                              : cilCaretBottom
                            : cilSwapVertical
                        }
                        // className="me-2 "
                        onClick={() => {
                          if (item.required) handleSort(item.asc, item.desc)
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </CTableHeaderCell>
              ))}
            </CTableRow>
          </CTableHead>

          <CTableBody>
            {users?.data?.map((data, index) => (
              <CTableRow key={data?.id}>
                <CTableDataCell>{data?.fullName}</CTableDataCell>
                <CTableDataCell>{data?.email}</CTableDataCell>
                <CTableDataCell>{data?.accountStatus}</CTableDataCell>
                <CTableDataCell>{data?.level}</CTableDataCell>
                <CTableDataCell>{data?.company}</CTableDataCell>
                <CTableDataCell>{data?.address}</CTableDataCell>
                <CTableDataCell>{data?.phone}</CTableDataCell>
                <CTableDataCell>{data?.emailVerified ? 'YES' : 'NO'}</CTableDataCell>
                <CTableDataCell>{handleUtcToMMDDYY(data?.createdAt)}</CTableDataCell>{' '}
                <CTableDataCell>{handleUtcToMMDDYY(data?.emailVerifiedDate)}</CTableDataCell>
                <CTableDataCell>
                  <CButton
                    style={{
                      backgroundColor: '#3a9bf0',
                    }}
                    onClick={() => navigate('/user-details?id=' + data?.id)}
                  >
                    <CIcon icon={cilColorBorder} className="me-2" />
                    {t('EDIT')}
                  </CButton>
                </CTableDataCell>
              </CTableRow>
            ))}
          </CTableBody>
        </CTable>
        <div className="w-100 d-flex justify-content-center pt-5">
          <CSmartPagination
            align="center"
            activePage={currentPage}
            pages={Number(users?.totalPages)}
            onActivePageChange={setCurrentPage}
          />
        </div>
      </div>
    ) : (
      <div
        style={{ height: '10rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        {t('NODATA')}
      </div>
    )
  }

  return (
    <div>
      <div className="d-flex ">
        <h1>{t('USER.USERS')}</h1>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '20px',
          }}
        ></div>
      </div>
      <div className="w-50">
        <Search />
      </div>

      {renderTable()}
    </div>
  )
}

export default Users
