import React from 'react'
import { cilArrowRight, cilDevices } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CCol, CLink, CRow, CWidgetStatsF } from '@coreui/react-pro'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getDeviceCount } from '../slices/device'
import { useTranslation } from 'react-i18next'

const WidgetsDropdown = () => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const list = [
    { title: t('REGISTERED_DEVICE'), color: 'success', value: 'REGISTERED_DEVICES' },
    { title: t('UN-REGISTERED_DEVICES'), color: 'warning', value: 'UNREGISTERED_DEVICES' },
    { title: t('AUTHORIZED_DEVICES'), color: 'info', value: 'AUTHORIZED_DEVICES' },
    { title: t('SUSPENDED_DEVICES'), color: 'danger', value: 'SUSPENDED_DEVICES' },
    { title: t('NOT_REGISTERED_DEVICE'), color: 'secondary', value: 'NOT_REGISTERED_DEVICES' },
  ]
  useEffect(() => {
    dispatch(getDeviceCount())
  }, [dispatch])
  const { deviceCount } = useSelector((state) => state.device)
  return (
    <>
      <CRow>
        {list.map((value, index) => (
          <CCol key={index + value} xs={6}>
            <CWidgetStatsF
              className="mb-3 "
              color={value.color}
              footer={
                <CLink
                  className="font-weight-bold font-xs text-medium-emphasis"
                  onClick={() => navigate('/devices?page=1&size=10&filter=' + value.value)}
                  rel="noopener norefferer"
                  target="_blank"
                >
                  {t('VIEW_MORE')}
                  <CIcon icon={cilArrowRight} className="float-end" width={16} />
                </CLink>
              }
              icon={<CIcon icon={cilDevices} height={24} />}
              title={value.title}
              value={deviceCount[value.value]}
            />
          </CCol>
        ))}
      </CRow>
    </>
  )
}

export default WidgetsDropdown
