import React from 'react'
import { CSpinner } from '@coreui/react-pro'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

const PublicRoute = ({ children }) => {
  const loginAuth = localStorage.getItem('token')
  const { isRoleManagement } = useSelector((state) => state.authentication)

  return loginAuth ? (
    isRoleManagement ? (
      <div className="d-flex vh-100 wh-100 justify-content-center align-items-center ">
        {' '}
        <CSpinner className="text-primary" />
      </div>
    ) : (
      // !userDetails?.emailVerified?
      <Navigate to={'/dashboard'} replace state={{ path: '/dashboard' }} />
    )
  ) : (
    children
  )
}

export default PublicRoute
PublicRoute.propTypes = {
  children: PropTypes.any.isRequired,
}
