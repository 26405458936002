import React from 'react'
import { cilPlus } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react-pro'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getAutomationData } from 'src/views/slices/automation'
import { useTranslation } from 'react-i18next'

const Automation = () => {
  const [tableData, setTableData] = useState()
  const dispatch = useDispatch()
  const { automationData } = useSelector((state) => state.automation)
  const { t } = useTranslation('common')

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getAutomationData())
    }
    fetchData()
  }, [])
  useEffect(() => {
    if (automationData) {
      setTableData(
        automationData?.data?.map((value, index) => {
          return {
            data1: value?.name,
            data2: (
              <div className="">
                <div>last use</div>
                <div>two weeks ago</div>
              </div>
            ),
            data3: (
              <div className="d-flex w-100">
                <div className="flex-grow-1 border-start border-start-4 border-start-success py-1 px-3 mb-3 text-center">
                  <div>Match and script run</div>
                  <div className="w-full d-flex justify-content-center font-weight-bold">
                    <h3>0</h3>
                  </div>
                </div>
                <div className="flex-grow-1 border-start border-start-4 border-start-warning py-1 px-3 mb-3 text-center">
                  <div>Not Match</div>
                  <div className="w-full d-flex justify-content-center">
                    <h3>0</h3>
                  </div>
                </div>
                <div className="flex-grow-1 border-start border-start-4 border-start-danger py-1 px-3 mb-3 text-center">
                  <div>Script Error</div>
                  <div className="w-full d-flex justify-content-center">
                    <h3>0</h3>
                  </div>
                </div>
              </div>
            ),
            id: value?.id,
          }
        }),
      )
    }
  }, [automationData])
  const navigate = useNavigate()

  return (
    <div>
      <CCard className="" style={{ width: '60%' }}>
        <CCardHeader className="d-flex justify-content-between">
          {t('AUTOMATION_LIST.QUOTE')}{' '}
          <span>
            <CButton
              color="success"
              className="text-white"
              onClick={() => navigate('/new-automation')}
            >
              <CIcon icon={cilPlus} color="white" className="me-2" />
              {t('AUTOMATION_LIST.NEW_RULE')}
            </CButton>
          </span>
        </CCardHeader>
        <CCardBody>
          <CTable className="  border border-grey">
            <CTableHead color="light">
              <CTableRow>
                <CTableHeaderCell scope="col">{t('AUTOMATION_LIST.NAME')}</CTableHeaderCell>
                <CTableHeaderCell scope="col" className="">
                  <span className=" flex-grow-1 w-full d-flex justif-content-center">
                    {t('AUTOMATION_LIST.ACTIVITY')}
                  </span>
                </CTableHeaderCell>
                <CTableHeaderCell colSpan={10} scope="col">
                  {t('AUTOMATION_LIST.STATISTIC')}
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {' '}
              {tableData?.map((value, index) => (
                <CTableRow
                  key={index}
                  onClick={() => navigate('/new-automation?id=' + value?.id)}
                  style={{ cursor: 'pointer' }}
                >
                  <CTableDataCell>
                    <div>{value?.data1}</div>
                  </CTableDataCell>
                  <CTableDataCell className="">{value?.data2}</CTableDataCell>
                  <CTableDataCell colSpan={10}>{value?.data3}</CTableDataCell>
                </CTableRow>
              ))}
            </CTableBody>
          </CTable>
        </CCardBody>
      </CCard>
    </div>
  )
}

export default Automation
