import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { setTokens } from '../helpers/localStorage'
import {
  authenticate,
  emailCheckService,
  forgotPasswordVerification,
  resetForgotPasswordVerification,
  userDetails,
  userRegister,
  userVerification,
} from '../services/login'
import { showAlert } from './toastSlice'

const initialState = {
  userEmail: '',
  isAuthentication: false,
  userDetails: '',
  isVerifiying: false,
  isSuccess: false,
  isError: false,
  isEmailValidating: false,
  emailExists: false,
  isRoleManagement: false,
}

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setUserEmail(state, action) {
      return { userEmail: action.payload?.email }
    },
    resetEmail(state) {
      return { userEmail: '' }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logInUser.pending, (state) => {
        state.isAuthentication = true
      })
      .addCase(logInUser.fulfilled, (state, action) => {
        state.isAuthentication = false
      })
      .addCase(logInUser.rejected, (state) => {
        state.isAuthentication = false
      })
      .addCase(userRoleManagement.pending, (state) => {
        state.isRoleManagement = true
      })
      .addCase(userRoleManagement.fulfilled, (state, action) => {
        state.isRoleManagement = false
        state.userDetails = action.payload?.data[0]
      })
      .addCase(userRoleManagement.rejected, (state) => {
        state.isRoleManagement = false
      })
      .addCase(userEmailVerification.pending, (state) => {
        state.isVerifiying = true
        state.isSuccess = false
        state.isError = false
      })
      .addCase(userEmailVerification.fulfilled, (state, action) => {
        state.isVerifiying = false
        state.isSuccess = true
      })
      .addCase(userEmailVerification.rejected, (state) => {
        state.isVerifiying = false
        state.isError = true
      })
      .addCase(checkEmail.pending, (state) => {
        state.isEmailValidating = true
      })
      .addCase(checkEmail.fulfilled, (state, action) => {
        state.isEmailValidating = false
        state.emailExists = action.payload
      })
      .addCase(checkEmail.rejected, (state) => {
        state.isEmailValidating = false
      })
      .addCase(forgotPassword.pending, (state) => {
        state.isAuthentication = true
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isAuthentication = false
      })
      .addCase(forgotPassword.rejected, (state) => {
        state.isAuthentication = false
      })
      .addCase(createUser.pending, (state) => {
        state.isAuthentication = true
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.isAuthentication = false
      })
      .addCase(createUser.rejected, (state) => {
        state.isAuthentication = false
      })
  },
})
export const logInUser = createAsyncThunk('auth/logIn', async (userData, thunkAPI) => {
  try {
    const response = await authenticate(userData)

    setTokens(response?.data[0]?.accessToken)
    return response
  } catch (err) {
    thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.message }))
    return thunkAPI.rejectWithValue(err)
  }
})
export const createUser = createAsyncThunk('auth/createUser', async (userData, thunkAPI) => {
  try {
    const response = await userRegister(userData)

    return response
  } catch (err) {
    thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
    return thunkAPI.rejectWithValue(err)
  }
})
export const checkEmail = createAsyncThunk('auth/checkEmail', async (userData, thunkAPI) => {
  try {
    const response = await emailCheckService(userData)
    return response
  } catch (err) {
    thunkAPI.dispatch(
      showAlert({ type: 'ERROR', message: 'Email not registered! Please register' }),
    )
    return thunkAPI.rejectWithValue(err?.data?.message)
  }
})
export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (userData, thunkAPI) => {
    try {
      const response = await forgotPasswordVerification(userData)
      return response
    } catch (err) {
      thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
      return thunkAPI.rejectWithValue(err)
    }
  },
)
export const resetForgotPassword = createAsyncThunk(
  'auth/resetForgotPassword',
  async (userData, thunkAPI) => {
    try {
      const response = await resetForgotPasswordVerification(userData)
      return response
    } catch (err) {
      thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
      return thunkAPI.rejectWithValue(err)
    }
  },
)
export const userRoleManagement = createAsyncThunk(
  'user/roleManganement',
  async (userData, thunkAPI) => {
    try {
      const response = await userDetails()
      return response
    } catch (err) {
      // thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
      return thunkAPI.rejectWithValue(err)
    }
  },
)
export const userEmailVerification = createAsyncThunk(
  'user/verification',
  async (userData, thunkAPI) => {
    try {
      const response = await userVerification(userData)
      return response
    } catch (err) {
      thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
      return thunkAPI.rejectWithValue(err)
    }
  },
)

export const { setUserEmail, resetEmail } = authenticationSlice.actions

export default authenticationSlice.reducer
