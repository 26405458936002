import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardTitle,
  CCol,
  CPlaceholder,
  CRow,
} from '@coreui/react-pro'
import React from 'react'

const TerminalLoader = (props) => {
  const list = [1, 2, 3, 4, 5, 6]
  return (
    <ul className="d-flex flex-wrap  px-0 gap-3">
      {' '}
      <CRow>
        {list.map((value, index) => (
          <CCol key={index + value} xs="12" sm="6" className="mb-4">
            <CCard style={{ minHeight: '300px', minWidth: '45%' }}>
              <CCardHeader
                className="d-flex justify-content-between align-items-center  "
                style={{ cursor: 'move' }}
              >
                <h5 className="pt-2  hello flex-grow-1">
                  {' '}
                  <CPlaceholder component={CCardTitle} animation="glow" xs={3}>
                    <CPlaceholder xs={6} style={{ height: '30px', borderRadius: '5px' }} />
                  </CPlaceholder>
                </h5>{' '}
                <div
                  className="d-flex gap-3 align-items-center remove "
                  style={{ cursor: 'pointer' }}
                >
                  <CPlaceholder component={CCardTitle} animation="glow" xs={3}>
                    <CPlaceholder
                      xs={6}
                      style={{ height: '30px', width: '100px', borderRadius: '5px' }}
                    />
                  </CPlaceholder>
                </div>
              </CCardHeader>
              <CCardBody>
                <div className="d-flex ">
                  <div className="d-flex " style={{ width: '', flexGrow: 1 }}>
                    <CPlaceholder component={CCardTitle} animation="glow" xs={3}>
                      <CPlaceholder
                        xs={6}
                        style={{ height: '30px', width: '100px', borderRadius: '5px' }}
                      />
                    </CPlaceholder>{' '}
                  </div>
                  <div
                    className="d-flex justify-centent-between g align-item-center"
                    style={{ width: '30rem' }}
                  >
                    <div className="d-flex gap-2" style={{ width: '45%' }}>
                      <CPlaceholder component={CCardTitle} animation="glow" xs={3}>
                        <CPlaceholder
                          xs={6}
                          style={{ height: '30px', width: '100px', borderRadius: '5px' }}
                        />
                      </CPlaceholder>
                    </div>
                    <div className="d-flex gap-2" style={{ width: '45%' }}>
                      <CPlaceholder component={CCardTitle} animation="glow" xs={3}>
                        <CPlaceholder
                          xs={6}
                          style={{ height: '30px', width: '100px', borderRadius: '5px' }}
                        />
                      </CPlaceholder>
                    </div>
                    <CPlaceholder
                      component={CButton}
                      aria-hidden="true"
                      disabled
                      href="#"
                      tabIndex={-1}
                      xs={4}
                      animation="glow"
                    ></CPlaceholder>
                  </div>
                </div>
                <div className="pb-3">
                  {' '}
                  <CPlaceholder component={CCardTitle} animation="glow" xs={3}>
                    <CPlaceholder
                      xs={6}
                      style={{ height: '30px', width: '300px', borderRadius: '5px' }}
                    />
                  </CPlaceholder>
                </div>

                <CPlaceholder
                  component={CCardTitle}
                  animation="glow"
                  xs={3}
                  style={{ width: '100%' }}
                >
                  <CPlaceholder
                    xs={6}
                    style={{ height: '400px', width: '100%', borderRadius: '5px' }}
                  />
                </CPlaceholder>
              </CCardBody>
              <CCardFooter style={{ backgroundColor: 'white', borderTop: 'none' }}>
                <CCard className="border border-1 " style={{ height: '100px' }}>
                  <CCardBody>
                    <CPlaceholder component={CCardTitle} animation="glow" xs={3}>
                      <CPlaceholder
                        xs={6}
                        style={{ height: '30px', width: '300px', borderRadius: '5px' }}
                      />
                    </CPlaceholder>
                  </CCardBody>
                </CCard>
              </CCardFooter>
            </CCard>
          </CCol>
        ))}
      </CRow>
    </ul>
  )
}

export default TerminalLoader
