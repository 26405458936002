import { CButton } from '@coreui/react-pro'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const NotVerified = () => {
  const navigate = useNavigate()
  const handleClick = () => {
    localStorage.clear()
    navigate('/login')
  }

  return (
    <div className="d-flex flex-column justify-content-center align-items-center vh-100">
      {' '}
      <div className="d-flex justify-content-center align-items-center">
        <div className="text-center ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="35"
            fill="red"
            className="bi bi-envelope-x"
            viewBox="0 0 16 16"
          >
            <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z" />
            <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-4.854-1.354a.5.5 0 0 0 0 .708l.647.646-.647.646a.5.5 0 0 0 .708.708l.646-.647.646.647a.5.5 0 0 0 .708-.708l-.647-.646.647-.646a.5.5 0 0 0-.708-.708l-.646.647-.646-.647a.5.5 0 0 0-.708 0Z" />
          </svg>
          <h1 className="text-primary">Your Email is Not Verified</h1>
          <p className="text-secondary">
            Please check your email and click the verification link to proceed.
          </p>
        </div>
      </div>
      <CButton onClick={handleClick}>Go to Login</CButton>
    </div>
  )
}

export default NotVerified
