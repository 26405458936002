import { getAccessToken } from './localStorage'

export const getFormFileHeader = async function () {
  const token = getAccessToken()
  return {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  }
}
