import { deleteById, get, post, put } from './api'

export const fetchAutomationData = async (id) => {
  return id ? await get('automation/' + id) : await get('automation')
}
export const fetchApiKey = async (id) => {
  if (!id) return await get('api-key/')
  return await get('api-key/' + id)
}
export const fetchputApiKey = async (id) => {
  return await put('api-key/' + id)
}
export const postAutomationData = async (data) => {
  return await post('automation', data)
}
export const putAutomationData = async (data) => {
  return await put('automation/' + data?.id, data?.data)
}
export const removeAutomationDatabyId = async (data) => {
  return await deleteById('automation/' + data)
}
export const postAutomationTemplate = async (data) => {
  return await post('automation/template/?template=' + data)
}
