import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  deleteProjectByIdService,
  getProjectByIdService,
  getProjectService,
  postProjectService,
  putProjectByIdService,
} from '../services/projects'
import { showAlert } from './toastSlice'

const initialState = {
  isgettingProjects: false,
  projects: [],
  isCreatingProject: false,
  isProcessingProjectById: false,
  isUpdatingProject: false,
  isDeleteingProjectsById: false,
}

export const projectSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getProjects.pending, (state) => {
        state.isgettingProjects = true
      })
      .addCase(getProjects.fulfilled, (state, action) => {
        state.isgettingProjects = false
        state.projects = action.payload?.data[0]
      })
      .addCase(getProjects.rejected, (state) => {
        state.isgettingProjects = false
      })
      .addCase(createProjects.pending, (state) => {
        state.isCreatingProject = true
      })
      .addCase(createProjects.fulfilled, (state, action) => {
        state.isCreatingProject = false
      })
      .addCase(createProjects.rejected, (state) => {
        state.isCreatingProject = false
      })
      .addCase(getProjectById.pending, (state) => {
        state.isProcessingProjectById = true
      })
      .addCase(getProjectById.fulfilled, (state, action) => {
        state.isProcessingProjectById = false
      })
      .addCase(getProjectById.rejected, (state) => {
        state.isProcessingProjectById = false
      })
      .addCase(updataProjectsById.pending, (state) => {
        state.isUpdatingProject = true
      })
      .addCase(updataProjectsById.fulfilled, (state, action) => {
        state.isUpdatingProject = false
      })
      .addCase(updataProjectsById.rejected, (state) => {
        state.isUpdatingProject = false
      })
      .addCase(deleteProjectsById.pending, (state) => {
        state.isUpdatingProject = true
      })
      .addCase(deleteProjectsById.fulfilled, (state, action) => {
        state.isUpdatingProject = false
      })
      .addCase(deleteProjectsById.rejected, (state) => {
        state.isUpdatingProject = false
      })
  },
})

export const getProjects = createAsyncThunk('projects/get', async (userData, thunkAPI) => {
  try {
    const response = await getProjectService(userData)

    return response
  } catch (err) {
    thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
    return thunkAPI.rejectWithValue(err)
  }
})
export const getProjectById = createAsyncThunk('projects/getById', async (id, thunkAPI) => {
  try {
    const response = await getProjectByIdService(id)

    return response
  } catch (err) {
    thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
    return thunkAPI.rejectWithValue(err)
  }
})
export const createProjects = createAsyncThunk('projects/create', async (userData, thunkAPI) => {
  try {
    const response = await postProjectService(userData)
    thunkAPI.dispatch(showAlert({ type: 'SUCCESS', message: response?.message }))
    thunkAPI.dispatch(getProjects())
    return response
  } catch (err) {
    thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
    return thunkAPI.rejectWithValue(err)
  }
})
export const updataProjectsById = createAsyncThunk('projects/update', async (payload, thunkAPI) => {
  try {
    const response = await putProjectByIdService(payload.data, payload.id)
    thunkAPI.dispatch(showAlert({ type: 'SUCCESS', message: response?.message }))
    thunkAPI.dispatch(getProjects())
    return response
  } catch (err) {
    thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
    return thunkAPI.rejectWithValue(err)
  }
})
export const deleteProjectsById = createAsyncThunk('projects/delete', async (id, thunkAPI) => {
  try {
    const response = await deleteProjectByIdService(id)
    thunkAPI.dispatch(showAlert({ type: 'SUCCESS', message: response?.message }))
    thunkAPI.dispatch(getProjects())
    return response
  } catch (err) {
    thunkAPI.dispatch(showAlert({ type: 'ERROR', message: err?.data?.message }))
    return thunkAPI.rejectWithValue(err)
  }
})
export default projectSlice.reducer
export const projectReducer = projectSlice.reducer
